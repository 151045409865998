export const mapLabelToInputName = {
  iban: "IBAN",
  bic: "BIC",
};

export const mapHelperText = {
  iban: "subscription.payment-information.iban-help-text",
  bic: "subscription.payment-information.bic-help-text",
};

export const mapErrorText = {
  iban: "subscription.payment-information.iban-error-text",
  bic: "subscription.payment-information.bic-error-text",
};
