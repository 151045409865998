import React from "react";
import cn from "classnames";

import { ButtonTypeKeys } from "./select-button.types";

import styles from "./select-button.module.css";

export interface SelectButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "ref"> {
  className?: string;
  children: React.ReactNode;
  type?: ButtonTypeKeys;
  selected?: boolean;
  forwardRef?: React.RefObject<HTMLButtonElement> | React.Dispatch<HTMLButtonElement>;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  className = "",
  children,
  type = "button",
  selected = false,
  forwardRef,
  ...rest
}: SelectButtonProps) => {
  const buttonStyles = cn(styles.selectButton, { [styles.selected]: selected }, className);

  return (
    <button
      {...rest}
      className={buttonStyles}
      type={type}
      ref={forwardRef}
      role="button"
      aria-label="button"
      aria-disabled={rest.disabled}
    >
      {children}
    </button>
  );
};

export default SelectButton;
