import { call, put } from "redux-saga/effects";

import * as a from "../../actions/account";

import { request } from "utils/request";

const getDocuments = () => {
  return request("/freshsales", "GET");
};

export function* getDocumentsSaga() {
  try {
    const response = yield call(getDocuments);
    const data = yield response.json();

    yield put(a.getDocumentsListSuccess(data));
  } catch (error) {
    yield put(a.getDocumentsListFailure(error));
  }
}
