import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { RootState, ScooterSetupState, setSelectedAddons } from "store";
import { NonNullableKeys } from "models";

const addonImageUrl = "https://d2yjuj8b4m1iq2.cloudfront.net/images/addons/##addon##/addon.svg";

const SecondDriverAddon: React.FC = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addons = useSelector((state: RootState) => state.scooterData.addons);
  const { selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;
  const applicableAddons = selectedPlan.applicable_addons?.map((item) => item.id) ?? [];

  const { selectedAddons } = paymentDetails;

  const clickHandler = (price: number) => () => {
    const count = selectedAddons["pro_addition_driver"] ? 0 : 1;
    dispatch(
      setSelectedAddons({
        ["pro_addition_driver"]: { count, price },
      }),
    );
  };

  return (
    <div className="addons-wrap">
      <div className="addons-wrap-list">
        {addons.map(({ price, id }, index) => {
          if (id !== "pro_addition_driver" || !applicableAddons.includes(id)) return null;

          return (
            <div key={index} className={"addon second-driver"}>
              <img src={addonImageUrl.replace("##addon##", id)} alt="" />
              <div className="addon-content">
                <h6>{t(`subscription.subscription-choice.${id}`)}</h6>
                <p>{t(`subscription.subscription-choice.description.${id}`)}</p>
              </div>
              <div className="addon-price">
                {price / 100}€<span>{t("subscription.subscription-choice.ttc-month")}</span>
              </div>
              <button
                onClick={clickHandler(price)}
                className={cn("second-driver-button", { active: !!selectedAddons[id] })}
              >
                {selectedAddons[id]
                  ? t("subscription.subscription-choice.remove")
                  : t("subscription.subscription-choice.add")}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default SecondDriverAddon;
