import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "store";
import { useDidMount, useGTM } from "hooks";
import { isEmpty } from "utils";
import { DEFAULT_PAGE } from "constants/routes.constants";
import ParticularSubscriptionDetails from "./components/ParticularSubscriptionDetails";
import ProfessionalSubscriptionDetails from "./components/ProfessionalSubscriptionDetails";

const ScooterSubscriptionSetup: React.FC = memo(() => {
  useGTM(5);

  const history = useHistory();

  const { setup, selectedPlan } = useSelector((state: RootState) => state.scooterSetup);
  const { cf_buyer_profile, cf_power, cf_model, cf_color } = setup;

  const [canShowSetup, setCanShowSetup] = useState(false);

  useDidMount(() => {
    if (!cf_buyer_profile || !cf_power || !cf_model || !cf_color || isEmpty(selectedPlan)) {
      return history.push(DEFAULT_PAGE.path);
    }
    setCanShowSetup(true);
  });

  if (!canShowSetup) return null;

  const Component =
    cf_buyer_profile === "particular"
      ? ParticularSubscriptionDetails
      : ProfessionalSubscriptionDetails;

  return <Component />;
});

export default ScooterSubscriptionSetup;
