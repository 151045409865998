import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { isValidNumber } from "libphonenumber-js";
import en from "react-phone-number-input/locale/en.json";
import fr from "react-phone-number-input/locale/fr.json";

import { setLanguage } from "utils";
import { blockInvalidChar } from "utils/inputs";

const useLocale = () => (setLanguage() === "en" ? en : fr);

function handleInputValue(value, country) {
  if (!value) {
    return "";
  }

  let textToShow = value.toString();

  if (country === "FR") {
    // First add space to each pair of symbols that isn't near the start nor end => 222_22_22_22 (there is no space at the end! like this 222_22_22_)
    textToShow = textToShow.replace(/((?!^.).{2})(?!$)/g, "$& ");

    // Then add space to start if there if more than 1 symbol => 2_22...
    if (textToShow.length > 1) {
      textToShow = textToShow.replace(/^./, "$& ");
    }
  }

  return textToShow;
}

export function PhoneInput({ t, onValidPhone, clearPhone, onError, error }) {
  const [country, setCountry] = useState("FR");
  const [countryCallingCode, setCountryCallingCode] = useState(getCountryCallingCode("FR"));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isError, setIsError] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const searchInputRef = useRef(null);
  const searchBlockRef = useRef(null);

  // Focus on search when it opens.
  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  // Handle outside click to close search menu
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (searchBlockRef.current && !searchBlockRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBlockRef]);

  function validatePhone() {
    if (!phoneNumber) {
      clearPhone && clearPhone();
      setIsError(false);
      return;
    }

    // If country is not France => do not validate phone, just pass it to onValidPhone.
    if (country !== "FR") {
      onValidPhone && onValidPhone(countryCallingCode, phoneNumber);
      setIsError(false);
      return;
    }

    // If country is France => validate phone.
    const isValid = isValidNumber(phoneNumber, country);

    if (!isValid) {
      onError && onError();
      setIsError(true);
      return;
    }

    onValidPhone && onValidPhone(countryCallingCode, phoneNumber);
    setIsError(false);
  }

  useEffect(() => {
    validatePhone();
  }, [country]);

  function handlePhoneEnter(value) {
    let numberToSave = value.replace(/\s+/g, "");

    if (isNaN(numberToSave)) {
      return;
    }

    if (numberToSave.toString() === "") {
      setPhoneNumber("");
      return;
    }

    // Removing leading '0' numbers for France.
    if (country === "FR") {
      numberToSave = parseInt(numberToSave, 10);
    }

    setPhoneNumber(numberToSave.toString());
  }

  function handleCountry(country) {
    if (country === "FR") {
      setPhoneNumber((prevState) => parseInt(prevState, 10).toString());
    }
    setIsSearchOpen(false);
    setCountry(country);
    setCountryCallingCode(getCountryCallingCode(country));
    setFilterText("");
  }

  return (
    <div className="phone-input-container">
      <div className={`phone-input-wrap ${isError || error ? "not-valid" : ""}`}>
        <button
          className={`phone-input-dropdown-button ${isError || error ? "not-valid" : ""}`}
          type={"button"}
          onClick={() => {
            setIsSearchOpen((prevState) => !prevState);
          }}
        >
          <div className={`dropdown-button-text ${isError || error ? "not-valid" : ""}`}>
            {country}
          </div>
          <div className="arrow-down" />
        </button>
        <div className="phone-inputs-wrap">
          <span>+{countryCallingCode}</span>
          &nbsp;
          <input
            className="phone-input"
            type="text"
            value={handleInputValue(phoneNumber, country)}
            onBlur={() => validatePhone()}
            onKeyDown={blockInvalidChar}
            onChange={(e) => handlePhoneEnter(e.target.value)}
            pattern="[\d|\s]+"
            inputMode="numeric"
          />
        </div>
      </div>
      {isSearchOpen && (
        <div className="country-phone-select-container" ref={searchBlockRef}>
          <div className="country-phone-search">
            <div className="search-icon" />
            <input
              className="search-input"
              ref={searchInputRef}
              type="text"
              placeholder={t("subscription.personal-information.phone-country-placeholder")}
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value || "");
              }}
            />
          </div>
          <ul className="country-phone-list">
            {getCountries().map((country) => {
              if (!filterText) {
                return (
                  <li
                    key={country}
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleCountry(country);
                      }
                    }}
                    onClick={() => handleCountry(country)}
                  >
                    <span className="country-name">{useLocale()[country]}</span>
                    &nbsp;
                    <span className="country-phone-number">+{getCountryCallingCode(country)}</span>
                  </li>
                );
              }
              if (
                filterText &&
                useLocale()[country].toLowerCase().includes(filterText.toLowerCase())
              ) {
                return (
                  <li
                    key={country}
                    tabIndex="0"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleCountry(country);
                      }
                    }}
                    onClick={() => handleCountry(country)}
                  >
                    <span className="country-name">{useLocale()[country]}</span>
                    &nbsp;
                    <span className="country-phone-number">+{getCountryCallingCode(country)}</span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
      {(error || isError) && (
        <span className="invalid-phone">
          {t("subscription.personal-information.invalid-phone")}
        </span>
      )}
    </div>
  );
}

PhoneInput.propTypes = {
  t: PropTypes.func.isRequired,
  onValidPhone: PropTypes.func,
  clearPhone: PropTypes.func,
  onError: PropTypes.func,
  error: PropTypes.bool,
};
