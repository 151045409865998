import { call, put } from "redux-saga/effects";

import * as a from "../../actions/account";

import { request } from "../../../utils/request";

export function* updatePhoneNumberSaga({ payload }) {
  const body = JSON.stringify({
    phone: payload,
  });

  try {
    yield call(request, `/customers/info`, "PUT", { body });

    yield put(a.updatePhoneNumberSuccess());
    yield put(a.getCustomerData());
  } catch (error) {
    yield put(a.updatePhoneNumberFailure(error));
  }
}
