const maxSize = 20; //20 MB

export const formatbytes = (bytes: number) => {
  return bytes / Math.pow(1024, 2);
};

export const isExceedSize = (weight: number) => formatbytes(weight) > maxSize;

export const isFormatValid = (format: string) => {
  const availableFormats = ["image/jpeg", "application/pdf", "image/png"];

  return availableFormats.includes(format);
};
