import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StepConnector, StepLabel, Step, Stepper as StepperMaterial } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import cn from "classnames";

import { StepsMap } from "components/client/routes";
import { arrayOf } from "utils/helpers.utils";

const QontoConnector = withStyles({
  active: {
    "& $line": {
      borderColor: "#F48040",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#F48040",
    },
  },
  line: {
    borderColor: "#fbccb3",
    borderTopWidth: 9,
  },

  "@media (max-width: 768px)": {
    line: {
      borderTopWidth: 4,
    },
  },
})(StepConnector);

const StepStyled = withStyles({
  root: {
    padding: 0,
  },
})(Step);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 18,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 18,
    height: 18,
    margin: "0 -2px",
    borderRadius: "50%",
    backgroundColor: "#fbccb3",
  },
  circle_completed: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: "#F48040",
    margin: "0 -2px",
  },

  "@media (max-width: 768px)": {
    circle_completed: {
      height: 8,
      width: 8,
    },
    circle: {
      margin: 0,
      height: 8,
      width: 8,
    },
  },
});

const StepLabelStyled = withStyles({
  iconContainer: {
    padding: 0,
  },
})(StepLabel);

const StepperStyled = withStyles({
  root: {
    padding: "0 0 0",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
})(StepperMaterial);

function QontoStepIcon(props: any) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={cn(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circle_completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const TextStepsMap: Record<number, string> = {
  1: "my-account.dashboard.my-offer",
  2: "subscription.header.my-personal-information",
  3: "subscription.header.my-payment-method",
  4: "subscription.header.my-documents",
  5: "subscription.header.my-signatures",
};

export const Stepper: React.FC = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const currentStep = StepsMap[pathname];

  if (!currentStep) return null;

  return (
    <div className="stepper-wrapper">
      <h3 className="stepper-header" aria-controls="stepper-menu">
        <span>
          {t("subscription.header.stage")} {currentStep}/5 -
        </span>
        {t(TextStepsMap[currentStep])}
      </h3>
      <div className="stepper">
        <span className="steps-mobile">{currentStep}/5</span>
        <StepperStyled activeStep={currentStep - 1} connector={<QontoConnector />}>
          {arrayOf(5, (index) => (
            <StepStyled key={index}>
              <StepLabelStyled StepIconComponent={QontoStepIcon} />
            </StepStyled>
          ))}
        </StepperStyled>
      </div>
    </div>
  );
});
