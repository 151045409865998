import React from "react";
import { useTranslation } from "react-i18next";

import ErrorImage from "assets/images/account/error.svg";

const SubscriptionError = () => {
  const { t } = useTranslation();

  return (
    <div className="account-wrap">
      <div className="common-wrap common-account">
        <div className="content">
          <div className="error-page">
            <img src={ErrorImage} alt="" />
            <h1>{t("my-account.error_page.error")}</h1>
            <h3>{t("my-account.error_page.inscription")}</h3>
            <p>{t("my-account.error_page.inctiprion_details")}</p>
            <p className={"contact"}>{t("my-account.error_page.support_text")}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("my-account.error_page.support_button"),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionError;
