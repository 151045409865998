import { useRef, useReducer } from "react";
import memoize from "memoizee";

import { FetchMiddlewareReturnType, ErrorResponse, Status } from "api/api.middleware";
import { useDidUnmount } from "hooks";
import { Nullable } from "models";

type SuccessCallback<T> = (payload: T) => void;
type OnSuccessCallback<T> = (callback: SuccessCallback<T>, dependencies: any[]) => void;

type ErrorCallback = (error: NonNullable<ErrorResponse>) => void;
type OnErrorCallback = (callback: ErrorCallback, dependencies: any[]) => void;

type State = { data: any; error: null | ErrorResponse; submitting: boolean; details: Status };

type Actions = {
  setSubmitting: (submitting: boolean) => void;
  setData: (data: any) => void;
  setError: (error: any) => void;
  setDetails: (details: Status) => void;
};

type Action =
  | { type: "setSubmitting"; submitting: boolean }
  | { type: "setData"; data: any }
  | { type: "setError"; error: null | ErrorResponse }
  | { type: "setDetails"; details: Status };

type UseCallReturnType<T, O extends any[]> = {
  error: null | ErrorResponse;
  submitting: boolean;
  submit: (...options: O) => void;
  actions: Actions;
  details: Status;
  onCallSuccess: OnSuccessCallback<T>;
  onCallError: OnErrorCallback;
};

type UseCallType = <R, O extends any[]>(
  fn: (...options: O) => FetchMiddlewareReturnType<R>,
) => UseCallReturnType<R, O>;

function reducer(state: any, action: Action) {
  switch (action.type) {
    case "setSubmitting":
      return { ...state, submitting: action.submitting };
    case "setData":
      return { ...state, data: action.data };
    case "setError":
      return { ...state, error: action.error };
    case "setDetails":
      return { ...state, details: action.details };
  }
}

const initialState: State = {
  data: null,
  error: null,
  submitting: false,
  details: { status: 0, isCanceled: false },
};

const memoizeOptions = {
  normalizer: (args: any) => JSON.stringify(args[1]),
};

const useCall: UseCallType = <R, O extends any[]>(
  asyncApiCall: (...options: O) => FetchMiddlewareReturnType<R>,
) => {
  const componentIsMounted = useRef(true);
  useDidUnmount(() => (componentIsMounted.current = false));

  const [state, dispatch] = useReducer(reducer, initialState);
  const actions: Actions = {
    setSubmitting: (submitting) => dispatch({ type: "setSubmitting", submitting }),
    setData: (data) => dispatch({ type: "setData", data }),
    setError: (error) => dispatch({ type: "setError", error }),
    setDetails: (details) => dispatch({ type: "setDetails", details }),
  };

  const onCallSuccess = useRef<Nullable<SuccessCallback<R>>>(null);
  const onCallError = useRef<Nullable<ErrorCallback>>(null);

  const handleSend = async (...options: O) => {
    const { setSubmitting, setData, setError, setDetails } = actions;

    setSubmitting(true);
    setError(null);

    const [payload, error, { status, isCanceled }] = await asyncApiCall(...options);

    if (!componentIsMounted.current) return;

    if (isCanceled) return setSubmitting(false);

    if (error && status !== 0) {
      setError(error);
      setDetails({ status, isCanceled });
      onCallError.current?.(error);
      setSubmitting(false);
      return;
    }

    setData(payload);
    setDetails({ status, isCanceled });
    onCallSuccess.current?.(payload as R);
    setSubmitting(false);
  };

  const handleSuccess = memoize((successCall: SuccessCallback<R>) => {
    onCallSuccess.current = successCall;
  }, memoizeOptions);

  const handleError = memoize((errorCall: ErrorCallback) => {
    onCallError.current = errorCall;
  }, memoizeOptions);

  return {
    error: state.error,
    submitting: state.submitting,
    actions: actions,
    submit: handleSend,
    details: state.details,
    onCallSuccess: handleSuccess,
    onCallError: handleError,
  };
};

export default useCall;
