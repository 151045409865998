import { takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACT_STATUS,
  GET_DOCUMENTS_LIST,
  GET_CUSTOMER_DATA,
  UPDATE_PHONE_NUMBER,
  GET_SUBSCRIPTION_DETAILS,
} from "../../constants/account";

import { getDocumentsSaga } from "./getDocuments";
import { getCustomerDataSaga } from "./getCustomerData";
import { updatePhoneNumberSaga } from "./updatePhoneNumber";
import { getSubscriptionDetailsSaga } from "./getSubscriptionDetails";
import { getContractStatusSaga } from "./getContractStatus";

export default function* () {
  yield takeLatest(GET_DOCUMENTS_LIST, getDocumentsSaga);
  yield takeLatest(GET_CUSTOMER_DATA, getCustomerDataSaga);
  yield takeLatest(UPDATE_PHONE_NUMBER, updatePhoneNumberSaga);
  yield takeLatest(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetailsSaga);
  yield takeLatest(GET_CONTRACT_STATUS, getContractStatusSaga);
}
