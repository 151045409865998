import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classnames from "classnames";

import UserImage from "assets/images/account/Information personnelle.svg";

export const MenuHoc = (WrappedComponent) => () => {
  const { location } = useHistory();
  const { t } = useTranslation();

  const {
    customer,
    subscription: { status },
  } = useSelector((state) => state.account);
  const { chargebeeCustomer } = customer;

  return (
    <div className={"account-wrap"}>
      {location.pathname !== "/account/subscription-error" && (
        <aside className="side-menu">
          <div className="clement">
            <img src={UserImage} alt="" />
            <span>{customer?.fleetManagerName || chargebeeCustomer?.first_name || "N/A"}</span>
          </div>
          <nav>
            {status === "active" ? null : (
              <Link
                to={"/account/dashboard"}
                className={classnames({ active: location.pathname === "/account/dashboard" })}
              >
                {t("my-account.dashboard.dashboard")}
              </Link>
            )}
            <Link
              to={"/account/personal"}
              className={classnames({ active: location.pathname === "/account/personal" })}
            >
              {t("my-account.menu.personal-information")}
            </Link>
            <Link
              to={"/account/contract"}
              className={classnames({ active: location.pathname === "/account/contract" })}
            >
              {t("my-account.menu.contract")}
            </Link>
            <Link
              to={"/account/bills"}
              className={classnames({ active: location.pathname === "/account/bills" })}
            >
              {t("my-account.menu.bills")}
            </Link>
            <Link
              to={"/account/payment"}
              className={classnames({ active: location.pathname === "/account/payment" })}
            >
              {t("my-account.menu.payment-method")}
            </Link>
            {status === "active" ? null : (
              <Link
                to={"/account/documents"}
                className={classnames({ active: location.pathname === "/account/documents" })}
              >
                {t("my-account.menu.documents")}
              </Link>
            )}
            <Link
              to={"/account/password"}
              className={classnames({ active: location.pathname === "/account/password" })}
            >
              {t("my-account.menu.change-password")}
            </Link>
          </nav>
          <span
            className="contact-us"
            dangerouslySetInnerHTML={{
              __html: t("my-account.menu.contact-us-link"),
            }}
          />
        </aside>
      )}
      <WrappedComponent />
    </div>
  );
};
