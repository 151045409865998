import { call, put, takeEvery } from "redux-saga/effects";

import * as a from "../actions/files.js";
import { getDocumentsList, getSubscriptionDetails } from "../actions";

import { UPLOAD_FILES } from "../constants/files";
import { CONTRACT_PAGE } from "constants/routes.constants";

const sendFilesRequest = (data, subscriptionId) => {
  const fullUrl = `${process.env.REACT_APP_API_URL || "https://api.zeway.com"}/freshsales`;
  const token = localStorage.getItem("token");

  const entriesList = Object.entries(data);
  const mapPropToDocumentType = {
    address: "ADDRESS",
    id: "PASSPORT",
    drivingLicense: "DRIVE_LICENCE",
    secondDrivingLicense: "SECOND_DRIVER_LICENSE",
    kbis: "KBIS",
  };

  const promises = entriesList
    .map((item) => {
      return item[1].map((file) => {
        const fileData = new FormData();
        fileData.append("file", file);
        fileData.append("document-type", mapPropToDocumentType[item[0]]);
        fileData.append("subscription-id", subscriptionId);
        return fetch(fullUrl, {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: fileData,
        })
          .then((response) => response)
          .catch((error) => error);
      });
    })
    .flat();
  return Promise.all(promises);
};

function* uploadFilesSaga({ payload }) {
  const { data, isManualUpload, subscriptionId, documentsToUpload, push } = payload;

  try {
    const responses = yield call(sendFilesRequest, data, subscriptionId);
    if (responses.some((response) => !response.ok)) {
      return yield put(
        a.uploadFilesFailure({
          documentsToUpload,
        }),
      );
    }
    yield put(
      a.uploadFilesSuccess({
        documentsToUpload,
      }),
    );

    if (!isManualUpload) {
      push && push(CONTRACT_PAGE.path);
      return;
    }
    yield put(getDocumentsList());
    yield put(getSubscriptionDetails(subscriptionId));
  } catch (error) {
    yield put(
      a.uploadFilesFailure({
        documentsToUpload,
        error,
      }),
    );
  }
}

export default function* () {
  yield takeEvery(UPLOAD_FILES, uploadFilesSaga);
}
