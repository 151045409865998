import React from "react";
import { useAuth0 } from "../../Auth";
import { useTranslation } from "react-i18next";

const EmailWarning = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  if (user && !user.email_verified && !user.picture) {
    return (
      <div className={"email_not_verified"}>
        {t("my-account.email.not_confirmed")} {user.email}
      </div>
    );
  }

  return null;
};

export default EmailWarning;
