import { useRef, useEffect } from "react";

type EventHandler<T extends Event = Event> = (e: T) => void;

type WindowEventHook = {
  <K extends keyof WindowEventMap>(eventName: K, handler: EventHandler<WindowEventMap[K]>): void;
};

const useWindowEvent: WindowEventHook = (eventName, handler) => {
  const handlerRef = useRef<typeof handler>();
  const didUnmount = useRef(false);

  const isClient = typeof window === "object";

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!isClient) return;

    const eventListener: typeof handler = (event) =>
      !didUnmount.current && handlerRef.current && handlerRef.current(event);

    window.addEventListener(eventName, eventListener);

    return () => {
      didUnmount.current = true;
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName]);
};

export default useWindowEvent;
