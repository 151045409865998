import { handleActions } from "redux-actions";

import * as c from "../constants/user";
import { UPLOAD_FILES, UPLOAD_FILES_FAILURE, UPLOAD_FILES_SUCCESS } from "../constants/files";

const initialState = {
  customerExtId: "", //ZCA202007022762
  subscriptionId: "", //TODO: delete hardcode//ZSA202007063226
  personalInfo: {
    //TODO: delete hardcode
    // buildingNumber: "ergegerg",
    // city: "ergergerg",
    // civility: "Mme",
    // complement: "gergergre",
    // contribution: 40000,
    // country: "ergergregerg",
    // dob: "1988-08-18",
    // firstName: "pavel",
    // lastName: "kalenyk",
    // phone: "+33233242342",
    // planId: "swapperone-for-particular",
    // postalCode: "ergerer",
    // profession: "Mme",
    // scooterColor: "blue",
    // street: "ergerg",
  },
  contract: {}, //{"id":"3z8r6t69237","expiration_date":"2020-07-20T21:59:00Z","preview_url":"https://app.getaccept.com/v/3z8r6t69237/7v2d5jcw/a/0ad7d5ca6d56813bc2619d62fcfd0879"},//TODO: delete hardcode
  loaders: {
    isAddingSecondDriver: false,
    isAddingDocuments: false,
  },
  documentLoadingStatus: {
    drivingLicense: {
      pending: false,
      success: false,
      error: false,
    },
    secondDrivingLicense: {
      pending: false,
      success: false,
      error: false,
    },
    id: {
      pending: false,
      success: false,
      error: false,
    },
    address: {
      pending: false,
      success: false,
      error: false,
    },
    kbis: {
      pending: false,
      success: false,
      error: false,
    },
  },
};

const userData = handleActions(
  new Map([
    [
      c.ADD_SECOND_DRIVER,
      (state) => ({
        ...state,
        loaders: {
          ...state.loaders,
          isAddingSecondDriver: true,
        },
      }),
    ],
    [
      c.ADD_SECOND_DRIVER_SUCCESS,
      (state) => ({
        ...state,
        loaders: {
          ...state.loaders,
          isAddingSecondDriver: false,
        },
      }),
    ],
    [
      c.ADD_SECOND_DRIVER_FAILURE,
      (state) => ({
        ...state,
        loaders: {
          ...state.loaders,
          isAddingSecondDriver: false,
        },
      }),
    ],
    [
      UPLOAD_FILES,
      (state, { payload }) => {
        const { documentsToUpload } = payload;

        const documentToSave = {};
        documentsToUpload.forEach((doc) => {
          documentToSave[doc] = {
            pending: true,
            success: false,
            error: false,
          };
        });

        return {
          ...state,
          loaders: {
            ...state.loaders,
            isAddingDocuments: true,
          },
          documentLoadingStatus: {
            ...state.documentLoadingStatus,
            ...documentToSave,
          },
        };
      },
    ],
    [
      UPLOAD_FILES_SUCCESS,
      (state, { payload }) => {
        const { documentsToUpload } = payload;

        const documentToSave = {};
        documentsToUpload.forEach((doc) => {
          documentToSave[doc] = {
            pending: false,
            success: true,
            error: false,
          };
        });

        return {
          ...state,
          loaders: {
            ...state.loaders,
            isAddingDocuments: false,
          },
          documentLoadingStatus: {
            ...state.documentLoadingStatus,
            ...documentToSave,
          },
        };
      },
    ],
    [
      UPLOAD_FILES_FAILURE,
      (state, { error }) => {
        const { documentsToUpload } = error;

        const documentToSave = {};
        documentsToUpload.forEach((doc) => {
          documentToSave[doc] = {
            pending: false,
            success: false,
            error: true,
          };
        });

        return {
          ...state,
          loaders: {
            ...state.loaders,
            isAddingDocuments: false,
          },
          documentLoadingStatus: {
            ...state.documentLoadingStatus,
            ...documentToSave,
          },
        };
      },
    ],
  ]),
  initialState,
);

export default userData;
