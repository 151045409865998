import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IBAN from "iban";
import { useTranslation } from "react-i18next";

import { mapHelperText, mapErrorText, mapLabelToInputName } from "../payment.constants";

const StyledTextField = withStyles({
  root: {
    marginTop: 14,
    "& fieldset": {
      border: "1px solid #1E285A",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1E285A",
    },

    "& label": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
    },
    "& input": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
    },
  },
})(TextField);

const Input = ({
  formik,
  name,
  error,
  onKeyDownMethod = (e) => {},
  onChangeMethod,
  onBlurMethod,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <StyledTextField
      {...rest}
      id={name}
      name={name}
      label={mapLabelToInputName[name]}
      helperText={t(error ? mapErrorText[name] : mapHelperText[name])}
      variant="outlined"
      value={formik.values[name]}
      onKeyDown={onKeyDownMethod}
      onChange={(e) => {
        let value = e.target.value.replace(/\s+/g, "");

        if (!value) {
          onChangeMethod(name, "");
          return;
        }
        // Prohibit to enter: • ◘ ○ ♦ ♣ ♠ ☺ ☻ ♥ ╞ ... etc.
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
          return;
        }

        if (name === "iban") {
          value = IBAN.printFormat(value, " ");
        }

        onChangeMethod(name, value, e);
      }}
      onBlur={onBlurMethod}
      error={error}
    />
  );
};

export default Input;
