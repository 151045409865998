import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { Auth0Provider } from "Auth";
import store from "store";
import { PERSONAL_INFO_PAGE } from "constants/routes.constants";
import config from "auth_config.json";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type Props = {
  children: React.ReactNode;
};

const Providers: React.FC<Props> = ({ children }: Props) => (
  <Provider store={store}>
    <BrowserRouter>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin + PERSONAL_INFO_PAGE.path}
      >
        {children}
      </Auth0Provider>
    </BrowserRouter>
  </Provider>
);

export default Providers;
