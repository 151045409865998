import * as c from "../constants/user";

export const addSecondDriver = (payload) => ({
  type: c.ADD_SECOND_DRIVER,
  payload,
});
export const addSecondDriverSuccess = (payload) => ({
  type: c.ADD_SECOND_DRIVER_SUCCESS,
  payload,
});
export const addSecondDriverFailure = (payload) => ({
  type: c.ADD_SECOND_DRIVER_FAILURE,
  payload,
});
