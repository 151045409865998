import { BuyerTypes, BuyerTypesKeys } from "models";

import Grey from "assets/images/scooter_colors/color_selector_gris.png";
import Blue from "assets/images/scooter_colors/color_selector_bleu.png";

export const validTypes: BuyerTypesKeys[] = [
  BuyerTypes.particular,
  BuyerTypes.professional,
  BuyerTypes.transport,
];

// Buyer type
export const mapNotificationTextToUserType: Record<BuyerTypesKeys, string> = {
  particular: "subscription.byuer-type.message-particular",
  professional: "subscription.byuer-type.message-professional",
  transport: "subscription.byuer-type.message-rider",
  salesperson: "subscription.byuer-type.message-professional",
  rider: "subscription.byuer-type.message-rider",
};

// Scooter power
export const mapNotificationTextToScooterPower: Record<string, string> = {
  "45": "subscription.power-choice.dob-50cc",
  "80": "subscription.power-choice.dob-125cc",
};

export const mapPower: Record<string, string> = {
  "45": "subscription.power-choice.50cc",
  "80": "subscription.power-choice.125cc",
};

// Scooter model
export const mapModelToScooterModel: Record<string, string> = {
  swapperOne: "subscription.scooter-choice.swapper-one",
  F10: "subscription.scooter-choice.F10",
};

export const mapModelToScooterDescription: Record<string, string> = {
  swapperOne: "subscription.scooter-choice.swapper-one.description",
  F10: "subscription.scooter-choice.F10.description",
};

// Scooter color
export const mapColorToScooterColor: Record<string, string> = {
  blue: "subscription.color-choice.blue",
  silver_grey: "subscription.color-choice.silver-gray",
  black: "subscription.color-choice.black",
};

export const mapColorToImage: Record<string, string> = {
  silver_grey: Grey,
  blue: Blue,
};
