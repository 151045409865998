import React from "react";
import { Route } from "react-router-dom";

import {
  SCOOTER_SETUP_PAGE,
  ACCOUNT_PAGE,
  DEFAULT_PAGE,
  CONTRACT_PAGE,
  PAYMENT_PAGE,
  DOCUMENTS_PAGE,
  PERSONAL_INFO_PAGE,
  SCOOTER_SUBSCRIPTION_SETUP_PAGE,
  GENERAL_CONDITIONS_PAGE,
  AUTHORIZATION_PAGE,
} from "constants/routes.constants";
import ScooterSetup from "pages/scooter-setup";
import ScooterSubscriptionSetup from "pages/scooter-subscription-setup";
import PersonalInfo from "pages/personal-info";
import GeneralConditions from "pages/general-conditions";
import Authorization from "pages/authorization";
import Payment from "pages/payment";
import Documents from "pages/documents";
import Contract from "pages/contract";
import Account from "containers/Account";

export interface RouteConfig {
  path: string;
  component: React.FC;
  name: string;
  exact: boolean;
}

export const RouteComponent: React.FC<RouteConfig> = ({ component, ...route }: RouteConfig) => (
  <Route {...route} component={component} />
);

export const StepsMap: Record<string, number> = {
  [DEFAULT_PAGE.path]: 1,
  [SCOOTER_SETUP_PAGE.path]: 1,
  [SCOOTER_SUBSCRIPTION_SETUP_PAGE.path]: 1,
  [PERSONAL_INFO_PAGE.path]: 2,
  [GENERAL_CONDITIONS_PAGE.path]: 2,
  [AUTHORIZATION_PAGE.path]: 2,
  [PAYMENT_PAGE.path]: 3,
  [DOCUMENTS_PAGE.path]: 4,
  [CONTRACT_PAGE.path]: 5,
};

export const routes: RouteConfig[] = [
  // Scooter Setup
  {
    path: SCOOTER_SETUP_PAGE.path,
    component: ScooterSetup,
    name: SCOOTER_SETUP_PAGE.name,
    exact: true,
  },
  // Scooter Subscription Setup
  {
    path: SCOOTER_SUBSCRIPTION_SETUP_PAGE.path,
    component: ScooterSubscriptionSetup,
    name: SCOOTER_SUBSCRIPTION_SETUP_PAGE.name,
    exact: true,
  },
  // Personal Info
  {
    path: PERSONAL_INFO_PAGE.path,
    component: PersonalInfo,
    name: PERSONAL_INFO_PAGE.name,
    exact: true,
  },
  // General conditions
  {
    path: GENERAL_CONDITIONS_PAGE.path,
    component: GeneralConditions,
    name: GENERAL_CONDITIONS_PAGE.name,
    exact: true,
  },
  // Authorization
  {
    path: AUTHORIZATION_PAGE.path,
    component: Authorization,
    name: AUTHORIZATION_PAGE.name,
    exact: true,
  },
  // Documents
  {
    path: DOCUMENTS_PAGE.path,
    component: Documents,
    name: DOCUMENTS_PAGE.name,
    exact: true,
  },
  // Payment
  {
    path: PAYMENT_PAGE.path,
    component: Payment,
    name: PAYMENT_PAGE.name,
    exact: true,
  },
  // Contract
  {
    path: CONTRACT_PAGE.path,
    component: Contract,
    name: CONTRACT_PAGE.name,
    exact: true,
  },
  // Account
  {
    path: ACCOUNT_PAGE.path,
    component: Account,
    name: ACCOUNT_PAGE.name,
    exact: false,
  },
  // ---
  {
    path: DEFAULT_PAGE.path,
    component: ScooterSetup,
    name: DEFAULT_PAGE.name,
    exact: true,
  },
];
