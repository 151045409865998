import fetchMiddleware from "api/api.middleware";
import { AlreadyRegisteredUserModel } from "models";

type UserInvoiceItem = {
  amount: number,
  entityId: string,
  id: string,
  quantity: number,
}


type UserInvoice = {
  amountDue: number,
  currency: string,
  date: string,
  downloadUrl: string,
  id: string
  items: UserInvoiceItem[],
  status: string,
  subscriptionId: string,
}

export const getUserInvoices = () =>
  fetchMiddleware<{ collectUrl: string, invoices: UserInvoice[]}>({
    method: "get",
    url: "/subscriptions/v2/invoices",
  });


export const getUserInvoice = (invoiceId: string) =>
  fetchMiddleware<{ downloadUrl: string }>({
    method: "get",
    url: `/subscriptions/invoices/${invoiceId}`,
  });


export const checkIfUserAlreadyRegistered = () =>
  fetchMiddleware<AlreadyRegisteredUserModel>({
    method: "get",
    url: "/customers/info",
  });

export const createSubscription = (data: any) =>
  fetchMiddleware<{ subscriptionId: string }>({
    method: "post",
    url: "/subscriptions",
    data,
  });
