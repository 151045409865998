import * as c from "../constants/account";

export const getDocumentsList = () => ({
  type: c.GET_DOCUMENTS_LIST,
});
export const getDocumentsListSuccess = (payload) => ({
  type: c.GET_DOCUMENTS_LIST_SUCCESS,
  payload,
});
export const getDocumentsListFailure = (error) => ({
  type: c.GET_DOCUMENTS_LIST_FAILURE,
  error,
});

export const getCustomerData = () => ({
  type: c.GET_CUSTOMER_DATA,
});
export const getCustomerDataSuccess = (payload) => ({
  type: c.GET_CUSTOMER_DATA_SUCCESS,
  payload,
});
export const getCustomerDataFailure = (error) => ({
  type: c.GET_CUSTOMER_DATA_FAILURE,
  error,
});

export const updatePhoneNumber = (payload) => ({
  type: c.UPDATE_PHONE_NUMBER,
  payload,
});
export const updatePhoneNumberSuccess = () => ({
  type: c.UPDATE_PHONE_NUMBER_SUCCESS,
});
export const updatePhoneNumberFailure = (error) => ({
  type: c.UPDATE_PHONE_NUMBER_FAILURE,
  error,
});

export const getSubscriptionDetails = (subscriptionId) => ({
  type: c.GET_SUBSCRIPTION_DETAILS,
  subscriptionId,
});
export const getSubscriptionDetailsSuccess = (payload) => ({
  type: c.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
});
export const getSubscriptionDetailsFailure = (error) => ({
  type: c.GET_SUBSCRIPTION_DETAILS_FAILURE,
  error,
});

export const getContractStatus = (subscriptionId) => ({
  type: c.GET_CONTRACT_STATUS,
  subscriptionId,
});
export const getContractStatusSuccess = (payload) => ({
  type: c.GET_CONTRACT_STATUS_SUCCESS,
  payload,
});
export const getContractStatusFailure = (error) => ({
  type: c.GET_CONTRACT_STATUS_FAILURE,
  error,
});
