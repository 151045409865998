import { useLocation } from "react-router-dom";

import { useDidUpdate } from "hooks";
import { pushDataLayerEvent } from "utils";

export const useGiveEventId = (): void => {
  const location = useLocation();
  const { pathname } = location;

  useDidUpdate(
    () => {
      pushDataLayerEvent({
        event: "giveEventID",
      });
    },
    [pathname],
    true,
  );
};

export default useGiveEventId;
