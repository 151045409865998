import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { TextField, CircularProgress, withStyles } from "@material-ui/core";

import { resetPassword } from "api";
import { useCall } from "hooks";
import { useAuth0 } from "Auth";
import { MenuHoc } from "./MenuHoc.js";

const StyledTextField = withStyles({
  root: {
    marginRight: 40,
    width: "50%",
    "& fieldset": {
      border: "1px solid #1E285A",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1E285A",
    },

    "& label": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
    },
    "& input": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
    },
    "@media (max-width: 1000px)": {
      marginRight: 0,
      width: "1000%",
    },
  },
})(TextField);

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "",
    )
    .required("email is required"),
});

const Password = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();

  const [isSuccess, setIsSuccess] = useState(false);

  const { submit, submitting, onCallSuccess } = useCall(resetPassword);
  onCallSuccess(() => setIsSuccess(true), []);

  const formik = useFormik({
    initialValues: {
      email: user.email || "",
    },
    validationSchema,
    onSubmit: ({ email }) => submit(email),
  });

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("my-account.password.reset_password")}</h3>
        <div className="divider" />
        {isSuccess ? (
          <div className="reset-password">
            <div className="reset-password-check-mail">{t("my-account.password.email_sent")}</div>
          </div>
        ) : submitting ? (
          <div style={{ display: "flex", marginTop: "15px", justifyContent: "center" }}>
            <CircularProgress size={59.5} />
          </div>
        ) : (
          <form className="reset-password" onSubmit={formik.handleSubmit}>
            <StyledTextField
              name={"email"}
              variant="outlined"
              label={t("my-account.password.recovery_email")}
              onChange={formik.handleChange}
              onBlur={(e) => {
                if (e.target.value) {
                  formik.setFieldTouched("email");
                  return;
                }
                formik.setFieldTouched("email", false, false);
              }}
              value={formik.values.email}
              error={(formik.touched.email && Boolean(formik.errors.email)) || false}
            />
            <button type="submit" disabled={submitting}>
              {t("my-account.password.reset_password")}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default MenuHoc(Password);
