// Scooter setup
export const SCOOTER_SETUP_PAGE = { path: "/scooter", name: "Scooter setup" };

// Scooter subscription setup
export const SCOOTER_SUBSCRIPTION_SETUP_PAGE = {
  path: "/subscription",
  name: "Subscription setup",
};

// Personal Info
export const PERSONAL_INFO_PAGE = { path: "/personal-infos", name: "Personal Info" };

// General conditions
export const GENERAL_CONDITIONS_PAGE = { path: "/general-conditions", name: "General conditions" };

// Authorization
export const AUTHORIZATION_PAGE = { path: "/authorization", name: "Authorization" };

// Payment
export const PAYMENT_PAGE = { path: "/billing", name: "Payment" };

// Documents
export const DOCUMENTS_PAGE = { path: "/documents", name: "Documents" };

// Contract
export const CONTRACT_PAGE = { path: "/contract", name: "Contract" };

// Account
export const ACCOUNT_PAGE = { path: "/account", name: "Account" };

// ---
export const DEFAULT_PAGE = { ...SCOOTER_SETUP_PAGE, path: "/" };
