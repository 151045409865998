import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import moment from "moment";

import { setLanguage } from "utils";
import { blockInvalidChar } from "utils/inputs";

function leapYear(year) {
  return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
}

function checkForZero(value) {
  const parsed = parseInt(value, 10);

  return parsed === 0;
}

function checkForNaN(value) {
  return isNaN(value);
}

function handleInputValueToShow(value) {
  if (!value) {
    return "";
  }

  return value;
}

export const DateOfBirth = ({ t, onValidDate, onError, onClear, error }) => {
  const [dayInput, setDayInput] = useState("");
  const [monthInput, setMonthInput] = useState("");
  const [yearInput, setYearInput] = useState("");

  const [dayInputInFocus, setDayInputInFocus] = useState(false);
  const [monthInputInFocus, setMonthInputInFocus] = useState(false);
  const [yearInputInFocus, setYearInputInFocus] = useState(false);

  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const [isValid, setIsValid] = useState(false);
  const [errorText, setErrorText] = useState("");

  function handleDayInput(e) {
    let value = e.target.value;

    if (value === "") {
      return setDayInput("");
    }
    if (checkForNaN(value)) {
      return;
    }
    if (checkForZero(value)) {
      return setDayInput("0");
    }

    const parsedValue = parseInt(value);

    if (parsedValue > 3) {
      monthRef.current.focus();
    }

    if (parsedValue > 31) {
      value = 31;
    } else if (parsedValue < 1) {
      value = 1;
    }

    setDayInput(value.toString());
  }

  function handleMonthInput(e) {
    let value = e.target.value;

    if (value === "") {
      return setMonthInput("");
    }
    if (checkForNaN(value)) {
      return;
    }
    if (checkForZero(value)) {
      return setMonthInput("0");
    }

    const parsedValue = parseInt(value);

    if (parsedValue > 1) {
      yearRef.current.focus();
    }

    if (parsedValue > 12) {
      value = 12;
    } else if (parsedValue < 1) {
      value = 1;
    }

    setMonthInput(value.toString());
  }

  function handleYearInput(e) {
    const value = e.target.value;

    if (value === "") {
      return setYearInput("");
    }
    if (checkForZero(value) || checkForNaN(value)) {
      return;
    }

    setYearInput(value);
  }

  // Handle Backspace button to focus previous input.
  function handleDayFocus(e) {}

  function handleMonthFocus(e) {
    // Focus backward
    if (e.key === "Backspace" && !monthInput.length) {
      dayRef.current.focus();
    }
  }

  function handleYearFocus(e) {
    // Focus backward
    if (e.key === "Backspace" && !yearInput.length) {
      monthRef.current.focus();
    }
  }

  // Change focus
  // Use useEffect because onKeyDown attr method doesn't have current state info and we can't correctly get length of inputs
  useEffect(() => {
    // Focus forward
    if (dayInput.length >= 2 && !(monthInput.length >= 2)) {
      monthRef.current.focus();
      return;
    }
    if (dayInput.length >= 2 && monthInput.length >= 2 && yearInput.length < 4) {
      yearRef.current.focus();
    }
  }, [dayInput]);
  useEffect(() => {
    // Focus forward
    if (monthInput.length >= 2 && yearInput.length < 4) {
      yearRef.current.focus();
    }
  }, [monthInput]);

  // Add "0" to input if it loses focus and value < 10.
  // Check for length < 2 is for to not add "0" if user enters "01, 02, ... 09"
  // Use useEffect because onBlur attr method doesn't have current state info and we can't correctly get length of inputs.
  useEffect(() => {
    if (dayInputInFocus) return;

    // If user entered only "0" to day input and lost focus -> clear day input.
    if (checkForZero(dayInput)) {
      setDayInput("");
      return;
    }

    if (dayInput && parseInt(dayInput, 10) < 10 && dayInput.length < 2) {
      setDayInput("0" + dayInput.toString());
    }
  }, [dayInputInFocus]);
  useEffect(() => {
    if (monthInputInFocus) return;

    // If user entered only "0" to month input and lost focus -> clear month input.
    if (checkForZero(monthInput)) {
      setMonthInput("");
      return;
    }

    if (monthInput && parseInt(monthInput, 10) < 10 && monthInput.length < 2) {
      setMonthInput("0" + monthInput.toString());
    }
  }, [monthInputInFocus]);

  // Handle of day depending on month and year
  useEffect(() => {
    const parsedDay = parseInt(dayInput, 10);
    const parsedMonth = parseInt(monthInput, 10);
    const parsedYear = parseInt(yearInput);

    if (
      (parsedMonth === 4 || parsedMonth === 6 || parsedMonth === 9 || parsedMonth === 11) &&
      parsedDay >= 31
    ) {
      setDayInput("30");
      return;
    }

    if (parsedMonth === 2 && parsedDay > 29 && leapYear(parsedYear)) {
      setDayInput("29");
      return;
    }

    if (parsedMonth === 2 && parsedDay > 28 && !leapYear(parsedYear)) {
      setDayInput("28");
    }
  }, [dayInput, monthInput, yearInput]);

  useEffect(() => {
    if (error) {
      setIsValid(false);
      !errorText && setErrorText("subscription.personal-information.date-of-birth-error");
    }
  }, [error]);

  // Validate date.
  useEffect(() => {
    if (
      !dayInput ||
      !monthInput ||
      !yearInput ||
      dayInputInFocus ||
      monthInputInFocus ||
      yearInputInFocus
    ) {
      onClear && onClear();
      setIsValid(true);
      setErrorText("");
      return;
    }

    if (yearInput.length !== 4) {
      onError && onError();
      setIsValid(false);
      setErrorText("subscription.personal-information.date-of-birth-year-error");
      return;
    }

    // subtract "-1" from month because months countdown start from 0.
    const dateEntered = new Date(
      parseInt(yearInput),
      parseInt(monthInput, 10) - 1,
      parseInt(dayInput, 10),
    );

    // Check if date entered is > than current OR year difference is > than 200 OR not valid.
    if (
      dateEntered >= new Date() ||
      moment().diff(dateEntered, "years") > 200 ||
      !moment(dateEntered, "DD-MM-YYYY").isValid()
    ) {
      onError && onError();
      setIsValid(false);
      setErrorText("subscription.personal-information.date-of-birth-error");
      return;
    }

    // Date is valid
    onValidDate && onValidDate(dateEntered);
    setIsValid(true);
    setErrorText("");
  }, [dayInput, monthInput, yearInput, dayInputInFocus, monthInputInFocus, yearInputInFocus]);

  return (
    <div className={"date-input-container"}>
      <input
        className={classnames("date-input-day date-input", { error: !isValid && error })}
        onKeyDown={(e) => {
          blockInvalidChar(e);
          handleDayFocus(e);
        }}
        onChange={handleDayInput}
        value={handleInputValueToShow(dayInput)}
        onBlur={() => setDayInputInFocus(false)}
        onFocus={() => setDayInputInFocus(true)}
        placeholder={setLanguage() === "fr" ? "Jour" : "Day"}
        ref={dayRef}
        maxLength={2}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
      />
      <input
        className={classnames("date-input-month date-input", { error: !isValid && error })}
        onKeyDown={(e) => {
          blockInvalidChar(e);
          handleMonthFocus(e);
        }}
        onChange={handleMonthInput}
        value={handleInputValueToShow(monthInput)}
        onBlur={() => setMonthInputInFocus(false)}
        onFocus={() => setMonthInputInFocus(true)}
        placeholder={setLanguage() === "fr" ? "Mois" : "Month"}
        ref={monthRef}
        maxLength={2}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
      />
      <input
        className={classnames("date-input-year date-input", { error: !isValid && error })}
        onKeyDown={(e) => {
          blockInvalidChar(e);
          handleYearFocus(e);
        }}
        onChange={handleYearInput}
        value={handleInputValueToShow(yearInput)}
        onBlur={() => setYearInputInFocus(false)}
        onFocus={() => setYearInputInFocus(true)}
        placeholder={setLanguage() === "fr" ? "Année" : "Year"}
        ref={yearRef}
        maxLength={4}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
      />
      {errorText && <div className="date-input-error-wrap">{t(errorText)}</div>}
    </div>
  );
};
