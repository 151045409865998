import React, { memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SelectButton } from "components";
import { Footer } from "components/Footer";
import { RootState, setScooterColor, setScooterSetup, setSelectedPlan } from "store";
import { useGTM, useQuery, useDidMount } from "hooks";
import { BuyerTypesKeys } from "models";
import { uniqBy, isEqual } from "utils";
import { SCOOTER_SUBSCRIPTION_SETUP_PAGE } from "constants/routes.constants";
import {
  mapPower,
  mapModelToScooterModel,
  mapColorToScooterColor,
  mapColorToImage,
} from "../scooter-setup.constants";
import { buildImageUrl } from "../scooter-setup.utils";

type QueryType = {
  cf_buyer_profile: BuyerTypesKeys | "";
  cf_power: string;
  cf_model: string;
  cf_color?: string;
};

const ScooterColor: React.FC = memo(() => {
  useGTM(4);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { cf_buyer_profile, cf_power, cf_model } = useSelector(
    (state: RootState) => state.scooterSetup.setup,
  );
  const { query, setQueryParam, updateQueryParams } = useQuery<QueryType>();

  const plans = useSelector((state: RootState) => state.scooterData.plans);

  const filteredByBuyerPlans = useMemo(() => {
    return plans.filter((el) => el.cf_buyer_profile === cf_buyer_profile);
  }, [cf_buyer_profile]);

  const availableColorArr = useMemo(() => {
    const colorsArr = filteredByBuyerPlans.map((plan) => plan.meta_data.scooter_colors).flat();
    return uniqBy(colorsArr, "name");
  }, [filteredByBuyerPlans, cf_buyer_profile]);

  const colorType = availableColorArr.find(({ name }) => query.cf_color === name);
  const selectedColorName = colorType?.name;

  const blockClickHandler = (data: string) => () => setQueryParam("cf_color", data);

  const handleNextButton = () => {
    const selectedPlan = filteredByBuyerPlans.find((plan) => {
      const sameBuyer = plan.cf_buyer_profile === cf_buyer_profile;
      const samePower = Number(plan.cf_power) === Number(cf_power);
      const sameModel = plan.cf_scooter_model === cf_model;
      const sameColor = plan.meta_data.scooter_colors.find((color) => isEqual(color, colorType));
      return sameBuyer && samePower && sameModel && sameColor;
    });

    if (!selectedColorName || !selectedPlan) return;

    dispatch(setScooterColor(selectedColorName));
    dispatch(setSelectedPlan(selectedPlan));
    history.push(SCOOTER_SUBSCRIPTION_SETUP_PAGE.path);
  };

  const handlePrevButton = () => {
    updateQueryParams({
      cf_buyer_profile: "",
      cf_power: "",
      cf_model: "",
      cf_color: "",
    });

    dispatch(
      setScooterSetup({
        cf_buyer_profile: "",
        cf_model: "",
        cf_power: "",
        cf_color: "",
      }),
    );
  };

  useDidMount(() => {
    const length = availableColorArr.length;
    if (!length) return handlePrevButton();

    const color = colorType?.name || availableColorArr[0].name;

    setQueryParam("cf_color", color);
  });

  return (
    <>
      <div className="common-wrap">
        <div className="content-with-image-wrap">
          <div className="scooter-image">
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={buildImageUrl(cf_model, selectedColorName, "large")}
              />
              <source
                media="(max-width: 768px)"
                srcSet={buildImageUrl(cf_model, selectedColorName, "small")}
              />

              <img src={buildImageUrl(cf_model, selectedColorName, "large")} alt="" />
            </picture>
          </div>
          <div className="image-content">
            <h3 className="header">
              {availableColorArr.length > 1
                ? t("subscription.color-choice.question")
                : t("subscription.color-choice.statement")}
            </h3>
            <div className="divider" />

            <div className="scooter-info">
              <h4>{t("subscription.color-choice.scooter")}</h4>
              <p>
                {t(mapModelToScooterModel[cf_model])} - {t("subscription.power-choice.equivalent")}{" "}
                {t(mapPower[cf_power])}
              </p>
            </div>

            <div className="user-types-wrap">
              {availableColorArr.map(({ name }) => {
                return (
                  <SelectButton
                    key={name}
                    className="user-type"
                    selected={name === selectedColorName}
                    disabled={name === selectedColorName}
                    onClick={blockClickHandler(name)}
                  >
                    <div className="model">{t(mapColorToScooterColor[name])}</div>
                    <div className="color">
                      <img src={mapColorToImage[name]} alt="" />
                    </div>
                  </SelectButton>
                );
              })}
            </div>
            {selectedColorName && (
              <>
                <div className="delivery-wrap">
                  <h3>{t("subscription.color-choice.delivery")}</h3>
                  <p>
                    {t(
                      !!colorType?.available
                        ? "subscription.scooter-choice.1-3-weeks"
                        : "subscription.scooter-choice.2-months",
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer
        nextButtonId="scooter-color-select"
        isNextButtonDisabled={!selectedColorName}
        nextButtonHandler={handleNextButton}
        prevButtonHandler={handlePrevButton}
      />
    </>
  );
});

export default ScooterColor;
