import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import countriesMultilang from "i18n-iso-countries";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { registerLocale } from "react-datepicker";
import { fr, es } from "date-fns/locale";
import { useAuth0 } from "../../Auth";
import {
  titlesList,
  professionsList,
  mapLabelToInputName,
  mapHelperText,
} from "../../utils/mappers";
import { withStyles } from "@material-ui/core/styles";

import { PERSONAL_INFO_PAGE } from "constants/routes.constants";

import { StyledMenuItem } from "../Common";
import ProfessionalPersonalInfoForm from "../../pages/personal-info/components/ProfessionalPersonalInfoForm";
import ParticularPersonalInfoForm from "../../pages/personal-info/components/ParticularPersonalInfoForm";

registerLocale("fr", fr);
registerLocale("es", es);
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/es.json"));

const StyledInputLabel = withStyles({
  root: {
    font: "18px/21px Betm Rounded",
    color: "#1E285A",
    left: "18.5px",
    opacity: "0.5",
    top: "10px",
  },
})(InputLabel);

const StyledTextField = withStyles({
  root: {
    marginTop: 14,
    zIndex: 0,
    "& fieldset": {
      border: "1px solid #1E285A",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1E285A",
    },

    "& label": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
      opacity: "0.5",
    },
    "& .MuiInputLabel-shrink": {
      opacity: "1",
    },
    "& input": {
      font: "18px/21px Betm Rounded",
      color: "#1E285A",
    },

    "& > div": {
      height: "58px",
    },
  },
})(TextField);

export const PersonalInfoForm = ({
  paymentDetails,
  checkIfUserAlreadyRegistered,
  isCreatingNewSubscription,
  createNewSubscription,
  currentPlan,
  scooterDetails,
  firstPayment,
  t,
  coupon,
}) => {
  const { cf_buyer_profile } = currentPlan;

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      // TODO: FIX STORAGE
      localStorage.setItem("scooterDetails", JSON.stringify(scooterDetails));
      localStorage.setItem("currentPlan", JSON.stringify(currentPlan));
      localStorage.setItem("firstPayment", firstPayment);
      localStorage.setItem("coupon", JSON.stringify(coupon));
      localStorage.setItem("paymentDetails", JSON.stringify(paymentDetails));

      loginWithRedirect({
        screen_hint: "signup",
        redirect_uri: window.location.origin + PERSONAL_INFO_PAGE.path,
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      checkIfUserAlreadyRegistered();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <></>;

  return (
    <>
      {cf_buyer_profile === "professional" ? (
        <ProfessionalPersonalInfoForm
          t={t}
          coupon={coupon}
          currentPlan={currentPlan}
          firstPayment={firstPayment}
          paymentDetails={paymentDetails}
          scooterDetails={scooterDetails}
          isAuthenticated={isAuthenticated}
          createNewSubscription={createNewSubscription}
          isCreatingNewSubscription={isCreatingNewSubscription}
        />
      ) : (
        <ParticularPersonalInfoForm
          t={t}
          coupon={coupon}
          currentPlan={currentPlan}
          firstPayment={firstPayment}
          paymentDetails={paymentDetails}
          scooterDetails={scooterDetails}
          isAuthenticated={isAuthenticated}
          createNewSubscription={createNewSubscription}
          isCreatingNewSubscription={isCreatingNewSubscription}
        />
      )}
    </>
  );
};

export const TitleSelect = ({ t, formik, name, error }) => {
  const [isSelected, setIsSelected] = useState(!!formik.values[name]);
  return (
    <FormControl>
      {!isSelected && name === "profession" && (
        <StyledInputLabel>
          {t("subscription.personal-information.job-placeholder")}
        </StyledInputLabel>
      )}
      <StyledTextField
        select
        variant="outlined"
        id={name}
        name={name}
        value={formik.values[name]}
        onChange={(e) => {
          setIsSelected(true);
          formik.handleChange(e);
          formik.setFieldTouched(name, true);
        }}
        error={error}
        helperText={formik.touched[name] && formik.errors[name] && t(mapHelperText[name])}
      >
        {name === "civility" || name === "driverCivility"
          ? titlesList.map((item, index) => (
              <StyledMenuItem key={index} value={item.value}>
                {t(item.key)}
              </StyledMenuItem>
            ))
          : professionsList.map((item, index) => (
              <StyledMenuItem key={index} value={item.value}>
                {t(item.key)}
              </StyledMenuItem>
            ))}
      </StyledTextField>
    </FormControl>
  );
};

export const Input = ({ t, formik, name, error, onBlurMethod }) => {
  const mapHelper = {
    firstName: "subscription.personal-information.help-firstname",
    lastName: "subscription.personal-information.help-lastname",
    ape: "subscription.professinal-information.help-APE",
  };

  return (
    <StyledTextField
      label={t(mapLabelToInputName[name])}
      variant="outlined"
      id={name}
      name={name}
      value={formik.values[name]}
      onChange={(e) => {
        formik.handleChange(e);

        // Fix bug when field is empty but error is shown for postal codes.
        if (
          (name === "postalCode" || name === "workPostalCode" || name === "proPostalCode") &&
          !e.target.value
        ) {
          formik.setFieldTouched(name, false);
        }
      }}
      error={error}
      helperText={error ? t(mapHelperText[name]) : t(mapHelper[name])}
      onBlur={() => {
        if (name === "postalCode" || name === "workPostalCode" || name === "proPostalCode") {
          onBlurMethod && onBlurMethod(name);
        }
      }}
    />
  );
};

TitleSelect.propTypes = {
  t: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

Input.propTypes = {
  t: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  onBlurMethod: PropTypes.func,
};

PersonalInfoForm.propTypes = {
  createNewSubscription: PropTypes.func.isRequired,
  currentPlan: PropTypes.object.isRequired,
  scooterDetails: PropTypes.object.isRequired,
  firstPayment: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  coupon: PropTypes.object.isRequired,
  isCreatingNewSubscription: PropTypes.bool.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  setPaymentDetails: PropTypes.func.isRequired,
};
