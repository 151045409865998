import { UPLOAD_FILES, UPLOAD_FILES_FAILURE, UPLOAD_FILES_SUCCESS } from "../constants/files";

export const uploadFiles = (payload) => ({
  type: UPLOAD_FILES,
  payload,
});
export const uploadFilesSuccess = (payload) => ({
  type: UPLOAD_FILES_SUCCESS,
  payload,
});
export const uploadFilesFailure = (error) => ({
  type: UPLOAD_FILES_FAILURE,
  error,
});
