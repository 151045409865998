import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";

import { ParticularSubscriptionDetailsContent } from "pages/scooter-subscription-setup/components/ParticularSubscriptionDetailsContent";
import { ProfessionalSubscriptionDetailsContent } from "pages/scooter-subscription-setup/components/ProfessionalSubscriptionDetailsContent";
import { checkForIntegerToFixed } from "utils/prices";
import { proLikeTypes } from "models";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 400,
  },
  "@media (max-width: 768px)": {
    paper: {
      borderRadius: "0!important",
      left: "0!important",
      top: "50px!important",
      minWidth: "100%",
      minHeight: "97%",
      width: "100%",
      height: "97%",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const PriceDetailDropdown = memo(() => {
  const { t } = useTranslation();

  const { setup, paymentDetails } = useSelector((state) => state.scooterSetup);
  const { cf_buyer_profile } = setup;

  const {
    monthlyPayment,
    insurance: { driverUnder25, birthdateUnder25, ins25Price, insBasePrice },
  } = paymentDetails;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <div className="price-details-wrap">
      <button aria-controls="customized-menu" onClick={handleClick}>
        <div className="price">
          {checkForIntegerToFixed(
            monthlyPayment + (driverUnder25 || birthdateUnder25 ? ins25Price : insBasePrice),
          )}
          <span>
            {t("subscription.header.ttc-month")}
            €&nbsp;
          </span>
        </div>
        <div className="icon">{anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        <div className="icon mobile">{anchorEl ? <CloseIcon /> : <ExpandMoreIcon />}</div>
      </button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*Wrap content with div because the child of a Material-UI Tooltip must be able to hold a ref and FC(s) can't have it*/}
        <div>
          {proLikeTypes.includes(cf_buyer_profile) ? (
            <ProfessionalSubscriptionDetailsContent hideButtons />
          ) : (
            <ParticularSubscriptionDetailsContent hideButtons />
          )}
        </div>
      </StyledMenu>
    </div>
  );
});
