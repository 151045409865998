import moment from "moment";
import { arrayOf } from "utils";

export type GoogleTagSupportedEvents =
  | "giveEventID"
  | "pageview"
  | "register_or_login"
  | "generationContrat";

type PushDataLayerData = {
  event: GoogleTagSupportedEvents;
} & Record<string, any>;

export const pushDataLayerEvent = (data: PushDataLayerData, disableEventIds = false) => {
  // NOTE: crutch for development
  // Do not push during localhost due to crush error
  if (window.location.href.includes("localhost")) return;

  if (!data.event) return;

  let dataLayer = window.dataLayer;

  if (!Array.isArray(dataLayer)) {
    window.dataLayer = [];
    dataLayer = window.dataLayer;
  }

  if (!disableEventIds) {
    const now = moment().valueOf();
    const eventIds = arrayOf(4, (index) => {
      return "" + now + Math.floor(1000 + Math.random() * 9000) + (index + 1);
    });

    dataLayer.push({
      eventID1: eventIds[0],
      eventID2: eventIds[1],
      eventID3: eventIds[2],
      eventID4: eventIds[3],
      ...data,
    });
  } else {
    dataLayer.push(data);
  }
};
