export const appEnv = process.env.REACT_APP_ENV || "development";

interface Environment {
  apiUrl: string;
}

const environments: Record<string, Environment> = {
  production: {
    apiUrl: process.env.REACT_APP_API_URL || "https://app.zeway.com",
  },
  development: {
    apiUrl: process.env.REACT_APP_API_URL || "https://web.develop.zeway.com",
  },
};

export const appEnvironment: Environment = environments[appEnv];
