import fetchMiddleware, { FetchMiddlewareReturnType } from "api/api.middleware";

const networkTimeout = (timeout: number): FetchMiddlewareReturnType<null> =>
  new Promise((resolve) =>
    setTimeout(
      () =>
        resolve([
          null,
          {
            message: "Timeout",
            statusCode: 408,
            errors: [],
            formattedMessage: "Timeout",
          },
          {
            isCanceled: false,
            status: 408,
          },
        ]),
      timeout,
    ),
  );

type IPType = {
  "API Help": "https://getjsonip.com/#docs";
  "geo-ip": "https://getjsonip.com/#plus";
  ip: "91.201.168.106";
};

const getIPCall = (): FetchMiddlewareReturnType<IPType> =>
  new Promise(async (resolve) => {
    fetch("https://jsonip.com", { mode: "cors" })
      .then<IPType>((data) => data.json())
      .then((data) => {
        resolve([
          data,
          null,
          {
            isCanceled: false,
            status: 200,
          },
        ]);
      })
      .catch(() => {
        resolve([
          null,
          {
            message: "error",
            statusCode: 400,
            errors: [],
            formattedMessage: "error",
          },
          {
            isCanceled: false,
            status: 400,
          },
        ]);
      });
  });

export const getIP = (): FetchMiddlewareReturnType<IPType> =>
  Promise.race([getIPCall(), networkTimeout(5000)]);

export const acceptAgreement = (subscriptionId: string, data: any) =>
  fetchMiddleware({
    method: "put",
    url: `/subscriptions/${subscriptionId}/agreement`,
    data,
  });
