import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { generateContract } from "api";
import { useGTM, useFetch, useDidMount, useDidUpdate } from "hooks";
import { RootState, ScooterSetupState } from "store";
import { NonNullableKeys } from "models";
import { useAuth0 } from "Auth";
import { isEmpty, pushDataLayerEvent } from "utils";
import { DEFAULT_PAGE } from "constants/routes.constants";
import { ContractOnline } from "./components/ContractOnline";
import { ContractOffline } from "./components/ContractOffline";
import { QuoteOnline } from "./components/QuoteOnline";
import { QuoteOffline } from "./components/QuoteOffline";

const Contract: React.FC = memo(() => {
  useGTM(12);

  const history = useHistory();
  const { isAuthenticated, user } = useAuth0();

  const { subscriptionId, selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const apiCall = () => generateContract(subscriptionId);
  const { payload, loading } = useFetch(apiCall, [], !isAuthenticated || !subscriptionId);

  const { cf_buyer_profile, meta_data } = selectedPlan || {};
  const online_signing =
    meta_data && meta_data.contract_template ? meta_data.contract_template.online_signing : false;

  const [isMounted, setIsMounted] = useState(false);

  useDidMount(() => {
    if (isEmpty(subscriptionId)) return history.push(DEFAULT_PAGE.path);
    setIsMounted(true);
  });

  useDidUpdate(() => {
    if (!payload || !user.email) return;

    pushDataLayerEvent({
      event: "generationContrat",
      clientEmail: user.email,
    });
  }, [payload, user.email]);

  // TODO: dirty hack, maybe fix it later?
  if (!isMounted) return null;

  return cf_buyer_profile === "professional" && paymentDetails.subsidySelected ? (
    <>
      {online_signing ? (
        <QuoteOnline contract={payload} isGenerating={loading} />
      ) : (
        <QuoteOffline />
      )}
    </>
  ) : (
    <>
      {online_signing ? (
        <ContractOnline contract={payload} isGenerating={loading} />
      ) : (
        <ContractOffline />
      )}
    </>
  );
});

export default Contract;
