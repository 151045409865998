import fetchMiddleware from "api/api.middleware";
import { PlansAndAddonsType } from "./plans.interface";

export const getPlansAndAddons = () =>
  fetchMiddleware<PlansAndAddonsType>({
    method: "get",
    url: "/plans",
    headers: {
      Authorization: "",
    },
  });
