import { useEffect } from "react";

type CallbackType = () => void;

type UseDidMountType = (f: CallbackType) => void;

const useDidMount: UseDidMountType = (f) =>
  useEffect(() => {
    const rT = (f() as any) || "";
    if (rT && rT.constructor.name === "Function") {
      return rT;
    }
  }, []);

export default useDidMount;
