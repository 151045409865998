import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import cn from "classnames";

export const Footer = ({
  isNextButtonDisabled = false,
  isPrevButtonDisabled = false,
  nextButtonHandler,
  prevButtonHandler,
  nextButtonText = "subscription.professtional-information.next",
  notificationText,
  skipStepHandler,
  type = "button",
  isLoading,
  nextButtonId = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      {notificationText && <p className="notification mobile">{t(notificationText)}</p>}
      {prevButtonHandler && (
        <div className="prev-button-wrap">
          <button
            onClick={(e) => {
              window.scrollTo(0, 0);
              prevButtonHandler(e);
            }}
            disabled={isPrevButtonDisabled}
            type="button"
          />
        </div>
      )}
      <p className="notification desktop">{t(notificationText)}</p>
      <div className={cn("next-button-wrap", "mui-fixed", { skipActive: !!skipStepHandler })}>
        {skipStepHandler && (
          <>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                skipStepHandler();
              }}
              className="skip-mobile"
              disabled={isLoading}
            >
              {t("subscription.payment-information.skip-mobile")}
            </button>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                skipStepHandler();
              }}
              className="skip"
              disabled={isLoading}
            >
              {t("subscription.second-driver.skip")}
            </button>
          </>
        )}
        <div className="next-wrap">
          {isLoading && <Loader type="Oval" color="#254C91" height={20} width={20} />}

          <button
            id={nextButtonId}
            className={"next mui-fixed"}
            onClick={() => {
              window.scrollTo(0, 0);
              nextButtonHandler && nextButtonHandler();
            }}
            disabled={isNextButtonDisabled || isLoading}
            type={type}
          >
            {t(nextButtonText)}
          </button>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  nextButtonId: PropTypes.string,
  isNextButtonDisabled: PropTypes.bool,
  isPrevButtonDisabled: PropTypes.bool,
  nextButtonText: PropTypes.string,
  prevButtonHandler: PropTypes.func,
  nextButtonHandler: PropTypes.func,
  notification: PropTypes.string,
  type: PropTypes.string,
  skipStepHandler: PropTypes.func,
  notificationText: PropTypes.string,
  isLoading: PropTypes.bool,
};
