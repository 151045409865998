import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import {setLanguage} from "utils";
import Backend from "i18next-fetch-backend";

i18n
  .use(Backend)
  .use(new LanguageDetector())
  .use(initReactI18next)
  .init(
    {
      /**
       * Default lang
       */
      lng: setLanguage(),
      fallbackLng: setLanguage(),
      debug: true,
      appendNamespaceToCIMode: true,
      appendNamespaceToMissingKey: true,
      transSupportBasicHtmlNodes: true,
      defaultNS: "Zeway",
      nsSeparator: "::",
      keySeparator: false,
      // ns: getDefaultNS(pathname),
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ",",
        format(value, format) {
          if (format === "uppercase") return value.toUpperCase();
          return value;
        },
      },
      /**
       * reactI18nextModule module Options
       */
      react: {
        wait: true,
        withRef: false,
        bindI18n: "languageChanged loaded",
        bindStore: false,
        nsMode: "default",
      },
      /**
       * Backend module Options
       */
      backend: {
        loadPath: "/locales/{{lng}}.json",
        init: {
          mode: "cors",
          // credentials: 'same-origin',
          cache: "default",
        },
      },
      /**
       * Lang Detection module Options
       */
      detection: {
        order: ["langDetector"],
        lookupCookie: "lang",
        lookupLocalStorage: "locale",
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        caches: ["langDetector"],
      },
    },
    (err) => {
      if (err) {
        return console.error("error in i18n", err);
      }
    },
  );
//i18n.translator.add();
export default i18n;
