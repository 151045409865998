import Radio from "@material-ui/core/Radio";

import { withStyles } from "@material-ui/core/styles";

export default withStyles({
  root: {
    "&$checked": {
      color: "#1E285A",
    },
  },
  checked: {},
})(Radio);
