import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Checkbox } from "components/Inputs/Checkbox";
import { Footer } from "components/Footer";
import { getIP, acceptAgreement } from "api";
import { useGTM, useCall, useFetch, useDidUpdate } from "hooks";
import { AUTHORIZATION_PAGE, DEFAULT_PAGE } from "constants/routes.constants";
import { NonNullableKeys, Nullable } from "models";
import { RootState, ScooterSetupState } from "store";
import { useAuth0 } from "Auth";
import { pushDataLayerEvent } from "utils";
import { getThirdSentence } from "./general-conditions.utils";

const GeneralConditions: React.FC = memo(() => {
  useGTM(7);

  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth0();

  const { subscriptionId, selectedPlan } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const [acceptGeneralConditions, setAcceptGeneralConditions] = useState<Nullable<number>>(null);
  const [agreeToReceiveCommercial, setAgreeToReceiveCommercial] = useState<Nullable<number>>(null);
  const [validateGeneralDataProtection, setValidateGeneralDataProtection] =
    useState<Nullable<number>>(null);
  const [smsTimestamp, setSmsTimestamp] = useState<Nullable<number>>(null);

  const { payload, loading } = useFetch(getIP);

  const { submit, submitting, onCallSuccess } = useCall(acceptAgreement);
  onCallSuccess(() => history.push(AUTHORIZATION_PAGE.path), []);

  useDidUpdate(
    () => {
      if (!user.email) return;

      pushDataLayerEvent(
        {
          event: "generationContrat",
          clientEmail: user.email,
        },
        true,
      );
    },
    [user.email],
    true,
  );

  const nextButtonHandler = () => {
    submit(subscriptionId, {
      userAgent: navigator.userAgent,
      ipAddress: payload?.ip || "192.168.0.1",
      generalConditionsTimestamp: acceptGeneralConditions,
      gdrpTimestamp: validateGeneralDataProtection,
      commercialInformationTimestamp: agreeToReceiveCommercial,
      smsTimestamp,
    });
  };

  if (!subscriptionId) {
    history.push(DEFAULT_PAGE.path);
    return null;
  }

  return (
    <div className="common-wrap">
      <div className="content subscribtions-terms-wrap">
        <h3 className="header">{t("subscription.validation-of-conditions.location")}</h3>
        <div className="divider" />
        <span>
          <span>*</span>
          {t("subscription.personal-information")}
        </span>
        <Checkbox
          checked={!!acceptGeneralConditions}
          onChange={(e: any) => setAcceptGeneralConditions(e.target.checked ? Date.now : null)}
        >
          <span className="star">*</span>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`subscription.validation-of-conditions.read-through.${selectedPlan.id}`),
            }}
          />
        </Checkbox>
        <Checkbox
          checked={!!agreeToReceiveCommercial}
          onChange={(e: any) => setAgreeToReceiveCommercial(e.target.checked ? Date.now : null)}
        >
          <span className={"star"}>*</span>
          <span
            dangerouslySetInnerHTML={{
              __html: t("subscription.validation-of-conditions.gdpr-new"),
            }}
          />
        </Checkbox>
        <Checkbox
          checked={!!validateGeneralDataProtection}
          onChange={(e: any) =>
            setValidateGeneralDataProtection(e.target.checked ? Date.now : null)
          }
        >
          <span className="star">*</span>
          {t(getThirdSentence(selectedPlan.id))}
        </Checkbox>
        <Checkbox
          checked={!!smsTimestamp}
          onChange={(e: any) => setSmsTimestamp(e.target.checked ? Date.now : null)}
        >
          {t("subscription.validation-of-conditions.markerting-information")}
        </Checkbox>
      </div>
      <Footer
        nextButtonId="subscription-terms"
        nextButtonHandler={nextButtonHandler}
        isNextButtonDisabled={
          !(validateGeneralDataProtection && agreeToReceiveCommercial && acceptGeneralConditions)
        }
        isLoading={loading || submitting}
      />
    </div>
  );
});

export default GeneralConditions;
