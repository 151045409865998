import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cn from "classnames";
import { CircularProgress } from "@material-ui/core";

import { getUserInvoice, getUserInvoices } from "api";
import { setLanguage, isEmpty } from "utils";
import { useFetch, useDidUpdate } from "hooks";
import { MenuHoc } from "./MenuHoc.js";

import CheckmarkImage from "assets/images/account/checkmark.svg";
import SwapImage from "assets/images/account/swap.svg";
import FileImage from "assets/images/uploader/file.svg";

require("moment/locale/fr.js");
require("moment/locale/es.js");

const Bills = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const subscriptions = useSelector((state) => {
    return state.account.customer.subscriptions.filter(
      (sub) => sub.status?.toLowerCase?.() !== "future",
    );
  });

  const { payload, loading } = useFetch(
    getUserInvoices
  );

  const handleSubscriptionClick = (subscription) => () => {
    if (subscription.externalId === selectedSubscription?.externalId) {
      return setIsOpen((prevState) => !prevState);
    }

    setSelectedSubscription(subscription);
    setIsOpen(false);
  };

  const handleStatusShow = (status) => {
    status = status?.toLowerCase?.();
    const isActive = status === "active";
    const isInActive = status === "cancelled";
    const text = isActive ? "Actif" : isInActive ? "Inactif" : status;

    return (
      <span
        className={cn(
          "bills-subscription-item-status",
          { active: isActive },
          { inActive: isInActive },
        )}
      >
        {text}
      </span>
    );
  };

  useDidUpdate(
    () => {
      const mainSubscription = subscriptions.find(subscription => subscription.main)

      setSelectedSubscription(mainSubscription || subscriptions[0]);
    },
    [subscriptions.length],
    true,
  );

  const invoicesList = payload?.invoices.filter(invoice => invoice.subscriptionId === selectedSubscription?.externalId) || [];
  const isEmptyList = isEmpty(invoicesList);

  const onDocumentClick = async (id) => {
    const [response] = await getUserInvoice(id);

    if (response?.downloadUrl) window.open(response.downloadUrl, "_blank")
  }

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("my-account.menu.bills")}</h3>
        <div className="divider" />

        <div className="bills-subscription-title">{t("my-account.bills.empty")}</div>

        {!!subscriptions.length && (
          <div className="bills-subscription-items-wrapper">
            {selectedSubscription && (
              <div
                className="bills-subscription-item sticky"
                onClick={handleSubscriptionClick(selectedSubscription)}
              >
                <span className="bills-subscription-item-text">
                  Subscription n° {selectedSubscription.externalId}
                </span>
                <div className={cn("bills-subscription-item-right", { open: isOpen })}>
                  {handleStatusShow(selectedSubscription.status)}
                  <img src={CheckmarkImage} alt="" />
                </div>
              </div>
            )}

            {isOpen &&
              subscriptions?.map((subscription) => {
                const { externalId, status } = subscription;
                if (externalId === selectedSubscription.externalId) return null;

                return (
                  <div
                    key={externalId}
                    className="bills-subscription-item"
                    onClick={handleSubscriptionClick(subscription)}
                  >
                    <span className="bills-subscription-item-text">
                      Subscription n° {externalId}
                    </span>
                    <div className="bills-subscription-item-right">
                      {handleStatusShow(status)}
                      <img src={SwapImage} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {loading && (
          <div style={{ display: "flex", marginTop: "40px", justifyContent: "center" }}>
            <CircularProgress size={59.5} />
          </div>
        )}

        {/*{!loading && isEmptyList && <p className="bills-empty">{t("my-account.bills.empty")}</p>}*/}

        {!loading && !isEmptyList && (
          <ul className="bills-wrap">
            {invoicesList.map((invoice, index) => {
              const { date, amountDue, id } = invoice;

              const dateToShow = moment(date).locale(setLanguage()).format("MMMM YYYY");

              return (
                <li key={index}>
                  <h6>
                    {t("my-account.bills.invoice-date")}{" "}
                    {dateToShow.charAt(0).toUpperCase() + dateToShow.slice(1)}
                  </h6>
                  <div>
                    <span>{amountDue}€</span>
                    <img
                      className="file-image"
                      src={FileImage}
                      onClick={() => onDocumentClick(id)}
                      alt=""
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MenuHoc(Bills);
