import React, {memo, useMemo} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import { getUserContractData } from "api";
import { useFetch } from "hooks";
import { MenuHoc } from "./MenuHoc.js";

const Contract = memo(() => {
  const { t } = useTranslation();

  const { subscriptions } = useSelector((state) => state.account.customer);
  const hasSubscriptions = subscriptions && subscriptions.length;
  const mainSubscription = subscriptions.find(subscription => subscription.main)

  const apiCall = () => getUserContractData(mainSubscription.externalId);
  const { payload, loading } = useFetch(apiCall, [hasSubscriptions], !hasSubscriptions);

  const { status, active, contractNumber, subscriptionDate, licensePlate, scooter_vin } = payload || {};

  const subscriptionDateText = useMemo(() => {
    if (!subscriptionDate) return ''
    const isCorrectDate = moment(subscriptionDate).isValid()

    if (isCorrectDate) {
      return moment(subscriptionDate, "DD-MM-YYYY").format("DD/MM/YYYY");
    }

    const countryCode = localStorage.getItem("countryCode");

    switch (countryCode) {
      case 'fr':
        return 'Votre abonnement n\'a pas commencé';
     case 'es':
        return 'Tu suscripción no ha empezado';
     default:
        return subscriptionDate
    }
  }, [subscriptionDate])

  if (loading) {
    return (
      <div className="common-wrap common-account">
        <div className="content" style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={59.5} />
        </div>
      </div>
    );
  }

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("subscription.confirmation-page.my-contract")}</h3>
        <div className="divider" />
        <div className="personal-wrap">
          {status && (
            <div>
              <h6>{t("my-account.contract.status")}</h6>
              <p>{active ? t("my-account.contract.active") : t("my-account.contract.inactive")}</p>
            </div>
          )}
          {contractNumber && (
            <div>
              <h6>{t("my-account.contract.contract-number")}</h6>
              <p>{contractNumber}</p>
            </div>
          )}
          {subscriptionDate && (
            <div>
              <h6>{t("my-account.contract.registration-date")}</h6>
              <p>{subscriptionDateText}</p>
            </div>
          )}
          {licensePlate && (
            <div>
              <h6>{t("my-account.contract.plate-number")}</h6>
              <p>{licensePlate}</p>
            </div>
          )}
          {scooter_vin && (
            <div>
              <h6>{t("my-account.contract.vin-number")}</h6>
              <p>{scooter_vin}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default MenuHoc(Contract);
