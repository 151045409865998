import { useCookies, useQuery, useDidMount } from "hooks";

const utmRequiredCookiesArr = ["utm_source", "utm_medium", "utm_campaign"];
const utmOptionalCookiesArr = ["utm_term", "utm_content"];

type UTMQueries = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

const useSetUTMCookies = (): void => {
  const { setCookie } = useCookies();

  const { query } = useQuery<UTMQueries>();

  useDidMount(() => {
    const queryKeys = Object.entries(query);

    const hasRequiredFields = utmRequiredCookiesArr.every((cookie) => {
      return !!queryKeys.find(([key, value]) => value && cookie === key);
    });
    if (!hasRequiredFields) return;

    const utmCookies = queryKeys.filter(
      ([key]) => utmRequiredCookiesArr.indexOf(key) >= 0 || utmOptionalCookiesArr.indexOf(key) >= 0,
    );

    let cookieValue = "";

    utmCookies.forEach(([key, value], index, self) => {
      const comma = self.length - 1 > index ? "," : "";
      cookieValue += `"${key.slice(4)}":"${value}"` + comma;
    });

    cookieValue = `{${cookieValue}}`;

    const isLocalHost = location.hostname.includes("localhost");

    if (isLocalHost) {
      setCookie("utm", cookieValue, {
        path: "/",
        maxAge: 7.776e6,
      });
      return;
    }

    setCookie("utm", cookieValue, {
      path: "/",
      domain: ".zeway.com",
      maxAge: 7.776e6,
    });
  });
};

export default useSetUTMCookies;
