import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BuyerTypesKeys,
  PlanModel,
  CouponModel,
  Nullable,
  AddonModel,
  ContributionType,
} from "models";

export type ScooterSetupState = {
  setup: {
    cf_buyer_profile: BuyerTypesKeys | "";
    cf_power: string;
    cf_model: string;
    cf_color: string;
    cf_contribution: ContributionType;
    cf_contribution_type: "bonus" | "contribution" | null;
  };
  selectedPlan: Nullable<PlanModel>;
  subscriptionId: Nullable<string>;
  paymentDetails: {
    selectedCouponsIds: string[];
    selectedCoupons: CouponModel[];
    firstPayment: number;
    monthlyPaymentWithoutTaxes: number;
    monthlyPayment: number;
    monthlyPaymentWithoutTaxes_4_5: number;
    monthlyPayment_4_5: number;
    discountedSubscription: number;
    subsidySelected: boolean;
    insurance: {
      birthdateUnder25: boolean;
      driverUnder25: boolean;
      insBasePrice: number;
      ins25Price: number;
      cf_insurance_overcost: number;
    };
    cautionAddon: Nullable<AddonModel>;
    selectedAddons: Record<string, { price: number; count: number; priceHT?: number }>;
  };
  personalInfo: {
    dob: string;
    driverDob: string;
  } & Record<string, any>;
};

const initialState: ScooterSetupState = {
  setup: {
    cf_buyer_profile: "",
    cf_power: "",
    cf_model: "",
    cf_color: "",
    cf_contribution: {},
    cf_contribution_type: null,
  },
  selectedPlan: null,
  subscriptionId: null,
  paymentDetails: {
    selectedCouponsIds: [],
    selectedCoupons: [],
    firstPayment: 414,
    monthlyPaymentWithoutTaxes: 0,
    monthlyPayment: 0,
    monthlyPaymentWithoutTaxes_4_5: 0,
    monthlyPayment_4_5: 0,
    discountedSubscription: 0,
    subsidySelected: false,
    insurance: {
      birthdateUnder25: false,
      driverUnder25: false,
      insBasePrice: 0,
      ins25Price: 0,
      cf_insurance_overcost: 0,
    },
    cautionAddon: null,
    selectedAddons: {},
  },
  personalInfo: {
    dob: "",
    driverDob: "",
  },
};

const scooterSetup = createSlice({
  name: "scooter-setup",
  initialState,
  reducers: {
    setScooterSetupReduxState: (state, { payload }: PayloadAction<ScooterSetupState>) => {
      return { ...state, ...payload };
    },
    // SCOOTER SETUP ↓
    setScooterBuyerType: (
      state,
      { payload }: PayloadAction<ScooterSetupState["setup"]["cf_buyer_profile"]>,
    ) => {
      state.setup.cf_buyer_profile = payload;
    },
    setScooterPower: (
      state,
      { payload }: PayloadAction<ScooterSetupState["setup"]["cf_power"]>,
    ) => {
      state.setup.cf_power = payload;
    },
    setScooterModel: (
      state,
      { payload }: PayloadAction<ScooterSetupState["setup"]["cf_model"]>,
    ) => {
      state.setup.cf_model = payload;
    },
    setScooterColor: (
      state,
      { payload }: PayloadAction<ScooterSetupState["setup"]["cf_color"]>,
    ) => {
      state.setup.cf_color = payload;
    },
    setScooterSetup: (state, { payload }: PayloadAction<Partial<ScooterSetupState["setup"]>>) => {
      state.setup = { ...state.setup, ...payload };
    },
    // SCOOTER SETUP ↑
    setSelectedPlan: (state, { payload }: PayloadAction<PlanModel>) => {
      state.selectedPlan = payload;
    },
    setSubscriptionId: (
      state,
      { payload }: PayloadAction<Partial<ScooterSetupState["subscriptionId"]>>,
    ) => {
      state.subscriptionId = payload;
    },
    // PAYMENT DETAILS ↓
    clearPaymentDetails: (state) => {
      state.paymentDetails = initialState.paymentDetails;
    },
    setCouponsIds: (
      state,
      { payload }: PayloadAction<ScooterSetupState["paymentDetails"]["selectedCouponsIds"]>,
    ) => {
      state.paymentDetails.selectedCouponsIds = payload;
    },
    setCoupons: (state, { payload }: PayloadAction<CouponModel[]>) => {
      state.paymentDetails.selectedCoupons = payload;
    },
    setPaymentDetails: (
      state,
      { payload }: PayloadAction<Partial<ScooterSetupState["paymentDetails"]>>,
    ) => {
      state.paymentDetails = { ...state.paymentDetails, ...payload };
    },
    setInsurance: (
      state,
      { payload }: PayloadAction<Partial<ScooterSetupState["paymentDetails"]["insurance"]>>,
    ) => {
      state.paymentDetails.insurance = { ...state.paymentDetails.insurance, ...payload };
    },
    setSelectedAddons: (
      state,
      { payload }: PayloadAction<ScooterSetupState["paymentDetails"]["selectedAddons"]>,
    ) => {
      const addonsToSet: ScooterSetupState["paymentDetails"]["selectedAddons"] = {};

      const objToTest = { ...state.paymentDetails.selectedAddons, ...payload };

      Object.entries(objToTest).forEach(([id, value]) => {
        if (value.count > 0) {
          addonsToSet[id] = value;
        }
      });

      state.paymentDetails.selectedAddons = addonsToSet;
    },
    // PAYMENT DETAILS ↑
    setPersonalInfo: (state, { payload }: PayloadAction<ScooterSetupState["personalInfo"]>) => {
      state.personalInfo = payload;
    },
  },
});

export const {
  setScooterSetupReduxState,
  // SCOOTER SETUP ↓
  setScooterBuyerType,
  setScooterPower,
  setScooterModel,
  setScooterColor,
  setScooterSetup,
  // SCOOTER SETUP ↑
  setSelectedPlan,
  setSubscriptionId,
  // PAYMENT DETAILS ↓
  clearPaymentDetails,
  setCouponsIds,
  setCoupons,
  setPaymentDetails,
  setInsurance,
  setSelectedAddons,
  // PAYMENT DETAILS ↑
  setPersonalInfo,
} = scooterSetup.actions;

export default scooterSetup.reducer;
