import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import scooterData from "./reducers/scooter-data";
import scooterSetup from "./reducers/scooter-setup";
import user from "./reducers/user";
import account from "./reducers/account";
import rootSaga from "./sagas/root-saga";

const reducer = combineReducers({
  scooterData,
  scooterSetup,
  user,
  account,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export * from "./reducers/scooter-setup";
export * from "./reducers/scooter-data";

export type RootState = ReturnType<typeof reducer>;
export default store;
