import React from "react";
import PropTypes from "prop-types";

export const Notification = ({
  text,
  t,
  isShowForInformation = true,
  dangerouslySetInnerHTML = false,
}) => {
  return (
    <div className="notification-wrap">
      {isShowForInformation && (
        <>
          <span className="for-info">{t("subscription.subscription-choice.information")}</span>
          &nbsp;
        </>
      )}
      {dangerouslySetInnerHTML ? (
        <span
          className={"test"}
          dangerouslySetInnerHTML={{
            __html: t(text),
          }}
        />
      ) : (
        <>{t(text)}</>
      )}
    </div>
  );
};

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isShowForInformation: PropTypes.bool,
};
