import fetchMiddleware from "api/api.middleware";
import { GeneratedContractModel, UserContractModel } from "models";

export const generateContract = (subscriptionId: string) =>
  fetchMiddleware<GeneratedContractModel>({
    method: "put",
    url: `/subscriptions/${subscriptionId}/send-contract`,
  });

export const getUserContractData = (subscriptionId: string) =>
  fetchMiddleware<UserContractModel>({
    method: "get",
    url: `/subscriptions/${subscriptionId}/contract-details`,
  });
