import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { SelectButton } from "components";
import { Notification } from "components/Notification/Notification";
import { Footer } from "components/Footer";
import { useGTM, useQuery, useDidMount } from "hooks";
import { BuyerTypesKeys } from "models";
import { uniqBy } from "utils";
import { RootState, setScooterSetup, setScooterPower } from "store";
import { mapNotificationTextToScooterPower, mapPower } from "../scooter-setup.constants";

type QueryType = {
  cf_buyer_profile: BuyerTypesKeys | "";
  cf_power?: string;
};

const ScooterPower: React.FC = memo(() => {
  useGTM(2);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { query, setQueryParam, updateQueryParams } = useQuery<QueryType>();

  const plans = useSelector((state: RootState) => state.scooterData.plans);

  const availablePowerArr: string[] = useMemo(() => {
    const filteredByBuyer = plans.filter((el) => el.cf_buyer_profile === query.cf_buyer_profile);

    const uniq = uniqBy(filteredByBuyer, "cf_power");

    return uniq.map(({ cf_power }) => cf_power);
  }, [query.cf_buyer_profile]);

  const powerType = availablePowerArr.find((type) => query.cf_power === type);

  const blockClickHandler = (data: string) => () => {
    const dataToSet = powerType === data ? "" : data;
    setQueryParam("cf_power", dataToSet);
  };

  const nextButtonOnClick = (power = "") => {
    const powerToSet = power || powerType;
    powerToSet && dispatch(setScooterPower(powerToSet));
  };

  const handlePrevButton = () => {
    updateQueryParams({
      cf_buyer_profile: "",
      cf_power: "",
    });

    dispatch(
      setScooterSetup({
        cf_buyer_profile: "",
        cf_power: "",
      }),
    );
  };

  useDidMount(() => {
    const length = availablePowerArr.length;
    if (!length) return handlePrevButton();

    if (powerType) {
      setQueryParam("cf_power", powerType);
      return nextButtonOnClick(powerType);
    }

    const power = availablePowerArr[0];
    setQueryParam("cf_power", power);

    if (length === 1) nextButtonOnClick(power);
  });

  if (!availablePowerArr.length || availablePowerArr.length === 1) return null;

  return (
    <>
      <div className="common-wrap">
        <div className="content">
          <h3 className="header">{t("subscription.power-choice.question")}</h3>
          <div className="divider" />

          <div
            className="user-types-wrap scooter-power-wrap"
            style={{
              maxWidth: availablePowerArr.length > 2 ? "unset" : "546px",
              justifyContent: availablePowerArr.length === 1 ? "center" : "space-between",
            }}
          >
            {availablePowerArr.map((power) => (
              <SelectButton
                key={power}
                className={cn("user-type", "scooter-type", { active: powerType === power })}
                selected={power === powerType}
                onClick={blockClickHandler(power)}
              >
                <span>{t("subscription.power-choice.equivalent")}</span>
                {t(mapPower[power])}
              </SelectButton>
            ))}
          </div>
          {powerType && mapNotificationTextToScooterPower[powerType] && (
            <Notification t={t} text={mapNotificationTextToScooterPower[powerType]} />
          )}
        </div>
      </div>
      <Footer
        nextButtonId="scooter-power-select"
        nextButtonHandler={nextButtonOnClick}
        prevButtonHandler={handlePrevButton}
        isNextButtonDisabled={!powerType}
      />
    </>
  );
});

export default ScooterPower;
