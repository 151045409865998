import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import countriesMultilang from "i18n-iso-countries";

import { useAuth0 } from "Auth";
import { RootState, ScooterSetupState } from "store";
import { NonNullableKeys } from "models";
import { DEFAULT_PAGE, PERSONAL_INFO_PAGE } from "constants/routes.constants";
import { useDidMount, useDidUpdate, useGTM, useGTMEmail } from "hooks";
import { isEmpty } from "utils";
import ParticularPersonalInfoForm from "./components/ParticularPersonalInfoForm";
import ProfessionalPersonalInfoForm from "./components/ProfessionalPersonalInfoForm";

countriesMultilang.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const PersonalInfo: React.FC = memo(() => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  useGTM(6);
  useGTMEmail(user?.email);

  const history = useHistory();

  const { setup, selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;
  const { cf_buyer_profile } = setup;

  const [checkedStorage, setCheckedStorage] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useDidMount(() => {
    setCheckedStorage(true);

    if (isAuthenticated) return;

    localStorage.setItem("setup", JSON.stringify(setup));
    localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
    localStorage.setItem("paymentDetails", JSON.stringify(paymentDetails));

    loginWithRedirect({
      screen_hint: "signup",
      redirect_uri: window.location.origin + PERSONAL_INFO_PAGE.path,
    });
  });

  useDidUpdate(() => {
    setIsMounted(true);

    if (isEmpty(selectedPlan) || isEmpty(cf_buyer_profile)) {
      return history.push(DEFAULT_PAGE.path);
    }
  }, [checkedStorage]);

  if (!isMounted || !isAuthenticated) return null;

  const Component =
    cf_buyer_profile === "particular" ? ParticularPersonalInfoForm : ProfessionalPersonalInfoForm;

  return <Component />;
});

export default PersonalInfo;
