import React, { memo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import IBAN from "iban";

import { putPaymentToAccount } from "api";
import { useCall, useDidMount, useDidUpdate, useGTM } from "hooks";
import { NonNullableKeys } from "models";
import { RootState, ScooterSetupState } from "store";
import { DEFAULT_PAGE, DOCUMENTS_PAGE } from "constants/routes.constants";
import { Notification } from "components/Notification/Notification";
import { Footer } from "components/Footer";
import { setInputCaretPosition } from "utils/inputs";
import Input from "./components/input";

const validationSchema = Yup.object({
  iban: Yup.string()
    .test("iban-validation", "Field doesn't pass validation", (value) => {
      return IBAN.isValid(value);
    })
    .required("iban is required"),
  bic: Yup.string()
    .matches(/^[a-z]{6}[2-9a-z][0-9a-np-z]([a-z0-9]{3}|x{3})?$/i)
    .required("bic is required"),
});

const Payment: React.FC = memo(() => {
  useGTM(10);

  const { push } = useHistory();
  const { t } = useTranslation();

  const { submit, submitting, onCallSuccess } = useCall(putPaymentToAccount);

  onCallSuccess(() => push(DOCUMENTS_PAGE.path), []);

  const { subscriptionId } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  // Iban caret positioning.
  const [ibanRange, setIbanRange] = useState({
    start: 0,
    end: 0,
  });
  const ibanRef = useRef();

  const formik = useFormik({
    initialValues: {
      iban: "",
      bic: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const { iban, bic } = values;

      // DE89 3704 0044 0532 0130 00
      // DABAIE2D
      submit(subscriptionId, {
        iban: iban.replace(/\s/g, ""),
        bic,
      });
    },
  });

  const { iban } = formik.values;

  useDidMount(() => !subscriptionId && push(DEFAULT_PAGE.path));

  useDidUpdate(() => {
    const { start, end } = ibanRange;

    setInputCaretPosition(ibanRef, start, end);
  }, [iban]);

  // Iban validation
  function ibanOnKeyDown(e: any) {
    const { selectionStart, selectionEnd } = e.target;
    if (
      e.key === "Backspace" &&
      selectionStart &&
      selectionStart % 5 === 0 &&
      selectionEnd &&
      selectionEnd % 5 === 0
    ) {
      setInputCaretPosition(ibanRef, selectionStart - 1, selectionEnd - 1);
    }
  }
  function validateIBAN() {
    if (formik.values["iban"]) {
      formik.setFieldTouched("iban", true, false);
      return;
    }
    formik.setFieldTouched("iban", false, false);
    // const value = formik.values['iban'];
    //
    // // If value is empty -> clear error and return.
    // if (!value) {
    //     formik.setFieldError('iban', '')
    //     return;
    // }
    //
    // // If value is invalid -> set error and return.
    // if (!IBAN.isValid(value)) {
    //     formik.setFieldError('iban', 'Field doesn\'t pass validation')
    //     return;
    // }
    //
    // // Everything is ok -> clear error.
    // formik.setFieldError('iban', '')
  }
  function handleIbanChange(name: any, value: any, e: any) {
    // Setting new value.
    formik.setFieldValue(name, value, true);

    if (!e || !e.target) return;

    const vLength = value.length;
    const { selectionStart, selectionEnd } = e.target;

    // Check if caret in correct position.
    const isSelectionInCorrectPos = selectionStart % 5 === 0 && selectionEnd % 5 === 0;

    // Get value length without spaces.
    const parsedLength = value.replace(/\s+/g, "").length;

    // Сalculate whether it is necessary to add an offset or not.
    const offset =
      iban.length < vLength &&
      isSelectionInCorrectPos &&
      parsedLength > 4 &&
      (parsedLength - 1) % 4 === 0
        ? 1
        : 0;

    const start = selectionStart + offset;
    const end = selectionEnd + offset;

    setIbanRange((prevState) => ({
      ...prevState,
      start,
      end,
    }));
  }

  // Bic validation
  const validateBIC = () => {
    if (formik.values["bic"]) {
      formik.setFieldTouched("bic", true, false);
      return;
    }
    formik.setFieldTouched("bic", false, false);
    // const value = formik.values['bic'];
    //
    // // If value is empty -> clear error and return.
    // if (!value) {
    //     formik.setFieldError('bic', '')
    //     return;
    // }
    //
    // // If value is invalid -> set error and return.
    // if (!mapRegex.bic.test(value)) {
    //     formik.setFieldError('bic', 'Field doesn\'t pass validation')
    //     return;
    // }
    //
    // // Everything is ok -> clear error.
    // formik.setFieldError('bic', '')
  };

  const skipStepHandler = () => push(DOCUMENTS_PAGE.path);

  return (
    <div className="common-wrap">
      <div className="content payment-wrap">
        <div className="header">{t("subscription.header.my-payment-method")}</div>
        <div className="divider" />
        <Notification t={t} text="subscription.cart.levy" />

        <h6 className="bank-details">{t("subscription.payment-information.bank-details")}</h6>

        <form className="payment-inputs-wrap" onSubmit={formik.handleSubmit}>
          <Input
            formik={formik}
            name="iban"
            // label={t("subscription.payment-information.iban")}
            error={(formik.touched.iban && Boolean(formik.errors.iban)) || false}
            inputRef={ibanRef}
            onKeyDownMethod={ibanOnKeyDown}
            onChangeMethod={handleIbanChange}
            onBlurMethod={validateIBAN}
          />

          <Input
            formik={formik}
            name="bic"
            // label={t("subscription.payment-information.bic")}
            error={(formik.touched.bic && Boolean(formik.errors.bic)) || false}
            onChangeMethod={(name: any, value: any) => {
              formik.setFieldValue(name, value, true);
            }}
            onBlurMethod={validateBIC}
          />
          <Footer
            nextButtonId="payment-method"
            type="submit"
            skipStepHandler={skipStepHandler}
            isLoading={submitting}
          />
        </form>
      </div>
    </div>
  );
});

export default Payment;
