import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import { Trans, useTranslation } from "react-i18next";

import { SelectButton } from "components";
import { Footer } from "components/Footer";
import { useWindowSize, useDidMount, useDidUpdate } from "hooks";
import {
  RootState,
  ScooterSetupState,
  clearPaymentDetails,
  setPaymentDetails,
  setInsurance,
  setSelectedPlan,
  setSelectedAddons,
} from "store";
import { NonNullableKeys } from "models";
import { SCOOTER_SETUP_PAGE, PERSONAL_INFO_PAGE } from "constants/routes.constants";
import { checkForIntegerToFixed } from "utils/prices";
import { Insurance } from "./Insurance";
import { Addons } from "./Addons";
import { SecurityDeposit } from "./SecurityDeposit";
import { PromoCode } from "./PromoCode";
import SecondDriverAddon from "./SecondDriverAddon";
import {
  mapPower,
  inclusiveList,
  offersList,
  offersPriceList,
} from "../scooter-subscription-setup.constants";
import { ProfessionalSubscriptionDetailsContent } from "./ProfessionalSubscriptionDetailsContent";

import LogoLight from "assets/images/logo-light.svg";

const ProfessionalSubscriptionDetails: React.FC = memo(() => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { plans, addons } = useSelector((state: RootState) => state.scooterData);
  const { setup, selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;
  const { cf_buyer_profile, cf_model, cf_power, cf_color, cf_contribution } = setup;

  const [isOpen, setIsModalOpen] = useState(false);

  const { id, cf_caution_addon_id, cf_insurance_base_addon_id, cf_insurance_25_addon_id } =
    selectedPlan;

  const {
    firstPayment,
    monthlyPaymentWithoutTaxes,
    monthlyPayment,
    monthlyPaymentWithoutTaxes_4_5,
    monthlyPayment_4_5,
    insurance,
    selectedAddons,
  } = paymentDetails;

  const { insBasePrice, ins25Price, cf_insurance_overcost } = insurance;

  const [driverUnder25, setDriverUnder25] = useState(!!state?.cf_buyer_under_25 || false);

  const [subsidySelected, setSubsidySelected] = useState(
    typeof state?.subsidy === undefined ? true : !!state?.subsidy,
  );

  const { width } = useWindowSize();

  // First addons data initialization.
  useDidMount(() => {
    if (!addons.length) return;

    // Professional insurance addons to save in store.
    const insBaseAddon = addons.find((addon) => addon.id === cf_insurance_base_addon_id);
    const ins25Addon = addons.find((addon) => addon.id === cf_insurance_25_addon_id);

    if (insBaseAddon) {
      dispatch(
        setInsurance({
          insBasePrice: insBaseAddon.price / 100,
          ins25Price: (ins25Addon?.price || 0) / 100,
          cf_insurance_overcost: (Number(ins25Addon?.cf_insurance_overcost) || 0) / 100,
        }),
      );
    }

    // Caution Addon
    const cautionAddon = addons.find((addon) => addon.id === cf_caution_addon_id);
    // Fix caution addon to not add more than once.
    const shouldAddCaution = !selectedAddons[cf_caution_addon_id];

    if (!shouldAddCaution || !cautionAddon) return;

    dispatch(setSelectedAddons({ [cautionAddon.id]: { count: 1, price: cautionAddon.price } }));

    dispatch(setPaymentDetails({ cautionAddon }));
  });

  // Payment handle.
  useDidUpdate(
    () => {
      const { cf_price_year_4_5 = 0, cf_price_year_4_5_ht = 0 } = selectedPlan;

      const marks = Object.keys(cf_contribution).map((price) => ({
        value: +price,
        label: `${price}€`,
      }));
      const mark = marks.find(({ value }) => Number(value) === Number(firstPayment ?? 0));
      if (!mark) return;

      const firstPaymentToSet = mark.value;
      const monthlyPaymentWithoutTaxes = cf_contribution[mark.value].price_HT / 100;
      const monthlyPayment = cf_contribution[mark.value].price_TTC / 100;
      const monthlyPaymentWithoutTaxes_4_5 = +cf_price_year_4_5_ht / 100;
      const monthlyPayment_4_5 = +cf_price_year_4_5 / 100;

      dispatch(
        setPaymentDetails({
          firstPayment: firstPaymentToSet,
          monthlyPaymentWithoutTaxes,
          monthlyPayment,
          monthlyPaymentWithoutTaxes_4_5,
          monthlyPayment_4_5,
        }),
      );
    },
    [selectedPlan, cf_contribution],
    true,
  );

  // Subsidy plan and addon handle.
  useDidUpdate(
    () => {
      if (!(id === "SWAPPER_PRO_IDF" || id === "SWAPPER_PRO")) return;

      const plansArr = plans.filter((el) => el.cf_buyer_profile === cf_buyer_profile);

      const planToCheck = subsidySelected ? "SWAPPER_PRO_IDF" : "SWAPPER_PRO";

      const foundPlan = plansArr.find((plan) => plan.id === planToCheck);
      const foundSubsidyAddon = addons.find((addon) => addon.id === "FEE_IDF");

      if (!foundPlan || !foundSubsidyAddon) return;

      dispatch(setSelectedPlan(foundPlan));
      dispatch(setPaymentDetails({ subsidySelected }));
      dispatch(
        setSelectedAddons({
          ["FEE_IDF"]: {
            price: foundSubsidyAddon.price,
            count: subsidySelected ? 1 : 0,
          },
        }),
      );
    },
    [subsidySelected],
    true,
  );

  // Insurance addons handle.
  useDidUpdate(
    () => {
      if (!addons.length) return;

      const addonToCheck = driverUnder25 ? cf_insurance_25_addon_id : cf_insurance_base_addon_id;
      const oppositeAddonToCheck = driverUnder25
        ? cf_insurance_base_addon_id
        : cf_insurance_25_addon_id;

      const foundAddon = addons.find((addon) => addon.id === addonToCheck);

      if (!foundAddon) return;

      const { id, price } = foundAddon;

      dispatch(
        setSelectedAddons({
          [id]: { count: 1, price },
          [oppositeAddonToCheck]: { count: 0, price },
        }),
      );

      dispatch(setInsurance({ driverUnder25 }));
    },
    [driverUnder25, addons.length],
    true,
  );

  useDidMount(() => {
    if (!state) return;
    history.push(PERSONAL_INFO_PAGE.path);
  });

  const handlePreviousButtonClick = () => {
    dispatch(clearPaymentDetails());
    history.push(SCOOTER_SETUP_PAGE.path);
  };

  const handleSubmit = () => history.push(PERSONAL_INFO_PAGE.path);

  return (
    <div className="common-wrap">
      <div className="subscribtions-details-wrap">
        <div className="scooter-info">
          <h6>{t("subscription.color-choice.scooter")}</h6>
          <div>
            {t(`subscription.scooter-choice.${cf_model}`)}{" "}
            <span className="semilight">
              — {t("subscription.power-choice.equivalent")} {t(mapPower[cf_power])} -{" "}
              {t("subsctription.subscription-choice-color")}{" "}
              {t(`subscription.color-choice.${cf_color}`)}
            </span>
          </div>
        </div>
        <div className="scooter-title">
          <h2>
            <Trans i18nKey="subscription.subscription-choice">
              votre abonnement à partir de{" "}
              {{ price: checkForIntegerToFixed(monthlyPaymentWithoutTaxes + insBasePrice) }} € TTC*
            </Trans>
            <span>
              &nbsp;
              {t("subscription.subscription-choice.ht-month")}
              &nbsp;
            </span>
          </h2>
        </div>
        <div className="divider" />
        <div className="lists-wrap">
          <div className="inclusives-wrap">
            <h5>{t("subscription.subscription-choice.total")}</h5>
            <ul>
              {inclusiveList.map((item, index) => (
                <li key={index}>
                  <div>{t(item)} </div>
                  {inclusiveList.length === index + 1 && <img src={LogoLight} alt="" />}
                </li>
              ))}
            </ul>
          </div>
          <div className="offer-wrap">
            <h5>{t("subscription.subscription-choice.inclusion")}</h5>
            <ul>
              {offersList.map((item, index) => (
                <li key={index}>
                  <div className="offer">{t(item)}</div>
                  <div>{t(offersPriceList[item])}</div>
                </li>
              ))}
            </ul>
            <div className="introductory-offer-container">
              <div className="price">
                <span className="crossed">300€</span> {t("subscription.subscription-choice.ttc")}
              </div>
              <div className="free-offer">
                <h4>{t("subscription.subscription-choice.free")}</h4>
                <h6>
                  {width > 768 ? <>-</> : null}{" "}
                  {t("subscription.subscription-choice.introductory-offer")}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <h2>{t("subscription.subscription-choice.choose-subscription-pro")}</h2>
        <div className="divider" />
        <div className="long-term-wrap">
          <div className="header">
            <h6>{t("subscription.subscription-choice.3-year-subscription-pro")}</h6>
            <div className="subheader">
              {t("subscription.subscription-choice.contribution-pro")}
            </div>
          </div>
          <div className="long-term-price-wrap">
            <div className="buttons-wrap">
              <SelectButton
                className="button"
                selected={subsidySelected}
                disabled={subsidySelected}
                onClick={() => setSubsidySelected(true)}
              >
                {t("subscription.subscription-choice.get-help-button-text")}
              </SelectButton>
              <SelectButton
                className="button"
                selected={!subsidySelected}
                disabled={!subsidySelected}
                onClick={() => setSubsidySelected(false)}
              >
                {t("subscription.subscription-choice.no-help-button-text")}
              </SelectButton>
            </div>
            <div className="price">
              {monthlyPaymentWithoutTaxes && (
                <>
                  {checkForIntegerToFixed(
                    monthlyPaymentWithoutTaxes + (driverUnder25 ? ins25Price : insBasePrice),
                  )}
                  €<span>{t("subscription.subscription-choice.ht-month")}</span>
                </>
              )}
              <div className="subprice">
                {monthlyPayment && (
                  <>
                    {checkForIntegerToFixed(
                      monthlyPayment + (driverUnder25 ? ins25Price : insBasePrice),
                    )}
                    €<span>{t("subscription.subscription-choice.ttc-month")}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="reduced-price">
            <h5>{t("subscription.subscription-choice.after-3-years")}</h5>
            <div>
              <p>{t("subscription.subscription-choice.benefit")}</p>
              <div className="price">
                {checkForIntegerToFixed(
                  monthlyPaymentWithoutTaxes_4_5 + (driverUnder25 ? ins25Price : insBasePrice),
                )}
                €<span>{t("subscription.subscription-choice.ht-month")}</span>
                <div className="subprice">
                  {checkForIntegerToFixed(
                    monthlyPayment_4_5 + (driverUnder25 ? ins25Price : insBasePrice),
                  )}
                  €<span>{t("subscription.subscription-choice.ttc-month")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SecondDriverAddon />
        <Insurance
          descriptionText={"subscription.subscription-choice.ins-option-pro-description"}
          detailsText={"subscription.subscription-choice.ins-option-pro-details"}
          priceText={`+${cf_insurance_overcost}€/mois`}
          checked={driverUnder25}
          onChecked={setDriverUnder25}
        />
        <Addons isPro />
        <h2>{t("subscription.subscription-choice.promo-codes")}</h2>
        <div className="divider" />
        <PromoCode />
        <h2>{t("subscription.subscription-choice.deposit")}</h2>
        <div className="divider" />
        <SecurityDeposit />
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsModalOpen(false)}
        PaperProps={{
          style: {
            margin: "17px",
          },
        }}
      >
        <ProfessionalSubscriptionDetailsContent
          handleClose={setIsModalOpen}
          handleSubmit={handleSubmit}
        />
      </Dialog>
      <Footer
        nextButtonId="professional-subscription-details"
        nextButtonHandler={() => setIsModalOpen(true)}
        prevButtonHandler={handlePreviousButtonClick}
      />
    </div>
  );
});

export default ProfessionalSubscriptionDetails;
