import axios from "axios";

import { appEnvironment } from "config/environment.config";
import {
  handleHeaders,
  handleCancelDuplicatedRequest,
  handlePrepareData,
  handleStatus,
  handleErrors,
  handleProgress,
  isCanceledRequest,
} from "./api.utils";

export interface ParamsProps {
  headers?: HeadersInit;
  url: string;
  method: "post" | "patch" | "put" | "delete" | "get";
  data?: any;
  isFormData?: boolean;
  canceling?: boolean;
  baseUrl?: string;
  onProgress?: (progress: number) => void;
}

export interface Status {
  isCanceled: boolean;
  status: number;
}

export interface ErrorResponse {
  message?: string;
  statusCode: number;
  errors: string[];
  formattedMessage: string;
}

export type FetchMiddlewareReturnType<T> = Promise<[T | null, ErrorResponse | null, Status]>;

export type FetchMiddlewareType = <T>(params: ParamsProps) => FetchMiddlewareReturnType<T>;

/**
 * Api middleware.
 * Used to get developer-friendly syntax and provide default parameters
 * to requests
 * @returns [payload, error, status]
 */
const fetchMiddleware: FetchMiddlewareType = async (params) => {
  return new Promise((resolve) => {
    const { apiUrl } = appEnvironment;

    const base = params.baseUrl || apiUrl;
    const isFormData = params.data instanceof FormData || Boolean(params.isFormData);
    const headers: HeadersInit = handleHeaders(params, isFormData);
    const cancelToken = handleCancelDuplicatedRequest(params);
    const url = base + params.url;
    const data = handlePrepareData(params.data, isFormData);
    const onUploadProgress = handleProgress(params.onProgress);

    return axios({
      ...params,
      url,
      data,
      headers,
      cancelToken,
      onUploadProgress,
    })
      .then(({ data, status }: any) => {
        return resolve([data, null, { status, isCanceled: false }]);
      })
      .catch((err: any) => {
        const status = handleStatus(err);
        const isCanceled = isCanceledRequest(err);
        const error = handleErrors(err.response?.data || err);

        return resolve([null, error, { status, isCanceled }]);
      });
  });
};

export default fetchMiddleware;
