export enum BuyerTypes {
  particular = "particular",
  professional = "professional",
  salesperson = "salesperson",
  transport = "transport",
  rider = "rider",
}

export type BuyerTypesKeys = keyof typeof BuyerTypes;

export const proLikeTypes = [BuyerTypes.professional, BuyerTypes.salesperson];
export const partLikeTypes = [BuyerTypes.particular, BuyerTypes.rider];
