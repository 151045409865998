import React, { memo } from "react";
import { useTranslation } from "react-i18next";

export const QuoteOffline: React.FC = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="common-wrap">
      <div className="content">
        <h3 className="header">
          {t("subscription.confirmation-page.congratulations")}
          <br />
          {t("subscription.confirmation-page.subscription-created")}
        </h3>
        <div className="divider" />
        <div className="contract-wrap">
          <p>{t("subscription.confirmation-page-offline.request-taken-subsidy")}</p>
          <h4>{t("subscription.confirmation-page.next-step")}</h4>
          <p>{t("subscription.confirmation-page-offline.final-acception-subsidy")}</p>
        </div>
      </div>
    </div>
  );
});
