type CookieSetOptions = {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
};

type SetCookieFunc = (name: string, value: string, options?: CookieSetOptions) => void;

type DeleteCookieFunc = (name: string) => void;

type UseCookiesHookReturnType = {
  rawCookies: string;
  cookies: Record<string, string>;
  setCookie: SetCookieFunc;
  deleteCookie: DeleteCookieFunc;
};

export const readCookie = (): Record<string, string> => {
  const rawCookies = document.cookie;

  const allCookiesKeyValuesArr: [string, string][] = rawCookies
    .split("; ")
    .map((cookie) => cookie.split("=").map(decodeURIComponent)) as [string, string][];

  return Object.fromEntries(allCookiesKeyValuesArr);
};

export const setCookie: SetCookieFunc = (name, value, options) => {
  const opts: Record<string, any> = {
    path: "/",
    maxAge: 86400 * 7, // 7 days default
    ...options,
  };

  if (opts.expires instanceof Date) {
    opts.expires = opts.expires.toUTCString();
  }

  let cookieDataToSet = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  Object.entries(opts).forEach(([key, value]) => {
    cookieDataToSet += "; " + key;
    const optionValue = value;
    if (optionValue !== true) {
      cookieDataToSet += "=" + optionValue;
    }
  });

  document.cookie = cookieDataToSet;
};

export const deleteCookie: DeleteCookieFunc = (name) => setCookie(name, "", { maxAge: -1 });

const useCookiesHook = (): UseCookiesHookReturnType => {
  return {
    rawCookies: document.cookie,
    cookies: readCookie(),
    setCookie,
    deleteCookie,
  };
};

export default useCookiesHook;
