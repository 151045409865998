import React, { memo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState, ScooterSetupState } from "store";
import { NonNullableKeys, BuyerTypes, proLikeTypes } from "models";
import { useDidMount, useGTM } from "hooks";
import { isEmpty, isEqual } from "utils";
import { uploadFiles } from "store/actions";
import { DEFAULT_PAGE, PAYMENT_PAGE, CONTRACT_PAGE } from "constants/routes.constants";
import { Uploader } from "components/Fourth_step/Uploader";
import { Footer } from "components/Footer";
import { isFormatValid, isExceedSize } from "./documents.utils";

const Documents: React.FC = memo(() => {
  useGTM(11);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { subscriptionId, setup, personalInfo, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const { cf_buyer_profile } = setup;
  const cf_power = +setup.cf_power;
  const { dob, driverDob } = personalInfo;
  const { selectedAddons } = paymentDetails;

  const documentLoadingStatus = useSelector((state: any) => state.user.documentLoadingStatus);
  const isAddingDocuments = useSelector((state: any) => state.user.loaders.isAddingDocuments);

  const [uploadedFiles, setUploadedFiles] = useState<Record<string, any>>({
    drivingLicense: [],
    secondDrivingLicense: [],
    id: [],
    address: [],
    kbis: [],
  });

  const [uploadingError, setUploadingError] = useState<Record<string, any>>({
    drivingLicense: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    secondDrivingLicense: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    id: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    address: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    kbis: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
  });

  useDidMount(() => !subscriptionId && history.push(DEFAULT_PAGE.path));

  const uploadFilesHandler = () => {
    dispatch(
      uploadFiles({
        data: uploadedFiles,
        isManualUpload: false,
        subscriptionId,
        documentsToUpload: Object.entries(uploadedFiles)
          .map(([key, value]) => !isEmpty(value) && key)
          .filter((item) => item),
        push: history.push,
      }),
    );
  };

  const isShowDriverLicense = (dob: string) => {
    const dobParsed = new Date(dob);
    const minDob = new Date("1988-01-01");
    return minDob < dobParsed;
  };

  const isSecondDriverAddonAdded = () => !!selectedAddons["pro_addition_driver"];

  const handleFileUpload = useCallback((acceptedFiles, documentName) => {
    // Setting no errors for particular documentName.
    setUploadingError((prevState) => ({
      ...prevState,
      [documentName]: { isSizeExceeded: false, isWrongFormat: false },
    }));

    const temp = acceptedFiles
      .map((file: any) => {
        // If file is Ok -> return file.
        if (isFormatValid(file.type) && !isExceedSize(file.size)) return file;
        // If file format isn't Ok -> set format error for this file.
        else if (!isFormatValid(file.type)) {
          setUploadingError((prevState) => ({
            ...prevState,
            [documentName]: {
              ...prevState[documentName],
              isWrongFormat: true,
            },
          }));
        }
        // If file size isn't Ok -> set size error for this file.
        else if (isExceedSize(file.size)) {
          setUploadingError((prevState) => ({
            ...prevState,
            [documentName]: {
              ...prevState[documentName],
              isSizeExceeded: true,
            },
          }));
        }

        return undefined;
      })
      .filter(Boolean);

    setUploadedFiles((prevState) => ({
      ...prevState,
      [documentName]: [...prevState[documentName], ...temp],
    }));
  }, []);

  const deleteHandler = (documentToDelete: any, document: any) => {
    const temp = uploadedFiles[document].filter((file: any) => !isEqual(file, documentToDelete));
    setUploadedFiles((prevState) => ({ ...prevState, [document]: temp }));
  };

  const skipStepHandler = () => history.push(CONTRACT_PAGE.path);

  const handlePrevButton = () => history.push(PAYMENT_PAGE.path);

  return (
    <div className="common-wrap">
      <div className="content">
        <div className="header">{t("subscription.document-upload.add-documents")}</div>
        <div className="subheader">{t("subscription.document-upload.add-documents-later")}</div>
        <div className="divider" />

        <div className="uploaders-wrap">
          {proLikeTypes.includes(cf_buyer_profile as BuyerTypes) ? (
            <>
              <Uploader
                header={t("subscription.document-upload.company-proof")}
                onDrop={handleFileUpload}
                document={"kbis"}
                uploadedFiles={uploadedFiles}
                isLoading={documentLoadingStatus.kbis.pending}
                deleteHandler={deleteHandler}
                errors={uploadingError.kbis}
              />
              <Uploader
                header={t("subscription.document-upload.legal-representative")}
                onDrop={handleFileUpload}
                document={"id"}
                uploadedFiles={uploadedFiles}
                isLoading={documentLoadingStatus.id.pending}
                deleteHandler={deleteHandler}
                errors={uploadingError.id}
              />
              {(isShowDriverLicense(dob) || cf_power >= 80) && (
                <Uploader
                  header={t("subscription.document-upload.driving-license")}
                  onDrop={handleFileUpload}
                  document={"drivingLicense"}
                  isLoading={documentLoadingStatus.drivingLicense.pending}
                  uploadedFiles={uploadedFiles}
                  deleteHandler={deleteHandler}
                  errors={uploadingError.drivingLicense}
                />
              )}
              {((isSecondDriverAddonAdded() && cf_power >= 80) ||
                (isSecondDriverAddonAdded() && isShowDriverLicense(driverDob))) && (
                <Uploader
                  header={t("subscription.document-upload.second-driving-license")}
                  onDrop={handleFileUpload}
                  document={"secondDrivingLicense"}
                  isLoading={documentLoadingStatus.secondDrivingLicense.pending}
                  uploadedFiles={uploadedFiles}
                  deleteHandler={deleteHandler}
                  errors={uploadingError.secondDrivingLicense}
                />
              )}
            </>
          ) : (
            <>
              {(isShowDriverLicense(dob) || cf_power >= 80) && (
                <Uploader
                  header={t("subscription.document-upload.driving-license")}
                  onDrop={handleFileUpload}
                  document={"drivingLicense"}
                  isLoading={documentLoadingStatus.drivingLicense.pending}
                  uploadedFiles={uploadedFiles}
                  deleteHandler={deleteHandler}
                  errors={uploadingError.drivingLicense}
                />
              )}
              <Uploader
                header={t("subscription.document-upload.card-or-passport")}
                onDrop={handleFileUpload}
                document={"id"}
                uploadedFiles={uploadedFiles}
                isLoading={documentLoadingStatus.id.pending}
                deleteHandler={deleteHandler}
                errors={uploadingError.id}
              />
              <Uploader
                header={t("subscription.document-upload.address-proof")}
                onDrop={handleFileUpload}
                document={"address"}
                isLoading={documentLoadingStatus.address.pending}
                uploadedFiles={uploadedFiles}
                deleteHandler={deleteHandler}
                errors={uploadingError.address}
              />
            </>
          )}
        </div>
      </div>
      <Footer
        nextButtonId="documents-uploader"
        skipStepHandler={skipStepHandler}
        nextButtonHandler={uploadFilesHandler}
        prevButtonHandler={handlePrevButton}
        isLoading={isAddingDocuments}
      />
    </div>
  );
});

export default Documents;
