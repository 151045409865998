import { useEffect, useRef } from "react";

type CallbackType = () => void;

type UseDidUpdate = (f: CallbackType, dependencies: any[], callOnMount?: boolean) => void;

const useDidUpdate: UseDidUpdate = (f, dependencies, callOnMount = false) => {
  const didMountRef = useRef(callOnMount);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    const rT = (f() as any) || "";
    if (rT && rT.constructor.name === "Function") {
      return rT;
    }
  }, dependencies);
};

export default useDidUpdate;
