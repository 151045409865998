import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { createSubscription } from "api";
import { useCall, useDidUpdate } from "hooks";
import { setInsurance, setSelectedAddons, setSubscriptionId, setPersonalInfo } from "store";
import { GENERAL_CONDITIONS_PAGE } from "constants/routes.constants";
import { Notification } from "components/Notification/Notification";
import { DateOfBirth } from "components/Inputs/DateOfBirth";
import { PhoneInput } from "components/Inputs/PhoneInput";
import { CountrySelect } from "components/Inputs/CountryInput";
import { TitleSelect, Input } from "components/Second_Step/PersonalInfoForm";
import { Footer } from "components/Footer";
import { getCookiesData } from "../personal-info.utils";

const validationSchema = Yup.object({
  civility: Yup.string().required("civility is required"),
  lastName: Yup.string().required("lastName is required"),
  firstName: Yup.string().required("firstName is required"),
  dateOfBirth: Yup.date().required("dateOfBirth is required"),
  countryCallingCode: Yup.string().required("countryCallingCode is required"),
  phone: Yup.string().min(7).required("phone is required"),
  street: Yup.string().required("street is required"),
  complement: Yup.string(),
  postalCode: Yup.string().required("postalCode is required"),
  city: Yup.string().required("city is required"),
  country: Yup.string().required("country is required"),
  // profession: Yup.string().required("profession is required"),
  workStreet: Yup.string(),
  workComplement: Yup.string(),
  workPostalCode: Yup.string(),
  workCity: Yup.string(),
  workCountry: Yup.string(),
});

const ParticularPersonalInfoForm = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const addons = useSelector((state) => state.scooterData.addons);
  const { setup, selectedPlan, paymentDetails } = useSelector((state) => state.scooterSetup);
  const { cf_insurance_base_addon_id, cf_insurance_25_addon_id } = selectedPlan;

  const { submit, submitting, onCallSuccess } = useCall(createSubscription);
  onCallSuccess(({ subscriptionId }) => {
    dispatch(setSubscriptionId(subscriptionId));
    history.push(GENERAL_CONDITIONS_PAGE.path);

    localStorage.removeItem("setup");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("paymentDetails");
  }, []);

  const {
    selectedCouponsIds,
    selectedCoupons,
    firstPayment,
    insurance: { driverUnder25 },
    selectedAddons,
  } = paymentDetails;

  const formik = useFormik({
    initialValues: {
      civility: "",
      lastName: "",
      firstName: "",
      dateOfBirth: "",
      countryCallingCode: "",
      phone: "",
      street: "",
      complement: "",
      postalCode: "",
      city: "",
      country: "FR",
      profession: "",
      workStreet: "",
      workComplement: "",
      workPostalCode: "",
      workCity: "",
      workCountry: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        civility,
        lastName,
        firstName,
        dateOfBirth,
        countryCallingCode,
        phone,
        street,
        complement,
        postalCode,
        city,
        country,
        // profession,
        workStreet,
        workComplement,
        workPostalCode,
        workCity,
        workCountry,
      } = values;

      const addonsAndQuantity = {};
      Object.entries(selectedAddons).forEach(([id, { count }]) => {
        if (count < 1) return;
        addonsAndQuantity[id] = count;
      });

      if (moment().diff(dateOfBirth, "years") < 25) {
        addonsAndQuantity["surprime-jeune-conducteur"] = 1;
      }

      const enteredCoupons = selectedCoupons.length ? [selectedCoupons[0].id] : [];
      const couponIds = enteredCoupons.concat(selectedCouponsIds);

      const data = {
        ...getCookiesData(),
        planId: selectedPlan.id,
        scooterColor: setup.cf_color,
        addonsAndQuantity,
        couponIds,
        BONUS: firstPayment * 100,
        civility,
        firstName,
        lastName,
        dob: moment(dateOfBirth).format("YYYY-MM-DD"),
        phone: `+${countryCallingCode}${phone}`,
        street,
        complement,
        postalCode,
        city,
        country,
        profession: "OTHER_CATEGORY",
        workStreet,
        workComplement,
        workPostalCode,
        workCity,
        workCountry,
      };

      dispatch(setPersonalInfo(data));
      submit(data);
    },
  });

  const { dateOfBirth } = formik.values;

  // Insurance addons handle.
  useDidUpdate(() => {
    if (driverUnder25 || !addons.length) return;

    // Year difference between current date and entered date.
    const yearEntered = moment().diff(dateOfBirth, "years");

    const birthdateUnder25 = yearEntered < 25;

    const addonToCheck = birthdateUnder25 ? cf_insurance_25_addon_id : cf_insurance_base_addon_id;
    const oppositeAddonToCheck = birthdateUnder25
      ? cf_insurance_base_addon_id
      : cf_insurance_25_addon_id;

    const foundAddon = addons.find((addon) => addon.id === addonToCheck);
    const shouldAdd = foundAddon && !selectedAddons[foundAddon.id];
    if (!shouldAdd || !foundAddon) return;
    const { id, price } = foundAddon;

    dispatch(
      setSelectedAddons({
        [id]: { count: 1, price },
        [oppositeAddonToCheck]: { count: 0, price },
      }),
    );

    dispatch(setInsurance({ birthdateUnder25 }));
  }, [dateOfBirth, addons]);

  function validatePostalCodeOnBlur(name) {
    let countryToCheck = "country";
    if (name === "proPostalCode") {
      countryToCheck = "proCountry";
    }
    if (name === "workPostalCode") {
      countryToCheck = "workCountry";
    }

    const value = formik.values[name];

    // If country is not France OR postal code value is empty -> clear error and return.
    if (formik.values[countryToCheck] !== "FR" || !value) {
      formik.setFieldTouched(name, false);
      formik.validateField(name);
      return;
    }

    // If country is France AND postal code value length isn't equal 5 symbols -> set error and return.
    if (value.length !== 5) {
      // Fix weird bug when you set error when already has one, error for some reason disappears.
      // So if we have error already established do not set new one and only return.
      if (!Boolean(formik.errors[name])) {
        formik.setFieldTouched(name, true);
        formik.setFieldError(name, "Field doesn't pass validation");
      }
      return;
    }
    // Everything is ok -> clear error.
    formik.setFieldTouched(name, false);
    formik.validateField(name);
  }

  function validatePostalCodeOnCountryChange(name, countryValue) {
    let postalCodeToValidate = "postalCode";
    if (name === "proCountry") {
      postalCodeToValidate = "proPostalCode";
    }
    if (name === "workCountry") {
      postalCodeToValidate = "workPostalCode";
    }

    const postalCodeValue = formik.values[postalCodeToValidate];

    // If country is not France OR postal code value is empty -> clear error and return.
    if (countryValue !== "FR" || !postalCodeValue) {
      formik.setFieldTouched(postalCodeToValidate, false);
      return;
    }

    // If country is France AND postal code value length isn't equal 5 symbols -> set error and return.
    if (postalCodeValue.length !== 5) {
      formik.setFieldError(postalCodeToValidate, "Field doesn't pass validation");
      formik.setFieldTouched(postalCodeToValidate, true);
    }
  }

  return (
    <div className="common-wrap">
      <div className="personal-form-wrap">
        <div className="required-wrapper">
          <span className="required">
            <span>*</span>
            {t("subscription.personal-information")}
          </span>
        </div>
        <form onSubmit={formik.handleSubmit} autoComplete={"on"}>
          <h4>
            <span>*</span>
            {t("subscription.personal-information.name-question")}
          </h4>
          <div className="surname-wrap">
            <TitleSelect
              t={t}
              formik={formik}
              name="civility"
              error={(formik.touched.civility && Boolean(formik.errors.civility)) || false}
            />
            <Input
              t={t}
              formik={formik}
              name="lastName"
              error={(formik.touched.lastName && Boolean(formik.errors.lastName)) || false}
            />
          </div>
          <Input
            t={t}
            formik={formik}
            name="firstName"
            error={(formik.touched.firstName && Boolean(formik.errors.firstName)) || false}
          />
          <h4>
            <span>*</span>
            {t("subscription.professional-information.is-driver-dob")}
          </h4>
          <DateOfBirth
            t={t}
            onValidDate={(date) => {
              formik.setFieldValue("dateOfBirth", date, true);
            }}
            onClear={() => {
              formik.setFieldValue("dateOfBirth", "");
            }}
            onError={() => {
              formik.setFieldValue("dateOfBirth", "");
            }}
            error={(formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)) || false}
          />
          {moment().diff(dateOfBirth, "years") < 25 && !driverUnder25 && (
            <Notification t={t} text={"subscription.personal-information.under-25-text"} />
          )}
          <h4>
            <span>*</span>
            {t("subscription.personal-information.phone-question")}
          </h4>
          <PhoneInput
            t={t}
            onValidPhone={(countryCode, phoneNumber) => {
              formik.setFieldValue("phone", phoneNumber, true);
              formik.setFieldValue("countryCallingCode", countryCode, true);
              formik.setFieldTouched("phone", false);
              formik.setFieldTouched("countryCallingCode", false);
            }}
            clearPhone={() => {
              formik.setFieldValue("phone", "");
              formik.setFieldValue("countryCallingCode", "");
              formik.setFieldTouched("phone", false);
              formik.setFieldTouched("countryCallingCode", false);
            }}
            onError={() => {
              formik.setFieldValue("phone", "", true);
              formik.setFieldValue("countryCallingCode", "", true);
              formik.setFieldTouched("phone", true);
              formik.setFieldTouched("countryCallingCode", true);
            }}
            error={(formik.touched.phone && Boolean(formik.errors.phone)) || false}
          />
          <h4>
            <span>*</span>
            {t("subscription.personal-information.address_question")}
          </h4>
          <Input
            t={t}
            formik={formik}
            name="street"
            error={(formik.touched.street && Boolean(formik.errors.street)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="complement"
            error={(formik.touched.complement && Boolean(formik.errors.complement)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="postalCode"
            error={(formik.touched.postalCode && Boolean(formik.errors.postalCode)) || false}
            onBlurMethod={validatePostalCodeOnBlur}
          />
          <Input
            t={t}
            formik={formik}
            name="city"
            error={(formik.touched.city && Boolean(formik.errors.city)) || false}
          />
          <CountrySelect
            t={t}
            formik={formik}
            name="country"
            error={(formik.touched.country && Boolean(formik.errors.country)) || false}
            onChange={validatePostalCodeOnCountryChange}
          />
          {/*<h4>*/}
          {/*  <span>*</span>*/}
          {/*  {t("subscription.personal-information.job")}*/}
          {/*</h4>*/}
          {/*<TitleSelect*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="profession"*/}
          {/*  error={(formik.touched.profession && Boolean(formik.errors.profession)) || false}*/}
          {/*/>*/}

          {/*<h4>{t("subscription.professtional-information.question")}</h4>*/}
          {/*<Input*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="workStreet"*/}
          {/*  error={(formik.touched.workStreet && Boolean(formik.errors.workStreet)) || false}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="workComplement"*/}
          {/*  error={*/}
          {/*    (formik.touched.workComplement && Boolean(formik.errors.workComplement)) || false*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="workPostalCode"*/}
          {/*  error={*/}
          {/*    (formik.touched.workPostalCode && Boolean(formik.errors.workPostalCode)) || false*/}
          {/*  }*/}
          {/*  onBlurMethod={validatePostalCodeOnBlur}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="workCity"*/}
          {/*  error={(formik.touched.workCity && Boolean(formik.errors.workCity)) || false}*/}
          {/*/>*/}
          {/*<CountrySelect*/}
          {/*  t={t}*/}
          {/*  formik={formik}*/}
          {/*  name="workCountry"*/}
          {/*  error={(formik.touched.workCountry && Boolean(formik.errors.workCountry)) || false}*/}
          {/*  onChange={validatePostalCodeOnCountryChange}*/}
          {/*/>*/}
          <Footer
            nextButtonId="particular-personal-info-form"
            type="submit"
            isLoading={submitting}
          />
        </form>
      </div>
    </div>
  );
});

export default ParticularPersonalInfoForm;
