import { call, put } from "redux-saga/effects";

import * as a from "../../actions/account";

import { request } from "../../../utils/request";

export function* getCustomerDataSaga() {
  try {
    const response = yield call(request, "/customers/me/full");
    const data = yield response.json();

    yield put(a.getCustomerDataSuccess(data));
  } catch (error) {
    yield put(a.getCustomerDataFailure(error));
  }
}
