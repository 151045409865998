import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { isEmpty } from "utils";

import FileImage from "assets/images/uploader/file.svg";
import FileLoaded from "assets/images/icons/ok.svg";
import Folder from "assets/images/uploader/folder.svg";
import Camera from "assets/images/uploader/camera.svg";
import Clock from "assets/images/icons/clock.svg";
import WarningIcon from "assets/images/icons/warning.svg";
import ErrorIcon from "assets/images/icons/error.svg";

const mapDocumentToError = {
  drivingLicense: "cf_driver_licence_bsr_check",
  secondDrivingLicense: "cf_2nd_driver_licence_bsr_check", //TODO:should be replaced
  id: "cf_cf_buyer_passport_check",
  address: "cf_residence_proof_check",
  kbis: "cf_kbis_check",
};

export function Uploader(props) {
  const { location } = useHistory();
  const { t } = useTranslation();

  const {
    subscription = {},
    errors = {},
    header,
    onDrop,
    isLoading = false,
    document,
    uploadedFiles,
    deleteHandler,
    documentsList = [],
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles, document);
    },
  });

  const deleteDocumentHandler = (e, documentToDelete) => {
    e.stopPropagation();
    deleteHandler(documentToDelete, document);
  };

  return (
    <div {...getRootProps()} className="uploader">
      <input {...getInputProps({ accept: ".jpg, .png, .jpeg, .pdf" })} />

      <div className="image-wrap">
        <img
          src={FileImage}
          className={cn("file-image", {
            account: location.pathname === "/account/documents",
          })}
          alt=""
        />

        {isLoading ? (
          <div className={cn("icon-check active")}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            {(subscription[mapDocumentToError[document]] === "validated" ||
              subscription[mapDocumentToError[document]] === "not needed" ||
              !!uploadedFiles[document].length) && (
              <div className={cn("icon-check active")}>
                <img src={FileLoaded} alt="file loaded" />
              </div>
            )}
            {subscription[mapDocumentToError[document]] === "not verified" && (
              <div className={cn("icon-check active")}>
                <img src={Clock} alt="file is not verified" />
              </div>
            )}
            {subscription[mapDocumentToError[document]] === "warning" && (
              <div className={cn("icon-check active")}>
                <img src={ErrorIcon} alt="file error" />
              </div>
            )}
            {subscription[mapDocumentToError[document]] === "empty" && (
              <div className={cn("icon-check active")}>
                <img src={WarningIcon} alt="file warning" />
              </div>
            )}
          </>
        )}
      </div>

      <div className="mobile-wrapper">
        <div
          className={cn("images-mobile", {
            account: location.pathname === "/account/documents",
          })}
        >
          {isLoading ? (
            <div className={"image-mobile-scroll"}>
              <CircularProgress size={28} />
            </div>
          ) : (
            <>
              <div className="image-mobile-wrap">
                <img src={Camera} alt="Camera" />
              </div>
              <div className="image-mobile-wrap">
                <img src={Folder} alt="folder" />
              </div>
            </>
          )}
        </div>

        <div className="headers-wrap">
          <h4>{header}</h4>

          {document === "address" && <h6>{t("subscription.document-upload.rent-receipt")}</h6>}

          {document === "kbis" && (
            <h6 className="document-size">{t("subscription.document-upload.kbis-subtext")}</h6>
          )}
          <h6 className="document-size">
            {t("subscription.document-upload.available-format-rent-receipt")}
          </h6>
          <h6 className="document-size">
            {t("subscription.document-upload.maximum-size-rent-receipt")}
          </h6>
        </div>
      </div>
      {(!!uploadedFiles[document].length || !!documentsList.length) && (
        <ul>
          {uploadedFiles[document].map((item, index) => (
            <li key={index}>
              <div>{item.path}</div>
              <button onClick={(e) => deleteDocumentHandler(e, item)}>
                <DeleteOutlineIcon />
              </button>
            </li>
          ))}
          {documentsList.map((item, index) => (
            <li key={`document_${index}`}>
              <div>{item}</div>
            </li>
          ))}
        </ul>
      )}
      {!isEmpty(subscription) && (
        <div className="document-status">
          {(subscription[mapDocumentToError[document]] === "validated" ||
            subscription[mapDocumentToError[document]] === "not needed" ||
            !!uploadedFiles[document].length) && (
            <h6 className="valid-document">{t("my-account.dashboard.valid")}</h6>
          )}
          {subscription[mapDocumentToError[document]] === "not verified" && (
            <h6 className="not-verified-document">{t("my-account.dashboard.checking")}</h6>
          )}
          {subscription[mapDocumentToError[document]] === "warning" && (
            <h6 className="warning-document">{t("my-account.dashboard.refused-document")}</h6>
          )}
          {subscription[mapDocumentToError[document]] === "empty" && (
            <h6 className="missing-document">{t("my-account.dashboard.missing-document")}</h6>
          )}
        </div>
      )}
      {errors.isWrongFormat && (
        <h6 className="missing-document">{t("my-account.documents.wrong-format")}</h6>
      )}
      {errors.isSizeExceeded && (
        <h6 className="missing-document">{t("my-account.documents.document-size-exceeded")}</h6>
      )}
      {uploadedFiles[document].length > 3 && (
        <h6 className="missing-document">{t("my-account.documents.document-numbers-exceeded")}</h6>
      )}
    </div>
  );
}

Uploader.propTypes = {
  header: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  document: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  uploadedFiles: PropTypes.object.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  documentsList: PropTypes.array,
  errors: PropTypes.object.isRequired,
};
