import React, { memo } from "react";
// import { useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";

import { useGTM } from "hooks";
import {baseUrlLocale} from "../../../utils";
// import { SelectButton } from "components";
// import { Notification } from "components/Notification/Notification";
// import { Footer } from "components/Footer";
// import { useGTM, useQuery, useDidMount } from "hooks";
// import { BuyerTypesKeys } from "models";
// import { setScooterBuyerType } from "store";
// import { mapNotificationTextToUserType, validTypes } from "../scooter-setup.constants";

// type QueryType = {
//   cf_buyer_profile?: string;
// };

const UserType: React.FC = memo(() => {
  useGTM(1);

  // const dispatch = useDispatch();
  // const { t } = useTranslation();

  // const { query, setQueryParam } = useQuery<QueryType>();

  // const buyerType = validTypes.find((type) => query.cf_buyer_profile === type);

  // const blockClickHandler = (data: BuyerTypesKeys) => () => {
  //   const dataToSet = buyerType === data ? "" : data;
  //   setQueryParam("cf_buyer_profile", dataToSet);
  // };
  //
  // const nextButtonOnClick = () => buyerType && dispatch(setScooterBuyerType(buyerType));
  //
  // const shouldNextButtonDisabled = () => {
  //   if (window.location.hostname.match(/^app.zeway.com$/) && buyerType === "transport") return true;
  //   return !buyerType;
  // };

  // useDidMount(() => buyerType && nextButtonOnClick());

  return (
    <>
      <div className="common-wrap">
        <div className="content">
          <div className="attentionWrapper">
            <div className="attentionTitle">
              Vous avez rafraîchi votre navigateur pendant votre inscription.
            </div>
            <div className="attentionSubtitle">
              Votre compte est bien créé, mais vous devez sélectionner à nouveau l’ensemble des
              paramètres de votre offre pour finaliser votre abonnement.
            </div>
            <a className="attentionLink" href={`${baseUrlLocale()}abonnement`}>
              Retour à la sélection du scooter
            </a>
          </div>
          {/*<h3 className="header">{t("subscription.buyer-type.appeal")}</h3>*/}
          {/*<div className="divider" />*/}
          {/*<div className="user-types-wrap">*/}
          {/*  <SelectButton*/}
          {/*    className="user-type"*/}
          {/*    selected={buyerType === "particular"}*/}
          {/*    onClick={blockClickHandler("particular")}*/}
          {/*  >*/}
          {/*    {t("subscription.byuer-type.particular")}*/}
          {/*  </SelectButton>*/}
          {/*  <SelectButton*/}
          {/*    className="user-type"*/}
          {/*    selected={buyerType === "professional"}*/}
          {/*    onClick={blockClickHandler("professional")}*/}
          {/*  >*/}
          {/*    {t("subscription.byuer-type.professional")}*/}
          {/*    <div className="type-description">*/}
          {/*      {t("subscription.byuer-type.professional-description")}*/}
          {/*    </div>*/}
          {/*  </SelectButton>*/}
          {/*  <SelectButton*/}
          {/*    className="user-type"*/}
          {/*    selected={buyerType === "transport"}*/}
          {/*    onClick={blockClickHandler("transport")}*/}
          {/*  >*/}
          {/*    {t("subscription.byuer-type.rider")}*/}
          {/*    <div className="type-description">*/}
          {/*      {t("subscription.byuer-type.rider-description")}*/}
          {/*    </div>*/}
          {/*  </SelectButton>*/}
          {/*</div>*/}
          {/*{buyerType && <Notification t={t} text={mapNotificationTextToUserType[buyerType]} />}*/}
          {/*<p*/}
          {/*  className="several-scooters"*/}
          {/*  dangerouslySetInnerHTML={{*/}
          {/*    __html: t("subscription.byuer-type.rent-several-scooters-new"),*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
      </div>
      {/*<Footer*/}
      {/*  nextButtonId="buyer-type-select"*/}
      {/*  nextButtonHandler={nextButtonOnClick}*/}
      {/*  isPrevButtonDisabled*/}
      {/*  isNextButtonDisabled={shouldNextButtonDisabled()}*/}
      {/*/>*/}
    </>
  );
});

export default UserType;
