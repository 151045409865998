import { useEffect } from "react";

import { useCookies, useQuery } from "hooks";

type QueryType = {
  store_code?: string;
};

const useStoreCode = (): void => {
  const { setCookie } = useCookies();

  const { query } = useQuery<QueryType>();

  const storeCode = query["store_code"];

  useEffect(() => {
    if (!storeCode) return;

    const isLocalHost = location.hostname.includes("localhost");

    if (isLocalHost) {
      setCookie("store_code", storeCode, {
        path: "/",
        maxAge: 7.776e6,
      });
      return;
    }

    setCookie("store_code", storeCode, {
      path: "/",
      domain: ".zeway.com",
      maxAge: 7.776e6,
    });
  }, [storeCode]);
};

export default useStoreCode;
