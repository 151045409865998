const apiURL = process.env.REACT_APP_API_URL || "https://api.develop.zeway.com";

export const request = (url, method = "GET", params, headers, isAuthorizationRequired = true) => {
  const fullUrl = `${apiURL}${url}`;
  const token = localStorage.getItem("token");

  return fetch(fullUrl, {
    method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: isAuthorizationRequired && token ? `Bearer ${token}` : "",
      ...headers,
    },
    ...params,
  }).then((response) => {
    if (!response.ok) {
      throw response;
    }
    return response;
  });
};

export default request;
