import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PlanModel, AddonModel } from "models";

export type ScooterDataState = {
  plans: PlanModel[];
  addons: AddonModel[];
};

const initialState: ScooterDataState = {
  plans: [],
  addons: [],
};

const scooterData = createSlice({
  name: "scooter-data",
  initialState,
  reducers: {
    setPlansAndAddons: (
      state,
      action: PayloadAction<{
        plans: PlanModel[];
        addons: AddonModel[];
      }>,
    ) => {
      const { plans, addons } = action.payload;
      state.plans = plans;
      state.addons = addons;
    },
  },
});

export const { setPlansAndAddons } = scooterData.actions;

export default scooterData.reducer;
