import React, { Fragment, memo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useDidUpdate } from "hooks";
import { getCoupon } from "api";
import { RootState, ScooterSetupState } from "store";
import { CouponModel, NonNullableKeys } from "models";
import { checkForIntegerToFixed } from "utils/prices";
import { mapPower } from "../scooter-subscription-setup.constants";

import { ReactComponent as Loader } from "assets/images/contract-loader.svg";

type ParticularSubscriptionDetailsContentProps = {
  hideButtons?: boolean;
  handleClose?: (value: false) => void;
  handleSubmit?: VoidFunction;
};

export const ParticularSubscriptionDetailsContent: React.FC<ParticularSubscriptionDetailsContentProps> =
  memo(
    ({
      handleClose,
      handleSubmit,
      hideButtons = false,
    }: ParticularSubscriptionDetailsContentProps) => {
      const { t } = useTranslation();

      const [fetchedCoupons, setFetchedCoupons] = useState<CouponModel[]>([]);
      const [fetchingCoupons, setFetchingCoupons] = useState(true);

      const addons = useSelector((state: RootState) => state.scooterData.addons);

      const { selectedPlan, paymentDetails, setup } = useSelector(
        (state: RootState) => state.scooterSetup,
      ) as NonNullableKeys<ScooterSetupState>;

      const { cf_model, cf_power, cf_color } = setup;

      const {
        firstPayment,
        monthlyPayment,
        insurance: { birthdateUnder25, driverUnder25, insBasePrice, ins25Price },
        cautionAddon,
        selectedCouponsIds,
        selectedCoupons,
        selectedAddons,
      } = paymentDetails;

      const {
        id,
        cf_price_year_4_5 = "0",
        cf_caution_addon_id,
        cf_insurance_base_addon_id,
        cf_insurance_25_addon_id,
      } = selectedPlan || {};

      const applicableAddons = selectedPlan?.applicable_addons?.map((item) => item.id) || [];

      const isSwapperSE = id === "SWAPPER_PART_SE";
      const isRider = id === "SWAPPER_RIDER";

      const key = isSwapperSE
        ? "subscription.subscription-choice.month-to-month-subscription"
        : isRider
        ? "subscription.subscription-choice.2-year-subscription-rider"
        : "subscription.subscription-choice.3-year-subscription";

      const key1 = isRider
        ? "subscription.subscription-choice.after2-year-subscription-rider"
        : "subscription.subscription-choice.2-year-subscription";

      const enteredCoupons = selectedCoupons.length ? [selectedCoupons[0].id] : [];
      const couponIds = enteredCoupons.concat(selectedCouponsIds);

      useDidUpdate(
        () => {
          setFetchedCoupons([]);

          if (!couponIds.length) return setFetchingCoupons(false);

          setFetchingCoupons(true);

          const promises = couponIds.map(getCoupon);

          Promise.all(promises)
            .then((res) => {
              const coupons: CouponModel[] = [];
              res.forEach(([data, error]) => !!data && !error && coupons.push(data));
              setFetchedCoupons(coupons);
            })
            .finally(() => setFetchingCoupons(false));
        },
        [couponIds.length],
        true,
      );

      return (
        <div className="subscription-modal">
          <div className="price-details">
            <h4>{t("subscription.cart.my-scooter")}</h4>
            <div className="scooter-setup">
              {t(`subscription.scooter-choice.${cf_model}`)} {t(mapPower[cf_power])}{" "}
              {t(`subscription.color-choice.${cf_color}`)}
            </div>
            <h4>
              <span className="">{t("subscription.cart.contibution")}</span>
              <span className="header-price">
                {firstPayment}€ {t("subscription.subscription-choice.ttc")}
              </span>
            </h4>
            <h4>{t("subscription.cart.subscription")}</h4>
            <ul>
              <li>
                <div className="title">{t(key)}</div>
                <div className="content">
                  <div className="main-price">
                    <span className="price">
                      {checkForIntegerToFixed(
                        monthlyPayment +
                          (driverUnder25 || birthdateUnder25 ? ins25Price : insBasePrice),
                      )}
                      €
                    </span>
                    <span className="period">{t("subscription.header.ttc-month")}</span>
                  </div>
                </div>
              </li>
              {!isSwapperSE && (
                <li>
                  <div className="title">{t(key1)}</div>
                  <div className="content">
                    <div className="main-price">
                      <span className="price">
                        {checkForIntegerToFixed(
                          Number(cf_price_year_4_5) / 100 +
                            (driverUnder25 || birthdateUnder25 ? ins25Price : insBasePrice),
                        )}
                        €
                      </span>
                      <span className="period">{t("subscription.header.ttc-month")}</span>
                    </div>
                  </div>
                </li>
              )}
            </ul>
            <div className="divider" />
            <h4>{t("subscription.cart.stuff")}</h4>
            <ul>
              <li>
                <div className="title">{t("subscription.cart.options-icluded")}</div>
                <div className="content">
                  <div className="main-price">
                    <div className="price crossed">
                      {t("subscription.subscription-choice.amount-crossed")}
                    </div>
                    <div className="period">{t("subscription.subscription-choice.ttc")}</div>
                  </div>
                  <div className="sub-price">
                    <div className="price">
                      {t("subscription.subscription-choice.introductory-offer")}
                    </div>
                  </div>
                </div>
              </li>
              {Object.entries(selectedAddons).map(([id, { price, count }], index) => {
                if (
                  !count ||
                  id === cf_insurance_base_addon_id ||
                  id === cf_insurance_25_addon_id ||
                  id === "pro_addition_driver" ||
                  (cf_caution_addon_id && id === cf_caution_addon_id) ||
                  !applicableAddons.includes(id)
                ) {
                  return null;
                }

                const foundAddon = addons.find((addon) => id === addon.id);

                if (!foundAddon || foundAddon.cf_display_as_option === "false") {
                  return null;
                }

                return (
                  <li key={index}>
                    <div className="title">
                      {count}x {t(`subscription.subscription-choice.addon.${id}`)}
                    </div>
                    <div className="content">
                      {(price / 100) * count} € {t("subscription.subscription-choice.ttc")}
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="divider" />
            {fetchingCoupons && <Loader width={24} height={24} />}
            {!fetchingCoupons &&
              fetchedCoupons.map((coupon) => {
                const { meta_data, id: couponId, discount_amount = 0 } = coupon || {};
                const isReferral = meta_data?.coupon_type === "refferal";

                return (
                  <Fragment key={couponId}>
                    <h4>{t("subscription.cart.my-promotions")}</h4>
                    <ul>
                      <li>
                        <div className="title">
                          {isReferral
                            ? t("subscription.subscription-choice.referral-coupon-cart-description")
                            : t(`subscription.subscription-choice.${couponId}`)}
                        </div>
                        <div className="content">
                          {couponId === "3_MONTHS_OFFERED" && "3x "}-{discount_amount / 100}€{" "}
                          {t("subscription.subscription-choice.ttc")}
                        </div>
                      </li>
                    </ul>
                  </Fragment>
                );
              })}
            <h4>
              <div className="subheader">
                <span>{t("subscription.subscription-choice.deposit")}</span>{" "}
                {t("subscription.cart.return-moment")}
              </div>
              <span className="header-price">{cautionAddon && cautionAddon.price / 100}€</span>
            </h4>
            <p>{t("subscription.cart.levy")}</p>
          </div>
          {handleSubmit && !hideButtons && (
            <div className="buttons-wrap">
              <button onClick={() => handleClose?.(false)} className={"cancel-button"}>
                {t("subscription.cart.cancel")}
              </button>
              <button
                id="particular-subscription-details-modal"
                onClick={() => {
                  window.scrollTo(0, 0);
                  handleSubmit();
                }}
                className={"next-button"}
              >
                {t("subscription.cart.subscribe")}
              </button>
            </div>
          )}
        </div>
      );
    },
  );
