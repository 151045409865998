import React, { memo, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import { Trans, useTranslation } from "react-i18next";

import { SelectButton } from "components";
import { Footer } from "components/Footer";
import { Notification } from "components/Notification/Notification";
import { useWindowSize, useDidMount, useDidUpdate } from "hooks";
import {
  RootState,
  ScooterSetupState,
  clearPaymentDetails,
  setPaymentDetails,
  setInsurance,
  setSelectedAddons,
} from "store";
import { NonNullableKeys } from "models";
import { SCOOTER_SETUP_PAGE, PERSONAL_INFO_PAGE } from "constants/routes.constants";
import { checkForIntegerToFixed } from "utils/prices";
import { Addons } from "./Addons";
import { SecurityDeposit } from "./SecurityDeposit";
import { PromoCode } from "./PromoCode";
import { Insurance } from "./Insurance";
import {
  mapPower,
  inclusiveList,
  offersList,
  offersPriceList,
} from "../scooter-subscription-setup.constants";
import { ParticularSubscriptionDetailsContent } from "./ParticularSubscriptionDetailsContent";

import LogoLight from "assets/images/logo-light.svg";

const ParticularSubscriptionDetails: React.FC = memo(() => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [isOpen, setIsModalOpen] = useState(false);

  const addons = useSelector((state: RootState) => state.scooterData.addons);
  const { setup, selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const { cf_model, cf_power, cf_color, cf_contribution } = setup;

  const {
    cf_price_year_4_5 = "0",
    cf_caution_addon_id,
    cf_insurance_base_addon_id,
    cf_insurance_25_addon_id,
  } = selectedPlan;

  const marks = useMemo(() => {
    return Object.keys(cf_contribution).map((price) => ({ value: +price, label: `${price}€` }));
  }, [cf_contribution]);

  const { firstPayment, monthlyPaymentWithoutTaxes, insurance } = paymentDetails;
  const { insBasePrice, ins25Price, cf_insurance_overcost } = insurance;
  const [driverUnder25, setDriverUnder25] = useState(!!state?.cf_buyer_under_25 || false);

  useDidMount(() => {
    if (!addons.length) return;

    // Particular insurance addons to save in store.
    const insBaseAddon = addons.find((addon) => addon.id === cf_insurance_base_addon_id);
    const ins25Addon = addons.find((addon) => addon.id === cf_insurance_25_addon_id);

    if (insBaseAddon) {
      dispatch(
        setInsurance({
          insBasePrice: insBaseAddon.price / 100,
          ins25Price: (ins25Addon?.price || 0) / 100,
          cf_insurance_overcost: (Number(ins25Addon?.cf_insurance_overcost) || 0) / 100,
        }),
      );
    }

    // Caution Addon
    const cautionAddon = addons.find((addon) => addon.id === cf_caution_addon_id);

    if (!cautionAddon) return;

    dispatch(setSelectedAddons({ [cautionAddon.id]: { count: 1, price: cautionAddon.price } }));
    dispatch(setPaymentDetails({ cautionAddon }));
  });

  useDidUpdate(
    () => {
      if (firstPayment && monthlyPaymentWithoutTaxes) return;

      dispatch(
        setPaymentDetails({
          firstPayment: firstPayment,
          monthlyPayment: cf_contribution[firstPayment].price_TTC / 100,
          monthlyPaymentWithoutTaxes: cf_contribution[firstPayment].price_HT / 100,
          discountedSubscription: +cf_price_year_4_5,
        }),
      );
    },
    [cf_contribution],
    true,
  );

  // Insurance addons handle.
  useDidUpdate(
    () => {
      if (!addons.length) return;

      const addonToCheck = driverUnder25 ? cf_insurance_25_addon_id : cf_insurance_base_addon_id;
      const oppositeAddonToCheck = driverUnder25
        ? cf_insurance_base_addon_id
        : cf_insurance_25_addon_id;

      const foundAddon = addons.find((addon) => addon.id === addonToCheck);

      if (!foundAddon) return;

      const { id, price } = foundAddon;

      dispatch(
        setSelectedAddons({
          [id]: { count: 1, price },
          [oppositeAddonToCheck]: { count: 0, price },
        }),
      );

      dispatch(setInsurance({ driverUnder25 }));
    },
    [driverUnder25, addons.length],
    true,
  );

  useDidMount(() => {
    if (!state) return;
    history.push(PERSONAL_INFO_PAGE.path);
  });

  const handleMarkChange = (value: number) => () => {
    dispatch(
      setPaymentDetails({
        firstPayment: value,
        monthlyPayment: cf_contribution[value].price_TTC / 100,
        monthlyPaymentWithoutTaxes: cf_contribution[value].price_HT / 100,
      }),
    );
  };

  const handlePreviousButtonClick = () => {
    dispatch(clearPaymentDetails());
    history.push(SCOOTER_SETUP_PAGE.path);
  };

  const handleSubmit = () => history.push(PERSONAL_INFO_PAGE.path);

  return (
    <div className="common-wrap">
      <div className="subscribtions-details-wrap">
        <div className="scooter-info">
          <h6>{t("subscription.color-choice.scooter")}</h6>
          <div>
            {t(`subscription.scooter-choice.${cf_model}`)}{" "}
            <span className="semilight">
              — {t("subscription.power-choice.equivalent")} {t(mapPower[cf_power])} -{" "}
              {t("subsctription.subscription-choice-color")}{" "}
              {t(`subscription.color-choice.${cf_color}`)}
            </span>
          </div>
        </div>

        <div className="scooter-title">
          <h2>
            <Trans i18nKey="subscription.subscription-choice">
              {{
                price: checkForIntegerToFixed(
                  cf_contribution[firstPayment] &&
                    cf_contribution[firstPayment]["price_TTC"] / 100 + insBasePrice,
                ),
              }}
            </Trans>
            <span>
              &nbsp;
              {t("subscription.subscription-choice.ttc-month")}
              &nbsp;
            </span>
          </h2>
          <div className="bonus-eco-text">{t("subscription.subscription-choice.ttc-bonus")}</div>
        </div>
        <div className="divider" />
        <div className="lists-wrap">
          <div className="inclusives-wrap">
            <h5>{t("subscription.subscription-choice.total")}</h5>
            <ul>
              {inclusiveList.map((item, index) => (
                <li key={index}>
                  <div>{t(item)}</div>
                  {inclusiveList.length === index + 1 && <img src={LogoLight} alt="" />}
                </li>
              ))}
            </ul>
          </div>
          <div className="offer-wrap">
            <h5>{t("subscription.subscription-choice.inclusion")}</h5>
            <ul>
              {offersList.map((item, index) => (
                <li key={index}>
                  <div className="offer">{t(item)}</div>
                  <div>{t(offersPriceList[item])}</div>
                </li>
              ))}
            </ul>
            <div className="introductory-offer-container">
              <div className="price">
                <span className="crossed">300€</span> {t("subscription.subscription-choice.ttc")}
              </div>
              <div className="free-offer">
                <h4>{t("subscription.subscription-choice.free")}</h4>
                <h6>
                  {width > 768 ? <>-</> : null}{" "}
                  {t("subscription.subscription-choice.introductory-offer")}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <h2>{t("subscription.subscription-choice.choose-subscription")}</h2>
        <div className="divider" />

        <div className="long-term-wrap">
          <div className="header">
            <h6>{t("subscription.subscription-choice.3-year-subscription")}</h6>
            <div className="subheader subheader-particular">
              <div>{t(`subscription.subscription-choice-header.particular.${firstPayment}`)}</div>
              <div className="price">
                {(firstPayment || firstPayment === 0) && (
                  <>
                    {checkForIntegerToFixed(
                      cf_contribution[firstPayment].price_TTC / 100 +
                        (driverUnder25 ? ins25Price : insBasePrice),
                    )}
                    €<span>{t("subscription.subscription-choice.ttc-month")}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="long-term-price-wrap">
            <div className="buttons-wrap particular">
              {marks
                .sort((a, b) => b.value - a.value)
                .map(({ value, label }, index) => (
                  <SelectButton
                    key={value + index}
                    className="button"
                    selected={value === firstPayment}
                    disabled={value === firstPayment}
                    onClick={handleMarkChange(value)}
                  >
                    <div>{t(`subscription.subscription-choice-button.particular.${value}`)}</div>
                    {label}
                  </SelectButton>
                ))}
            </div>
          </div>

          {(firstPayment || firstPayment === 0) && cf_contribution[firstPayment].subsidy && (
            <Notification
              t={t}
              text={"subscription.subscription-choice.eco-bonus-link"}
              dangerouslySetInnerHTML={true}
            />
          )}

          <div className="reduced-price">
            <h5>{t("subscription.subscription-choice.after-3-years")}</h5>

            <div>
              <p>{t("subscription.subscription-choice.renewable")}</p>
              <div className="price">
                {checkForIntegerToFixed(
                  Number(cf_price_year_4_5) / 100 + (driverUnder25 ? ins25Price : insBasePrice),
                )}
                €<span>{t("subscription.subscription-choice.ttc-month")}</span>
              </div>
            </div>
          </div>
        </div>
        <Insurance
          priceText={`+${cf_insurance_overcost}€/mois`}
          checked={driverUnder25}
          onChecked={setDriverUnder25}
        />
        <Addons />
        <h2>{t("subscription.subscription-choice.promo-codes")}</h2>
        <div className="divider" />
        <PromoCode />
        <h2>{t("subscription.subscription-choice.deposit")}</h2>
        <div className="divider" />
        <SecurityDeposit />
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsModalOpen(false)}
        PaperProps={{
          style: {
            margin: "17px",
          },
        }}
      >
        <ParticularSubscriptionDetailsContent
          handleClose={setIsModalOpen}
          handleSubmit={handleSubmit}
        />
      </Dialog>
      <Footer
        nextButtonId="particular-subscription-details"
        nextButtonHandler={() => setIsModalOpen(true)}
        prevButtonHandler={handlePreviousButtonClick}
      />
    </div>
  );
});

export default ParticularSubscriptionDetails;
