import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "store";
import { useDidMount, useDidUpdate, useQuery } from "hooks";
import { BuyerTypesKeys } from "models";
import { DEFAULT_PAGE } from "constants/routes.constants";
import { setScooterSetup } from "store";
import UserType from "./components/UserType";
import ScooterPower from "./components/ScooterPower";
import ScooterModel from "./components/ScooterModel";
import ScooterColor from "./components/ScooterColor";

type QueryType = {
  cf_buyer_profile?: BuyerTypesKeys | "";
  cf_power?: string;
  cf_model?: string;
  cf_color?: string;
};

const ScooterSetup: React.FC = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setQueryParam } = useQuery<QueryType>();

  const { cf_buyer_profile, cf_power, cf_model, cf_color } = useSelector(
    (state: RootState) => state.scooterSetup.setup,
  );

  const handleStepSelect = (): number => {
    if (cf_buyer_profile && cf_power && cf_model) return 3;
    if (cf_buyer_profile && cf_power) return 2;
    if (cf_buyer_profile) return 1;
    return 0;
  };

  const stepToShow = handleStepSelect();

  useDidUpdate(() => {
    if (stepToShow > 0 && window.location.hostname.match(/^app.zeway.com$/)) {
      if (!(cf_buyer_profile === "particular" || cf_buyer_profile === "professional")) {
        dispatch(
          setScooterSetup({
            cf_power: "",
            cf_color: "",
            cf_model: "",
            cf_buyer_profile: "",
          }),
        );

        history.push(DEFAULT_PAGE.path);
      }
    }
  }, [stepToShow]);

  useDidMount(() => {
    cf_buyer_profile && setQueryParam("cf_buyer_profile", cf_buyer_profile);
    cf_power && setQueryParam("cf_power", cf_power);
    cf_model && setQueryParam("cf_model", cf_model);
    cf_color && setQueryParam("cf_color", cf_color);
  });

  const mapStepToComponent: Record<number, React.FC> = {
    0: UserType,
    1: ScooterPower,
    2: ScooterModel,
    3: ScooterColor,
  };

  const Component = mapStepToComponent[stepToShow];

  return <Component />;
});

export default ScooterSetup;
