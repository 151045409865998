import { useReducer, useRef } from "react";

import { FetchMiddlewareReturnType, ErrorResponse, Status } from "api/api.middleware";
import { useDidUpdate, useDidUnmount } from "hooks";
import { QueryParam } from "./use-query.hook";

export type UseFetchReturnType<T> = {
  payload: T;
  loading: boolean;
  error: null | ErrorResponse;
  details: Status;
  actions: Actions;
  refresh: () => Promise<void>;
};

type UseFetchType = <R>(
  fn: () => FetchMiddlewareReturnType<R>,
  dependencies?: QueryParam[],
  disable?: boolean,
) => UseFetchReturnType<R>;

type State = { data: any; error: null | ErrorResponse; loading: boolean; details: Status };

type Actions = {
  setLoading: (loading: boolean) => void;
  setData: (data: any) => void;
  setError: (error: any) => void;
  setDetails: (details: any) => void;
};

type Action =
  | { type: "setLoading"; loading: boolean }
  | { type: "setData"; data: any }
  | { type: "setError"; error: null | ErrorResponse }
  | { type: "setDetails"; details: Status };

const initialState: State = {
  data: null,
  error: null,
  loading: true,
  details: { status: 0, isCanceled: false },
};

function reducer(state: any, action: Action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, loading: action.loading };
    case "setData":
      return { ...state, data: action.data };
    case "setError":
      return { ...state, error: action.error };
    case "setDetails":
      return { ...state, details: action.details };
  }
}

const useFetch: UseFetchType = (asyncApiCall, dependencies = [], disable = false) => {
  const requestIndexRef = useRef(0); // kinda crutch to fix request cannot be aborted now

  const componentIsMounted = useRef(true);
  useDidUnmount(() => (componentIsMounted.current = false));

  const [state, dispatch] = useReducer(reducer, { ...initialState, loading: !disable });
  const actions: Actions = {
    setLoading: (loading) => dispatch({ type: "setLoading", loading }),
    setData: (data) => dispatch({ type: "setData", data }),
    setError: (error) => dispatch({ type: "setError", error }),
    setDetails: (details) => dispatch({ type: "setDetails", details }),
  };

  const handleFetch = async () => {
    const { setLoading, setData, setError, setDetails } = actions;

    setLoading(true);
    setError(null);
    requestIndexRef.current++;

    const index = requestIndexRef.current;

    const [data, error, { status, isCanceled }] = await asyncApiCall();

    const isSameRequest = requestIndexRef.current === index;

    if (!componentIsMounted.current || !isSameRequest) return;

    if (isCanceled) return setLoading(false);

    if (error && status !== 0) {
      setError(error);
      setDetails({ status, isCanceled });
      setLoading(false);
      return;
    }

    setData(data);
    setDetails({ status, isCanceled });
    setLoading(false);
  };

  useDidUpdate(() => !disable && handleFetch(), dependencies, true);

  return {
    payload: state.data,
    loading: state.loading,
    error: state.error,
    details: state.details,
    actions,
    refresh: handleFetch,
  };
};

export default useFetch;
