import { handleActions } from "redux-actions";

import * as c from "../constants/account";

const initialState = {
  document: {
    isLoading: false,
    isError: false,
    documentsList: [],
  },
  subscription: {},
  subscriptionLoadingStatus: {
    pending: false,
    success: false,
    error: false,
  },
  contractStatus: "",
  customer: {
    email: "",
    subscriptions: [],
    chargebeeCustomer: {},
  },
  customerLoadingStatus: {
    pending: false,
    error: false,
    success: false,
  },
  updatePhoneLoadingStatus: {
    pending: false,
    error: false,
    success: false,
  },
};

const account = handleActions(
  new Map([
    [
      c.GET_DOCUMENTS_LIST,
      (state) => ({
        ...state,
        document: {
          ...state.document,
          isLoading: true,
        },
      }),
    ],
    [
      c.GET_DOCUMENTS_LIST_SUCCESS,
      (state, { payload }) => ({
        ...state,
        document: {
          ...state.document,
          isLoading: false,
          documentsList: [...payload],
        },
      }),
    ],
    [
      c.GET_DOCUMENTS_LIST_FAILURE,
      (state) => ({
        ...state,
        document: {
          ...state.document,
          isLoading: false,
          isError: true,
        },
      }),
    ],
    [
      c.GET_CUSTOMER_DATA,
      (state) => ({
        ...state,
        customerLoadingStatus: {
          ...state.customerLoadingStatus,
          pending: true,
          error: false,
          success: false,
        },
      }),
    ],
    [
      c.GET_CUSTOMER_DATA_SUCCESS,
      (state, { payload }) => ({
        ...state,
        customer: payload,
        customerLoadingStatus: {
          ...state.customerLoadingStatus,
          pending: false,
          error: false,
          success: true,
        },
      }),
    ],
    [
      c.GET_CUSTOMER_DATA_FAILURE,
      (state) => ({
        ...state,
        customer: {
          email: null,
          chargebeeCustomer: null,
          subscriptions: null,
        },
        customerLoadingStatus: {
          ...state.customerLoadingStatus,
          pending: false,
          error: true,
          success: false,
        },
      }),
    ],
    [
      c.GET_SUBSCRIPTION_DETAILS,
      (state) => ({
        ...state,
        subscriptionLoadingStatus: {
          pending: true,
          success: false,
          error: false,
        },
      }),
    ],
    [
      c.GET_SUBSCRIPTION_DETAILS_SUCCESS,
      (state, { payload }) => ({
        ...state,
        subscription: payload,
        subscriptionLoadingStatus: {
          pending: false,
          success: true,
          error: false,
        },
      }),
    ],
    [
      c.GET_SUBSCRIPTION_DETAILS_FAILURE,
      (state) => ({
        ...state,
        subscriptionLoadingStatus: {
          pending: false,
          success: false,
          error: true,
        },
      }),
    ],
    [
      c.GET_CONTRACT_STATUS_SUCCESS,
      (state, { payload }) => ({
        ...state,
        contractStatus: payload.status,
      }),
    ],
    [
      c.UPDATE_PHONE_NUMBER,
      (state) => ({
        ...state,
        updatePhoneLoadingStatus: {
          ...state.updatePhoneLoadingStatus,
          pending: true,
          success: false,
          error: false,
        },
      }),
    ],
    [
      c.UPDATE_PHONE_NUMBER_SUCCESS,
      (state) => ({
        ...state,
        updatePhoneLoadingStatus: {
          ...state.updatePhoneLoadingStatus,
          pending: false,
          success: true,
          error: false,
        },
      }),
    ],
    [
      c.UPDATE_PHONE_NUMBER_FAILURE,
      (state) => ({
        ...state,
        updatePhoneLoadingStatus: {
          ...state.updatePhoneLoadingStatus,
          pending: false,
          success: false,
          error: true,
        },
      }),
    ],
  ]),
  initialState,
);

export default account;
