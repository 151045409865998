import React, {memo, useEffect, useState} from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { withStyles, Menu as MaterialMenu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { slide as Menu } from "react-burger-menu";

import { useCookies, useWindowSize } from "hooks";
import { useAuth0 } from "Auth";
import { mapTitleToAccountPage } from "utils/mappers";
import { Stepper } from "./Stepper";
import { PriceDetailDropdown } from "./PriceDetailDropdown";
import { LoggedInHeader } from "./LoggedInHeader";

import MyInformation from "assets/images/account/Information personnelle.svg";
import UserImage from "assets/images/account/Information personnelle.svg";
import Logo from "assets/images/logo-zeway.svg";
import LogoMobile from "assets/images/logo_mobile.svg";
import LogoLight from "assets/images/logo-light.svg";
import fnacWebImg from "assets/images/store-code/fnac-web.svg";
import dartyWebImg from "assets/images/store-code/darty-web.svg";
import {
  AUTHORIZATION_PAGE,
  DEFAULT_PAGE,
  DOCUMENTS_PAGE,
  GENERAL_CONDITIONS_PAGE,
  PAYMENT_PAGE,
  PERSONAL_INFO_PAGE,
  SCOOTER_SETUP_PAGE,
  SCOOTER_SUBSCRIPTION_SETUP_PAGE,
} from "../../constants/routes.constants";
import i18n from "../../i18n";
import {baseUrlLocale, setLanguage} from "../../utils";
import moment from "moment/moment";

const isStepperPage = (pathname) => {
  const list = ["/scooter", "/subscription", "/billing", "/documents", "/contract"];
  return list.includes(pathname);
};

const StyledMenuItem = withStyles({
  root: {
    font: "20px Betm Rounded Medium",
    color: "#1E285A",
    "& > ul": {
      padding: "0",
      "&:not(:first-child)": {
        borderTop: "2px solid #00000029",
      },
    },
  },
})(MenuItem);

const supportedStoreCodes = {
  FNAC_WEB: fnacWebImg,
  DARTY_WEB: dartyWebImg,
};

const canShowSupporters = (pathname, setup) => {
  const stepsPathname = [
    DEFAULT_PAGE.path,
    SCOOTER_SETUP_PAGE.path,
    SCOOTER_SUBSCRIPTION_SETUP_PAGE.path,
  ];

  const { cf_buyer_profile, cf_model, cf_power } = setup;

  return stepsPathname.includes(pathname) && cf_buyer_profile && cf_model && cf_power;
};

const Header = memo(() => {
  const { isAuthenticated, logout } = useAuth0();
  const { cookies } = useCookies();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { width } = useWindowSize();
  const isInMobile = width <= 768;

  const { setup } = useSelector((state) => state.scooterSetup);
  const customer = useSelector((state) => state.account.customer);
  const { first_name } = customer.chargebeeCustomer || {};

  const storeCode = cookies["store_code"];
  const storeCodeImg = supportedStoreCodes[storeCode];
  const canShowStoreCodeSupporter = storeCodeImg && canShowSupporters(pathname, setup);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  useEffect(() => {
    if (customer?.countryCode) {
      const countryCodeLower = customer?.countryCode.toLowerCase() || setLanguage();
      i18n.changeLanguage(countryCodeLower)
      moment.locale(countryCodeLower);
      localStorage.setItem("countryCode", countryCodeLower);
    }
  }, [customer?.countryCode])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutUserHandler = () => {
    localStorage.removeItem("token");
    logout({ returnTo: baseUrlLocale()  });
  };

  const renderAccountPageTitle = () => {
    if (pathname === "/account") {
      return <div className={"account-title center"}>{t("my-account.header.my-account")}</div>;
    }

    if (mapTitleToAccountPage[pathname]) {
      return <div className={"account-title"}>{t(mapTitleToAccountPage[pathname])}</div>;
    }

    return null;
  };

  const handleBurgerMenuState = (state) => {
    setIsBurgerMenuOpen((prevState) => {
      const stateToSave = state !== "undefined" ? state : !prevState;

      // Hide scrollbar when menu is opened.
      const docStyle = document.body.style;
      docStyle.overflow = stateToSave ? "hidden" : docStyle.removeProperty("overflow");

      return stateToSave;
    });
  };

  const handleAccountRedirect = () => push("/account");

  const isShowPricesDropdown = () => {
    const stepsPathname = [
      PERSONAL_INFO_PAGE.path,
      GENERAL_CONDITIONS_PAGE.path,
      AUTHORIZATION_PAGE.path,
      PAYMENT_PAGE.path,
      DOCUMENTS_PAGE.path,
    ];

    return stepsPathname.includes(pathname);
  };

  const canShowAccountDropdownMenu = () => {
    return pathname.includes("/account") || pathname.includes("/contract");
  };

  const accountDropdownStyles = {
    display: canShowAccountDropdownMenu() ? "block" : "none",
  };

  return (
    <header className={cn({ account: pathname.includes("/account") })}>
      {pathname === "/account" && customer.chargebeeCustomer && (
        <div className="account-burger-wrap">
          <Menu
            onOpen={() => handleBurgerMenuState(true)}
            onClose={() => handleBurgerMenuState(false)}
            isOpen={isBurgerMenuOpen}
            customCrossIcon={<CloseIcon />}
            customBurgerIcon={<MenuIcon />}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t("my-account.header.home-link"),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t("my-account.header.swapping-link"),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t("my-account.header.total-link"),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t("my-account.header.propos-link"),
              }}
            />
            <div className="menu-divider" />
            {isAuthenticated && (
              <div className="clement">
                <img src={UserImage} alt="" />
                <span>{first_name}</span>
              </div>
            )}
            <span onClick={() => handleBurgerMenuState(false)}>
              {t("my-account.header.my-account")}
            </span>
            <button onClick={() => logoutUserHandler()}>{t("my-account.header.sign-out")}</button>
          </Menu>
        </div>
      )}
      {pathname.includes("/account") && customer.chargebeeCustomer && pathname !== "/account" ? (
        <Link className="back-wrap" to={"/account"}>
          <ArrowBackIosIcon />
        </Link>
      ) : null}
      {renderAccountPageTitle()}

      {!isInMobile && (
        <a href={baseUrlLocale()} className="logo-link" style={{ display: "flex" }}>
          <img src={Logo} alt="logo" className="logo" />
        </a>
      )}

      {!pathname.includes("/account") && isInMobile && (
        <a
          className="logo-mobile"
          href={baseUrlLocale()}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex" }}
        >
          <img src={LogoMobile} alt="" />
        </a>
      )}
      <Stepper />
      {canShowStoreCodeSupporter && (
        <div className={cn("storeCodeSupporterWrapper", { showBorder: !isInMobile })}>
          {!isInMobile && <div className="storeCodeSupporterText">En exclusivité avec</div>}
          <img className="storeCodeSupporter" src={storeCodeImg} alt="" />
        </div>
      )}

      {!window.location.hostname.match(/^app.zeway.com$/) && isAuthenticated && (
        <button
          onClick={() => logoutUserHandler()}
          style={{
            position: "fixed",
            top: "56px",
            background: "red",
            zIndex: 5,
            height: "40px",
          }}
        >
          Logout
        </button>
      )}
      {isShowPricesDropdown() ? (
        <PriceDetailDropdown />
      ) : pathname.includes("/account") && !isStepperPage(pathname) ? (
        <LoggedInHeader t={t} />
      ) : null}

      {pathname.includes("/account") ? (
        <img className="mobile-logo" alt="" src={LogoLight} />
      ) : null}

      {canShowAccountDropdownMenu() ? (
        <div className="desktop-dropdown" style={accountDropdownStyles}>
          <button aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
            <img src={MyInformation} alt="" />
            <KeyboardArrowDownIcon />
          </button>
          <MaterialMenu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <StyledMenuItem onClick={handleAccountRedirect}>
              {t("my-account.header.my-account")}
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                setAnchorEl(null);
                logoutUserHandler();
              }}
            >
              {t("my-account.header.sign-out")}
            </StyledMenuItem>
          </MaterialMenu>
        </div>
      ) : null}
    </header>
  );
});

export default Header;
