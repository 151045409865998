import React from "react";
import { useSelector } from "react-redux";
import countriesMultilang from "i18n-iso-countries";

import { MenuHoc } from "./MenuHoc.js";
import { Particular } from "./Particular";
import { Professional } from "./Professional";

countriesMultilang.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/es.json"));

const Personal = () => {
  const subscription = useSelector((state) => state.account.subscription);
  const { cf_buyer_profile } = subscription;
  const isCompany = cf_buyer_profile === "professional";

  return isCompany ? <Professional /> : <Particular />;
};

export default MenuHoc(Personal);
