export const titlesList = [
  {
    key: "subscription.personal-information.civility-mrs",
    value: "Mrs",
  },
  {
    key: "subscription.personal-information.civility-mr",
    value: "Mr",
  },
];
export const professionsList = [
  {
    value: "INSURANCE_AGENT",
    key: "subscription.professtional-information.insurance-agent",
  },
  {
    value: "PROFESSION_LIST",
    key: "subscription.professtional-information.profession-list",
  },
  {
    value: "ENTREPRENEUR",
    key: "subscription.professtional-information.enterpreneur",
  },
  {
    value: "STUDENT",
    key: "subscription.professtional-information.student",
  },
  {
    value: "CLASS_A_SERVANT",
    key: "subscription.professtional-information.class-a-servant",
  },
  {
    value: "OTHER_CATEGORY",
    key: "subscription.professtional-information.other-categoty",
  },
  {
    value: "SHOWMAN",
    key: "subscription.professtional-information.showman",
  },
  {
    value: "PROFESSIONAL_OR_MEDICAL",
    key: "subscription.professtional-information.professtional-or-medical",
  },
  {
    value: "RETIREMENT",
    key: "subscription.professtional-information.retirement",
  },
  {
    value: "EXECUTIVE_EMPLOYEE",
    key: "subscription.professtional-information.executive-employee",
  },
  {
    value: "SENIOR_EMPLOYEE",
    key: "subscription.professtional-information.senior-employee",
  },
  {
    value: "NOT_UNDER_WORKER",
    key: "subscription.professtional-information.not-under-worker",
  },
  {
    value: "NO_OCCUPATION",
    key: "subscription.professtional-information.no-occupation",
  },
  {
    value: "TAXI",
    key: "subscription.professtional-information.taxi",
  },
];

export const mapLabelToInputName = {
  lastName: "subscription.personal-information.last-name",
  firstName: "subscription.personal-information.first-name",
  street: "subscription.professtional-information.channel-name",
  complement: "subscription.personal-information.additional-address",
  postalCode: "subscription.professtional-information.postal-code",
  country: "subscription.professtional-information.country",
  city: "subscription.personal-information.city",
  workStreet: "subscription.professtional-information.channel-name",
  workComplement: "subscription.personal-information.additional-address",
  workPostalCode: "subscription.professtional-information.postal-code",
  workCity: "subscription.personal-information.city",
  workCountry: "subscription.professtional-information.country",
  proStreet: "subscription.professtional-information.channel-name",
  proComplement: "subscription.personal-information.additional-address",
  proPostalCode: "subscription.professtional-information.postal-code",
  proCity: "subscription.personal-information.city",
  proCountry: "subscription.professtional-information.country",
  company: "subscription.professinal-information.company",
  ape: "subscription.professinal-information.APE",
  siren: "subscription.professinal-information.company-siren",
  tva: "subscription.professinal-information.company-tva",
  driverLastName: "subscription.personal-information.last-name",
  driverFirstName: "subscription.personal-information.first-name",
  driverEmail: "subscription.professinal-information.driver-email",
};

export const mapHelperText = {
  civility: "subscription.professional-information.error.civility",
  firstName: "subscription.professional-information.error.firstName",
  lastName: "subscription.professional-information.error.secondName",
  street: "subscription.professional-information.error.street",
  postalCode: "subscription.professional-information.error.postalCode",
  country: "subscription.professional-information.error.country",
  complement: "subscription.professional-information.error.complement",
  city: "subscription.professional-information.error.city",
  profession: "subscription.professional-information.error.profession",
  driverEmail: "subscription.professional-information.error.email",
  proStreet: "subscription.professional-information.error.street",
  proPostalCode: "subscription.professional-information.error.postalCode",
  proCountry: "subscription.professional-information.error.country",
  proComplement: "subscription.professional-information.error.complement",
  proCity: "subscription.professional-information.error.city",
  workPostalCode: "subscription.professional-information.error.postalCode",
  company: "subscription.professional-information.error.company",
  tva: "subscription.professional-information.error.tva",
  siren: "subscription.professional-information.error.siren",
  ape: "subscription.professinal-information.help-APE",
};

export const relations = [
  {
    translation: "subscription.second-driver.filliation-joint",
    value: "spouse",
  },
  {
    translation: "subscription.second-driver.filliation-child",
    value: "child",
  },
];

export const inclusivesList = [
  "subscription.subscription-choice.swappable-battery",
  "subscription.subscription-choice.unlimited-disctance",
  "subscription.subscription-choice.unlimited-bateries",
  "subscription.subscription-choice.electicity",
  "subscription.subscription-choice.insurance-support",
  "subscription.subscription-choice.maintenance",
  "subscription.subscription-choice.application",
];
export const offersList = [
  "subscription.subscription-choice.top-case",
  "subscription.subscription-choice.skirt",
  "subscription.subscription-choice.holder",
  "subscription.subscription-choice.charging-cable",
];
export const offersPriceList = {
  "subscription.subscription-choice.top-case": "subscription.subscription-choice.amount1-offered",
  "subscription.subscription-choice.skirt": "subscription.subscription-choice.amount2-offered",
  "subscription.subscription-choice.holder": "subscription.subscription-choice.amount3-offered",
  "subscription.subscription-choice.charging-cable":
    "subscription.subscription-choice.amount4-offered",
};

export const mapTitleToAccountPage = {
  "/account/dashboard": "my-account.dashboard.dashboard",
  "/account/personal": "my-account.dashboard.personal-information",
  "/account/contract": "my-account.home.my-contract",
  "/account/bills": "my-account.home.my-bills",
  "/account/payment": "my-account.dashboard.payment-method",
  "/account/documents": "my-account.menu.documents",
  "/account/password": "my-account.menu.change-password",
};

export const mapScooterModelToTranslation = {
  swapperOne: "subscription.scooter-choice.swapper-one",
  F10: "subscription.scooter-choice.F10",
};

export const mapScooterColorToTranslation = {
  blue: "subscription.color-choice.blue",
  silver_grey: "subscription.color-choice.silver-gray",
  black: "subscription.color-choice.black",
};

export const mapPower = {
  45: "subscription.power-choice.50cc",
  80: "subscription.power-choice.125cc",
};
