import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";

import { useAuth0 } from "Auth";
import { setInsurance, setPersonalInfo, setSelectedAddons, setSubscriptionId } from "store";
import { createSubscription } from "api";
import { useCall, useDidUpdate } from "hooks";
import { GENERAL_CONDITIONS_PAGE } from "constants/routes.constants";
import { Notification } from "components/Notification/Notification";
import { DateOfBirth } from "components/Inputs/DateOfBirth";
import { PhoneInput } from "components/Inputs/PhoneInput";
import { Checkbox } from "components/Inputs/Checkbox";
import { Footer } from "components/Footer";
import { TitleSelect, Input } from "components/Second_Step/PersonalInfoForm";
import { CountrySelect } from "components/Inputs/CountryInput";
import { getCookiesData } from "../personal-info.utils";

const validationSchema = Yup.object({
  notDriverCheckbox: Yup.boolean(),
  workplaceIsNotOfTheCompany: Yup.boolean(),
  civility: Yup.string().required("civility is required"),
  lastName: Yup.string().required("lastName is required"),
  firstName: Yup.string().required("firstName is required"),
  company: Yup.string().required("company is required"),
  siren: Yup.string().required("siren is required"),
  tva: Yup.string().required("tva is required"),
  ape: Yup.string(),
  street: Yup.string().required("street is required"),
  complement: Yup.string(),
  postalCode: Yup.string().required("postalCode is required"),
  city: Yup.string().required("city is required"),
  country: Yup.string().required("country is required"),
  phone: Yup.string().min(7).required("phone is required"),
  countryCallingCode: Yup.string().required("countryCallingCode is required"),
  driverCivility: Yup.string(),
  driverLastName: Yup.string(),
  driverFirstName: Yup.string(),
  driverEmail: Yup.string().when("notDriverCheckbox", {
    is: true,
    then: Yup.string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .required("driverEmail is required"),
    otherwise: Yup.string(),
  }),
  dateOfBirth: Yup.string().when("notDriverCheckbox", {
    is: false,
    then: Yup.string().required("dateOfBirth is required"),
    otherwise: Yup.string(),
  }),
  dateOfBirthSecondDriver: Yup.string().when("notDriverCheckbox", {
    is: true,
    then: Yup.string().required("dateOfBirthSecondDriver is required"),
    otherwise: Yup.string(),
  }),
  proStreet: Yup.string().required("proStreet is required"),
  proComplement: Yup.string(),
  proPostalCode: Yup.string().required("proPostalCode is required"),
  proCity: Yup.string().required("proCity is required"),
  proCountry: Yup.string().required("proCountry is required"),
  workStreet: Yup.string(),
  workComplement: Yup.string(),
  workPostalCode: Yup.string(),
  workCity: Yup.string(),
  workCountry: Yup.string(),
});

const ProfessionalPersonalInfoForm = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth0();

  const { submit, submitting, onCallSuccess } = useCall(createSubscription);
  onCallSuccess(({ subscriptionId }) => {
    dispatch(setSubscriptionId(subscriptionId));
    history.push(GENERAL_CONDITIONS_PAGE.path);

    localStorage.removeItem("setup");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("paymentDetails");
  }, []);

  const addons = useSelector((state) => state.scooterData.addons);
  const { setup, selectedPlan, paymentDetails } = useSelector((state) => state.scooterSetup);
  const { id, cf_insurance_base_addon_id, cf_insurance_25_addon_id } = selectedPlan;

  const {
    selectedCouponsIds,
    selectedCoupons,
    firstPayment,
    insurance: { driverUnder25 },
    selectedAddons,
  } = paymentDetails;

  const isRiderOrSales = /(rider)|(sales)/i.test(id);

  const formik = useFormik({
    initialValues: {
      notDriverCheckbox: false,
      workplaceIsNotOfTheCompany: false,
      civility: "",
      lastName: "",
      firstName: "",
      company: "",
      siren: "",
      tva: "",
      ape: "",
      street: "",
      complement: "",
      postalCode: "",
      city: "",
      country: "FR",
      phone: "",
      countryCallingCode: "",
      driverCivility: "",
      driverLastName: "",
      driverFirstName: "",
      driverEmail: "",
      dateOfBirth: "",
      dateOfBirthSecondDriver: "",
      proStreet: "",
      proComplement: "",
      proPostalCode: "",
      proCity: "",
      proCountry: "FR",
      workStreet: "",
      workComplement: "",
      workPostalCode: "",
      workCity: "",
      workCountry: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        notDriverCheckbox,
        workplaceIsNotOfTheCompany,
        civility,
        lastName,
        firstName,
        company,
        siren,
        tva,
        ape,
        street,
        complement,
        postalCode,
        city,
        country,
        phone,
        countryCallingCode,
        driverCivility,
        driverLastName,
        driverFirstName,
        driverEmail,
        dateOfBirth,
        dateOfBirthSecondDriver,
        proStreet,
        proComplement,
        proPostalCode,
        proCity,
        proCountry,
        workStreet,
        workComplement,
        workPostalCode,
        workCity,
        workCountry,
      } = values;

      const addonsAndQuantity = {};
      Object.entries(selectedAddons).forEach(([id, { count }]) => {
        if (count < 1) return;
        addonsAndQuantity[id] = count;
      });

      if (
        moment().diff(dateOfBirth, "years") < 25 ||
        moment().diff(dateOfBirthSecondDriver, "years") < 25
      ) {
        addonsAndQuantity["surprime-jeune-conducteur"] = 1;
      }

      const enteredCoupons = selectedCoupons.length ? [selectedCoupons[0].id] : [];
      const couponIds = enteredCoupons.concat(selectedCouponsIds);

      const data = {
        ...getCookiesData(),
        planId: selectedPlan.id,
        scooterColor: setup.cf_color,
        addonsAndQuantity,
        couponIds,
        BONUS: firstPayment * 100,
        civility,
        firstName,
        lastName,
        company,
        siren,
        tva,
        ape,
        street,
        complement,
        postalCode,
        city,
        country,
        phone: `+${countryCallingCode}${phone}`,
        driverCivility: notDriverCheckbox && driverCivility ? driverCivility : civility,
        driverFirstName: notDriverCheckbox && driverFirstName ? driverFirstName : firstName,
        driverLastName: notDriverCheckbox && driverLastName ? driverLastName : lastName,
        driverEmail: notDriverCheckbox && driverEmail ? driverEmail : user.email,
        dob: notDriverCheckbox
          ? moment(dateOfBirthSecondDriver).format("YYYY-MM-DD")
          : moment(dateOfBirth).format("YYYY-MM-DD"),
        driverDob: notDriverCheckbox
          ? moment(dateOfBirthSecondDriver).format("YYYY-MM-DD")
          : moment(dateOfBirth).format("YYYY-MM-DD"),
        proStreet,
        proComplement,
        proPostalCode,
        proCity,
        proCountry,
        workStreet: workplaceIsNotOfTheCompany && workStreet ? workStreet : street,
        workComplement: workplaceIsNotOfTheCompany && workComplement ? workComplement : complement,
        workPostalCode: workplaceIsNotOfTheCompany && workPostalCode ? workPostalCode : postalCode,
        workCity: workplaceIsNotOfTheCompany && workCity ? workCity : city,
        workCountry: workplaceIsNotOfTheCompany && workCountry ? workCountry : country,
      };

      // if (notDriverCheckbox) {
      //   if (driverCivility) data.driverCivility = driverCivility;
      //   if (driverFirstName) data.driverFirstName = driverFirstName;
      //   if (driverLastName) data.driverLastName = driverLastName;
      //   if (driverEmail) data.driverEmail = driverEmail;
      //   if (dateOfBirthSecondDriver)
      //     data.driverDob = moment(dateOfBirthSecondDriver).format("YYYY-MM-DD");
      // }

      dispatch(setPersonalInfo(data));
      submit(data);
    },
  });

  const { dateOfBirth, dateOfBirthSecondDriver, notDriverCheckbox, workplaceIsNotOfTheCompany } =
    formik.values;

  // Insurance addons handle.
  useDidUpdate(() => {
    if (driverUnder25 || !addons.length) return;

    // Year difference between current date and entered date.
    const yearEntered = moment().diff(dateOfBirth, "years");

    const birthdateUnder25 = yearEntered < 25;

    const addonToCheck = birthdateUnder25 ? cf_insurance_25_addon_id : cf_insurance_base_addon_id;
    const oppositeAddonToCheck = birthdateUnder25
      ? cf_insurance_base_addon_id
      : cf_insurance_25_addon_id;

    const foundAddon = addons.find((addon) => addon.id === addonToCheck);
    const shouldAdd = foundAddon && !selectedAddons[foundAddon.id];
    if (!shouldAdd || !foundAddon) return;
    const { id, price } = foundAddon;

    dispatch(
      setSelectedAddons({
        [id]: { count: 1, price },
        [oppositeAddonToCheck]: { count: 0, price },
      }),
    );

    dispatch(setInsurance({ birthdateUnder25 }));
  }, [dateOfBirth, addons]);

  function validatePostalCodeOnBlur(name) {
    let countryToCheck = "country";
    if (name === "proPostalCode") {
      countryToCheck = "proCountry";
    }
    if (name === "workPostalCode") {
      countryToCheck = "workCountry";
    }

    const value = formik.values[name];

    // If country is not France OR postal code value is empty -> clear error and return.
    if (formik.values[countryToCheck] !== "FR" || !value) {
      formik.setFieldTouched(name, false);
      formik.validateField(name);
      return;
    }

    // If country is France AND postal code value length isn't equal 5 symbols -> set error and return.
    if (value.length !== 5) {
      // Fix weird bug when you set error when already has one, error for some reason disappears.
      // So if we have error already established do not set new one and only return.
      if (!Boolean(formik.errors[name])) {
        formik.setFieldTouched(name, true);
        formik.setFieldError(name, "Field doesn't pass validation");
      }
      return;
    }
    // Everything is ok -> clear error.
    formik.setFieldTouched(name, false);
    formik.validateField(name);
  }

  function validatePostalCodeOnCountryChange(name, countryValue) {
    let postalCodeToValidate = "postalCode";
    if (name === "proCountry") {
      postalCodeToValidate = "proPostalCode";
    }
    if (name === "workCountry") {
      postalCodeToValidate = "workPostalCode";
    }

    const postalCodeValue = formik.values[postalCodeToValidate];

    // If country is not France OR postal code value is empty -> clear error and return.
    if (countryValue !== "FR" || !postalCodeValue) {
      formik.setFieldTouched(postalCodeToValidate, false);
      return;
    }

    // If country is France AND postal code value length isn't equal 5 symbols -> set error and return.
    if (postalCodeValue.length !== 5) {
      formik.setFieldError(postalCodeToValidate, "Field doesn't pass validation");
      formik.setFieldTouched(postalCodeToValidate, true);
    }
  }

  return (
    <div className="common-wrap">
      <div className="personal-form-wrap">
        <div className="required-wrapper">
          <span className="required">
            <span>*</span>
            {t("subscription.personal-information")}
          </span>
        </div>
        <div className="header">{t("subscription.buyer-type.company")}</div>
        <div className="divider" />
        <form onSubmit={formik.handleSubmit} autoComplete={"on"}>
          <h4>
            <span>*</span>
            {t("subscription.buyer-type.representative")}
          </h4>
          <div className="surname-wrap">
            <TitleSelect
              t={t}
              formik={formik}
              name="civility"
              error={(formik.touched.civility && Boolean(formik.errors.civility)) || false}
            />
            <Input
              t={t}
              formik={formik}
              name="lastName"
              error={(formik.touched.lastName && Boolean(formik.errors.lastName)) || false}
            />
          </div>
          <Input
            t={t}
            formik={formik}
            name="firstName"
            error={(formik.touched.firstName && Boolean(formik.errors.firstName)) || false}
          />

          <h4>
            <span>*</span>
            {t("subscription.buyer-type.your-society")}
          </h4>
          <Input
            t={t}
            formik={formik}
            name="company"
            error={(formik.touched.company && Boolean(formik.errors.company)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="siren"
            error={(formik.touched.siren && Boolean(formik.errors.siren)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="tva"
            error={(formik.touched.tva && Boolean(formik.errors.tva)) || false}
          />

          <h4>{t("subscription.buyer-type.ape-code")}</h4>
          <Input
            t={t}
            formik={formik}
            name="ape"
            error={(formik.touched.ape && Boolean(formik.errors.ape)) || false}
          />

          <h4>
            <span>*</span>
            {t("subscription.buyer-type.company-address")}
          </h4>
          <Input
            t={t}
            formik={formik}
            name="street"
            error={(formik.touched.street && Boolean(formik.errors.street)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="complement"
            error={(formik.touched.complement && Boolean(formik.errors.complement)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="postalCode"
            error={(formik.touched.postalCode && Boolean(formik.errors.postalCode)) || false}
            onBlurMethod={validatePostalCodeOnBlur}
          />
          <Input
            t={t}
            formik={formik}
            name="city"
            error={(formik.touched.city && Boolean(formik.errors.city)) || false}
          />
          <CountrySelect
            t={t}
            formik={formik}
            name="country"
            error={(formik.touched.country && Boolean(formik.errors.country)) || false}
            onChange={validatePostalCodeOnCountryChange}
          />

          <h4>
            <span>*</span>
            {t("subscription.personal-information.phone-question")}
          </h4>
          <PhoneInput
            t={t}
            onValidPhone={(countryCode, phoneNumber) => {
              formik.setFieldValue("phone", phoneNumber, true);
              formik.setFieldValue("countryCallingCode", countryCode, true);
              formik.setFieldTouched("phone", false);
              formik.setFieldTouched("countryCallingCode", false);
            }}
            clearPhone={() => {
              formik.setFieldValue("phone", "");
              formik.setFieldValue("countryCallingCode", "");
              formik.setFieldTouched("phone", false);
              formik.setFieldTouched("countryCallingCode", false);
            }}
            onError={() => {
              formik.setFieldValue("phone", "", true);
              formik.setFieldValue("countryCallingCode", "", true);
              formik.setFieldTouched("phone", true);
              formik.setFieldTouched("countryCallingCode", true);
            }}
            error={(formik.touched.phone && Boolean(formik.errors.phone)) || false}
          />

          <div className="header indent-30">{t("subscription.buyer-type.driver-title")}</div>
          <div className="divider" />
          <Checkbox
            checked={notDriverCheckbox}
            onChange={(e) => formik.setFieldValue("notDriverCheckbox", e.target.checked)}
          >
            {t("subsription.buyer-type.not-driver-checkbox")}
          </Checkbox>

          {notDriverCheckbox && (
            <>
              <div className="surname-wrap">
                <TitleSelect
                  t={t}
                  formik={formik}
                  name="driverCivility"
                  error={
                    (formik.touched.driverCivility && Boolean(formik.errors.driverCivility)) ||
                    false
                  }
                />
                <Input
                  t={t}
                  formik={formik}
                  name="driverLastName"
                  error={
                    (formik.touched.driverLastName && Boolean(formik.errors.driverLastName)) ||
                    false
                  }
                />
              </div>
              <Input
                t={t}
                formik={formik}
                name="driverFirstName"
                error={
                  (formik.touched.driverFirstName && Boolean(formik.errors.driverFirstName)) ||
                  false
                }
              />

              <h4>
                <span>*</span>
                {t("subsription.buyer-type.driver-email")}
              </h4>
              <Input
                t={t}
                formik={formik}
                name="driverEmail"
                error={(formik.touched.driverEmail && Boolean(formik.errors.driverEmail)) || false}
              />
            </>
          )}

          <h4>
            <span>*</span>
            {t(
              notDriverCheckbox
                ? "subscription.personal-information.driver-date-of-birth-question"
                : "subscription.personal-information.date-of-birth-question",
            )}
          </h4>
          <DateOfBirth
            t={t}
            onValidDate={(date) => {
              /**
               * Fix issue when you check that you are not driver when date entered, it's shown that date isn't valid
               * but there is date (and vice versa).
               * So when setting date set it to both field.
               */
              formik.setFieldValue("dateOfBirth", date, true);
              formik.setFieldValue("dateOfBirthSecondDriver", date, true);
            }}
            onClear={() => {
              formik.setFieldValue("dateOfBirth", "");
              formik.setFieldValue("dateOfBirthSecondDriver", "");
            }}
            onError={() => {
              formik.setFieldValue("dateOfBirth", "");
              formik.setFieldValue("dateOfBirthSecondDriver", "");
            }}
            error={
              notDriverCheckbox
                ? (formik.touched.dateOfBirthSecondDriver &&
                    Boolean(formik.errors.dateOfBirthSecondDriver)) ||
                  false
                : (formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)) || false
            }
          />
          {moment().diff(notDriverCheckbox ? dateOfBirthSecondDriver : dateOfBirth, "years") < 25 &&
            !driverUnder25 &&
            !isRiderOrSales && (
              <Notification t={t} text={"subscription.personal-information.under-25-text"} />
            )}

          <h4>
            <span>*</span>
            {t(
              notDriverCheckbox
                ? "subscription.professional-information.isnot-driver-address"
                : "subscription.personal-information.address_question",
            )}
          </h4>
          <Input
            t={t}
            formik={formik}
            name="proStreet"
            error={(formik.touched.proStreet && Boolean(formik.errors.proStreet)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="proComplement"
            error={(formik.touched.proComplement && Boolean(formik.errors.proComplement)) || false}
          />
          <Input
            t={t}
            formik={formik}
            name="proPostalCode"
            error={(formik.touched.proPostalCode && Boolean(formik.errors.proPostalCode)) || false}
            onBlurMethod={validatePostalCodeOnBlur}
          />
          <Input
            t={t}
            formik={formik}
            name="proCity"
            error={(formik.touched.proCity && Boolean(formik.errors.proCity)) || false}
          />
          <CountrySelect
            t={t}
            formik={formik}
            name="proCountry"
            error={(formik.touched.proCountry && Boolean(formik.errors.proCountry)) || false}
            onChange={validatePostalCodeOnCountryChange}
          />

          <Checkbox
            checked={workplaceIsNotOfTheCompany}
            onChange={(e) => formik.setFieldValue("workplaceIsNotOfTheCompany", e.target.checked)}
          >
            {t(
              notDriverCheckbox
                ? "subscription.professional-information.isnot-driver-workplace-checkbox"
                : "subscription.professional-information.is-driver-workplace-checkbox",
            )}
          </Checkbox>
          {workplaceIsNotOfTheCompany && (
            <>
              {notDriverCheckbox && (
                <h4>{t("subscription.professional-information.isnot-driver-workplace")}</h4>
              )}
              {workplaceIsNotOfTheCompany && !notDriverCheckbox && (
                <h4>{t("subscription.professional-information.is-driver-workplace")}</h4>
              )}
              <Input
                t={t}
                formik={formik}
                name="workStreet"
                error={(formik.touched.workStreet && Boolean(formik.errors.workStreet)) || false}
              />
              <Input
                t={t}
                formik={formik}
                name="workComplement"
                error={
                  (formik.touched.workComplement && Boolean(formik.errors.workComplement)) || false
                }
              />
              <Input
                t={t}
                formik={formik}
                name="workPostalCode"
                error={
                  (formik.touched.workPostalCode && Boolean(formik.errors.workPostalCode)) || false
                }
                onBlurMethod={validatePostalCodeOnBlur}
              />
              <Input
                t={t}
                formik={formik}
                name="workCity"
                error={(formik.touched.workCity && Boolean(formik.errors.workCity)) || false}
              />
              <CountrySelect
                t={t}
                formik={formik}
                name="workCountry"
                error={(formik.touched.workCountry && Boolean(formik.errors.workCountry)) || false}
                onChange={validatePostalCodeOnCountryChange}
              />
            </>
          )}
          <Footer
            nextButtonId="professional-personal-info-form"
            type="submit"
            isLoading={submitting}
          />
        </form>
      </div>
    </div>
  );
});

export default ProfessionalPersonalInfoForm;
