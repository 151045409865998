import React from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";

import { routes, RouteComponent } from "components/client/routes";
import { useAuth0 } from "Auth";
import { getPlansAndAddons } from "api";
import {
  setPaymentDetails,
  setPlansAndAddons,
  setScooterSetup,
  setSelectedAddons,
  setSelectedPlan,
} from "store";
import { isEqual, isJSONFormat, toArrayOptions, isEmpty } from "utils";
import { DEFAULT_PAGE, SCOOTER_SUBSCRIPTION_SETUP_PAGE } from "constants/routes.constants";
import { useSetUTMCookies, useStoreCode, useFetch, useDidUpdate, useQuery } from "hooks";
import Header from "./components/Header";

console.log('[app is running][j]');
const App = () => {
  useSetUTMCookies();
  useStoreCode();

  const history = useHistory();

  const { query, setQueryParam } = useQuery();

  const translation = useTranslation("", { useSuspense: false });

  const dispatch = useDispatch();

  const auth0 = useAuth0();

  const { payload, loading } = useFetch(getPlansAndAddons);

  useDidUpdate(() => {
    if (!payload) return;
    dispatch(setPlansAndAddons(payload));
  }, [payload]);

  useDidUpdate(() => {
    let data = query.data;

    if (!data || !isJSONFormat(data) || auth0.loading || !auth0.isAttemptedToLogin || !payload) {
      return;
    }

    setQueryParam("data", "");

    data = JSON.parse(data);

    const {
      cf_buyer_profile,
      cf_power,
      cf_model,
      cf_color,
      cf_contribution,
      cf_bonus,
      cf_buyer_under_25 = false,
      coupons_id = [], // string | string[]
      applicable_addons = [],
      id,
    } = data;

    if (cf_contribution && cf_bonus) {
      console.error(
        "Both 'cf_contribution' and 'cf_bonus' passed. Ensure you pass only one of them.",
      );
      return;
    }

    const contrib = cf_contribution || cf_bonus;
    const contribType = cf_contribution ? "contribution" : cf_bonus ? "bonus" : null;

    if (isEmpty(contrib) || !id || !contribType) {
      console.error(
        "You have passed either empty ('contribution' || 'cf_bonus') or plan 'id'. Ensure you pass valid contribution and plan id",
      );
      return;
    }

    const [firstPayment, { subsidy }] = Object.entries(contrib)[0];

    const filteredPlans = payload.plans.filter(
      (plan) => plan.cf_buyer_profile === cf_buyer_profile,
    );

    const selectedPlan = filteredPlans.find((plan) => {
      const sameBuyer = plan.cf_buyer_profile === cf_buyer_profile;
      if (id !== plan.id || !sameBuyer) return;

      const samePower = Number(plan.cf_power) === Number(cf_power);
      const sameModel = plan.cf_scooter_model === cf_model;
      const sameColor = plan.meta_data.scooter_colors.find((c) => isEqual(c.name, cf_color));

      const contrib = plan.meta_data.contribution || plan.meta_data.BONUS || {};

      const validContribution = contrib[firstPayment];

      return sameBuyer && samePower && sameModel && sameColor && validContribution;
    });

    if (!selectedPlan) {
      console.error(
        `
          You have mismatched 
          'cf_buyer_profile' | 'cf_power' | 'cf_model' | 'cf_color' | ('contribution' || 'bonus')
          with available plans.
          Ensure you pass valid data with plan configurations
        `,
      );
      return;
    }

    dispatch(
      setScooterSetup({
        cf_buyer_profile,
        cf_power,
        cf_model,
        cf_color,
        cf_contribution: contrib,
        cf_contribution_type: contribType,
      }),
    );
    dispatch(setSelectedPlan(selectedPlan));
    dispatch(
      setPaymentDetails({
        firstPayment: Number(firstPayment),
        selectedCouponsIds: toArrayOptions(coupons_id || []),
      }),
    );

    const addons = applicable_addons.reduce(
      (acc, { id, price, count }) => ({ ...acc, [id]: { price, count } }),
      {},
    );
    dispatch(setSelectedAddons(addons));

    history.push(SCOOTER_SUBSCRIPTION_SETUP_PAGE.path, {
      cf_buyer_under_25,
      subsidy,
    });
  }, [auth0.loading, auth0.isAttemptedToLogin, query, payload]);

  if (!translation.ready || !auth0.isAttemptedToLogin || auth0.loading || loading) {
    return (
      <div className="App loading">
        <CircularProgress size={59.5} />
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <div className="App-content">
        <Switch>
          {routes.map((route) => (
            <RouteComponent key={route.path} {...route} />
          ))}
          <Redirect to={DEFAULT_PAGE.path} />
        </Switch>
      </div>
      {/*<CookieAcceptanceModal />*/}
    </div>
  );
};

export default App;
