import { call, put, select } from "redux-saga/effects";

import * as a from "store/actions/user";
import { request } from "utils/request";

const addSecondDriver = (data, subscriptionId) => {
  return request(`/subscriptions/${subscriptionId}/second-driver`, "PUT", {
    body: JSON.stringify({ ...data, relation: "child" }),
  });
};

export function* addSecondDriverSaga({ payload }) {
  try {
    const subscriptionId = yield select((state) => state.user.subscriptionId);
    yield call(addSecondDriver, payload, subscriptionId);
    yield put(a.addSecondDriverSuccess());
    // yield put(nextActiveStep());
  } catch (error) {
    yield put(a.addSecondDriverFailure(error));
  }
}
