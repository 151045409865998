import { useEffect } from "react";

type CallbackType = () => void;

type UseDidUnmount = (f: CallbackType) => any;

const useDidUnmount: UseDidUnmount = (f) =>
  useEffect(
    () => () => {
      const rT = (f() as any) || "";
      if (rT && rT.constructor.name === "Function") {
        return rT;
      }
    },
    [],
  );

export default useDidUnmount;
