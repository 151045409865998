import { ArrayElement } from "../models";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type FunctionType<P extends any[] = any[], R = any> = (...arg: P) => R;

export const isFunction = (value: any): value is FunctionType => {
  return value && {}.toString.call(value) === "[object Function]";
};

export const arrayOf = <T>(length: number, iteratee: ((index: number) => T) | T): T[] => {
  if (!isFunction(iteratee)) return Array(length).fill(iteratee);
  return Array.from({ length }, (_, index) => iteratee(index));
};

export const isEmpty = (v: any): boolean => {
  if (typeof v === "function") return isEmpty(v()); // functions

  if (!Boolean(v)) return true; // null, undefined, 0, false, ""

  if (Array.isArray(v)) return !v.length; // arrays

  if (typeof v === "object") return !Object.keys(v).length; // objects

  return false;
};

export const isEqual = (v1: any, v2: any): boolean => {
  const v1Type = Object.prototype.toString.call(v1);
  const v2Type = Object.prototype.toString.call(v2);

  if (v1Type !== v2Type) return false; // type check

  if (typeof v1 === "function") return "" + v1 === "" + v2; // functions

  // null, undefined, string, number, bool, NaN
  if (v1Type !== "[object Object]" && v1Type !== "[object Array]") {
    if (v1Type === "[object Number]" && isNaN(v1) && isNaN(v2)) return true;
    return v1 === v2;
  }

  // arrays
  if (Array.isArray(v1) && Array.isArray(v2)) {
    if (v1.length !== v2.length) return false;

    if (!v1.length && !v2.length) return true;

    return v1.every((v1El, i) => isEqual(v1El, v2[i]));
  }

  // objects
  if (isEmpty(v1) && isEmpty(v2)) return true;
  if (Object.keys(v1).length !== Object.keys(v2).length) return false;

  return Object.entries(v1).every(
    ([key, value]) => v2.hasOwnProperty(key) && isEqual(value, v2[key]),
  );
};

// Shallow clone, (it's not deep cloning)
export const clone = <T = any>(toClone: T): T => {
  if (!toClone || typeof toClone !== "object") return toClone;
  if (Array.isArray(toClone)) return [...toClone] as any as T;
  return { ...toClone };
};

export const uniqBy = <T extends Array<Record<string | number, any>>>(
  array: T,
  field: keyof ArrayElement<T>,
): T => {
  const uniqArr = new Map<keyof ArrayElement<T>, ArrayElement<T>>();
  array.forEach((el) => uniqArr.set(el[field as keyof typeof el], el as ArrayElement<T>));
  return [...uniqArr.values()] as unknown as T;
};

export const toArrayOptions = <T>(value: T | T[]): T[] => (Array.isArray(value) ? value : [value]);

export const isJSONFormat = (value: any): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};
