import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState, ScooterSetupState, setSelectedAddons } from "store";
import { NonNullableKeys } from "models";

const addonImageUrl = "https://d2yjuj8b4m1iq2.cloudfront.net/images/addons/##addon##/addon.png";

type AddonsProps = {
  isPro?: boolean;
};

export const Addons: React.FC<AddonsProps> = memo(({ isPro = false }: AddonsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addons = useSelector((state: RootState) => state.scooterData.addons);

  const { selectedPlan, paymentDetails } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const { selectedAddons } = paymentDetails;

  const handleAddonCountChange = (
    addonId: string,
    count: number,
    price: number,
    priceHT: number,
  ) => {
    const countToSet = Math.max(0, count);

    const skipSet = (selectedAddons[addonId]?.count || 0) === countToSet;
    if (skipSet) return;

    dispatch(
      setSelectedAddons({
        [addonId]: { count: countToSet, price, priceHT },
      }),
    );
  };

  const handleAddonDecrement = (addonId: string, price: number, priceHT: number) => () => {
    const countToSet = (selectedAddons[addonId]?.count || 0) - 1;
    handleAddonCountChange(addonId, countToSet, price, priceHT);
  };

  const handleAddonIncrement = (addonId: string, price: number, priceHT: number) => () => {
    const countToSet = (selectedAddons[addonId]?.count || 0) + 1;
    handleAddonCountChange(addonId, countToSet, price, priceHT);
  };

  const applicableAddons = selectedPlan.applicable_addons
    ? selectedPlan.applicable_addons.map((item) => item.id)
    : [];

  // Check if there is at least 1 suitable addon.
  const shouldShow = addons.some(({ id, cf_display_as_option }) => {
    return !(
      cf_display_as_option === "false" ||
      id === "pro_addition_driver" ||
      !applicableAddons.includes(id)
    );
  });

  if (!shouldShow) return null;

  return (
    <div className="addons-wrap">
      <h2>{t("subscription.subscription-choice.options")}</h2>
      <div className="divider" />

      <div className="addons-wrap-list">
        {addons.map(({ cf_addon_price_ht, price, id, cf_display_as_option }, i) => {
          if (
            cf_display_as_option === "false" ||
            id === "pro_addition_driver" ||
            !applicableAddons.includes(id)
          )
            return null;

          return (
            <div key={i} className="addon">
              <img className="addon-image" src={addonImageUrl.replace("##addon##", id)} alt="" />
              <div className="addon-content">
                <h6>{t(`subscription.subscription-choice.${id}`)}</h6>
                <p>{t(`subscription.subscription-choice.description.${id}`)}</p>
              </div>
              {isPro ? (
                <div className="addon-price">
                  <div className="addon-price-ht-wrap">
                    <span className="addon-price-ht">{+cf_addon_price_ht / 100}€&nbsp;</span>
                    &nbsp;HT
                  </div>
                  <div className="addon-price-ttc-wrap">
                    <span className="addon-price-ttc">{price / 100}€</span>&nbsp;TTC
                  </div>
                </div>
              ) : (
                <div className="addon-price">{price / 100}€</div>
              )}
              <div className="addon-count">
                <button onClick={handleAddonDecrement(id, price, +cf_addon_price_ht)}>-</button>
                <input
                  type="number"
                  value={selectedAddons[id]?.count || 0}
                  min={0}
                  onChange={(e) =>
                    handleAddonCountChange(id, +e.target.value, price, +cf_addon_price_ht)
                  }
                />
                <button onClick={handleAddonIncrement(id, price, +cf_addon_price_ht)}>+</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
