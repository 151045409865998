import fetchMiddleware from "api/api.middleware";
import { UserPaymentDataModel } from "models";
import { PutPaymentDataType } from "./payment.interface";

export const getPaymentData = (subscriptionId: string) =>
  fetchMiddleware<UserPaymentDataModel>({
    method: "get",
    url: `/subscriptions/${subscriptionId}/customer`,
  });

export const putPaymentToAccount = (subscriptionId: string, data: PutPaymentDataType) =>
  fetchMiddleware({
    method: "put",
    url: `/subscriptions/${subscriptionId}/bank-account-details`,
    data,
  });

export const putCustomerPayment = () =>
  fetchMiddleware<{ url: string }>({
    method: "put",
    url: "/customers/payment",
  });

export const putPaymentAppendix = (subscriptionId: string) =>
  fetchMiddleware({
    method: "put",
    url: `/subscriptions/${subscriptionId}/send-appendix`,
  });
