import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

export type QueryParam =
  | string
  | (string | number | boolean)[]
  | number
  | boolean
  | null
  | undefined;

export interface QueryParams {
  [key: string]: QueryParam | undefined;
}

const options: any = {
  arrayFormat: "bracket",
  skipEmptyString: true,
};

export type UseQueryReturnType<T> = {
  query: T;
  search: string;
  setQueryParam: (param: keyof T, value: T[typeof param]) => void;
  setQueryParams: (value: T) => void;
  updateQueryParams: (values: T) => void;
  stringify: (query: T) => string;
};

export function useQuery<T = QueryParams>(): UseQueryReturnType<T> {
  const history = useHistory();
  const location = useLocation();

  const search = location.search;

  const query = queryString.parse(search, options);

  function setQueryParams(value: T) {
    const stringifiedValue = queryString.stringify(value, options);

    history.replace({
      search: stringifiedValue,
    });
  }

  function setQueryParam<D extends keyof T>(param: D, value: T[D]) {
    const newQuery: any = { ...query };

    newQuery[param] = value;

    const stringifiedValue = queryString.stringify(newQuery, options);

    history.replace({
      search: stringifiedValue,
    });
  }

  function updateQueryParams(values: Partial<T>) {
    const newQuery = { ...query, ...values };

    const stringifiedValue = queryString.stringify(newQuery, options);

    history.replace({
      search: stringifiedValue,
    });
  }

  function stringify(query: T | QueryParams): string {
    return "?" + queryString.stringify(query, options);
  }

  return {
    query,
    search,
    setQueryParams,
    setQueryParam,
    updateQueryParams,
    stringify,
  };
}

export default useQuery;
