import { useDidMount } from "hooks";
import { pushDataLayerEvent } from "utils";

export const useGtmEmail = (email: string): void => {
  useDidMount(() => {
    if (!email) return;

    const find = window.dataLayer?.find((item: any) => item.step === `${email}`);

    if (!find) {
      pushDataLayerEvent({
        event: "register_or_login",
        email,
      });
    }
  });
};

export default useGtmEmail;
