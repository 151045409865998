import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import countriesMultilang from "i18n-iso-countries";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import { setLanguage } from "utils";
import { updatePhoneNumber } from "store/actions";
import { PhoneInputPlain } from "../Inputs/PhoneInputPlain";
import { AccountItemInfo } from "./common/AccountInfoItem";

import EditIcon from "assets/images/icons/picto_edit.svg";
import CrossIcon from "assets/images/icons/cross.svg";
import OkIcon from "assets/images/icons/ok.svg";

export const Professional = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const customer = useSelector((state) => state.account.customer);
  const customerLoadingStatus = useSelector((state) => state.account.customerLoadingStatus);
  const updatePhoneLoadingStatus = useSelector((state) => state.account.updatePhoneLoadingStatus);

  const {
    cf_company_siren = "",
    cf_company_tva_code = "",
    cf_company_ape = "",
    cf_civility = "",
    billing_address = {},
    first_name = "",
    last_name = "",
    company = "",
    cf_birth_date = "",
    phone = "",
    email = "",
    cf_second_driver_civility = "",
    cf_second_driver_last_name = "",
    cf_second_driver_fist_name = "",
    cf_birth_date_second_driver = "",
    // cf_pro_residence_country='',
    cf_pro_residence_city = "",
    cf_pro_residence_street_name_and_number = "",
    cf_pro_residence_postal_code = "",
    cf_pro_1st_driver_civility = "",
    cf_pro_birth_date_first_driver = "",
    cf_pro_1st_driver_email = "",
    cf_pro_1st_driver_first_name = "",
    cf_pro_1st_driver_last_name = "",
  } = customer.chargebeeCustomer || {};

  const [isEditPhone, setIsEditPhone] = useState(false);
  const [phoneToShow, setPhoneToShow] = useState(!phone.startsWith("+") ? `+${phone}` : phone);
  const [phoneInput, setPhoneInput] = useState(phone);
  const [isPhoneError, setIsPhoneError] = useState(false);

  const inputProps = {
    inputStyle: {
      border: isPhoneError ? "1px solid #f44336" : "1px solid #1E285A",
      borderRadius: "4px",
      height: "50px",
      outline: "none",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
      color: "#1E285A",
    },
  };

  const isFirstDriverExists = !!(cf_pro_1st_driver_last_name && cf_pro_1st_driver_first_name);
  const isSecondDriverExists = !!(cf_second_driver_last_name && cf_second_driver_fist_name);

  function onEditHandler() {
    setIsEditPhone((prev) => !prev);
  }

  function handleOkPhone() {
    if (isPhoneError || phoneInput < 7) return setIsPhoneError(true);

    if (phoneInput === phoneToShow) {
      setIsEditPhone(false);
      return;
    }

    setIsEditPhone(false);
    setPhoneToShow(phoneInput);
    setPhoneInput(phoneInput);
    dispatch(updatePhoneNumber(phoneInput));
  }

  function handleCrossPhone() {
    setIsEditPhone(false);
    setIsPhoneError(false);
    setPhoneInput(phoneToShow);
  }

  function handleShowCivility(civ) {
    if (civ === "Mrs") {
      return t("subscription.personal-information.civility-mrs");
    }
    if (civ === "Mr") {
      return t("subscription.personal-information.civility-mr");
    }

    return civ || "";
  }

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("my-account.personal-data.personal-information")}</h3>
        <div className="divider" />

        <div className="personal-wrap">
          {(isFirstDriverExists || isSecondDriverExists) && (
            <>
              <h5>{t("my-account.pro-data.my-society")}</h5>

              <AccountItemInfo
                title={t("my-account.pro-data.representative-surname")}
                value={`${handleShowCivility(cf_civility)} ${last_name}`}
              />
              <AccountItemInfo
                title={t("my-account.pro-data.representative-firstname")}
                value={first_name}
              />
              <AccountItemInfo
                // title={'Dénomination Sociale'}
                title={t("my-account.pro-data.company")}
                value={company}
              />
              <AccountItemInfo
                // title={'N° SIREN'}
                title={t("my-account.pro-data.siren")}
                value={cf_company_siren}
              />
              <AccountItemInfo
                // title={'Adresse de la société'}
                title={t("my-account.pro-data.company_address")}
                value={
                  <>
                    {cf_pro_residence_street_name_and_number} <br />
                    {cf_pro_residence_postal_code} {cf_pro_residence_city}
                  </>
                }
              />
              <AccountItemInfo
                // title={'Code APE'}
                title={t("my-account.pro-data.ape")}
                value={cf_company_ape}
              />
              <AccountItemInfo
                // title={'N° TVA'}
                title={t("my-account.pro-data.tva")}
                value={cf_company_tva_code}
              />
              <div>
                <h6>{t("my-account.pro-data.phone")}</h6>
                <div className="my-account-phone-wrap">
                  {isEditPhone ? (
                    <>
                      <div>
                        <PhoneInputPlain
                          initialPhone={phoneInput}
                          onValid={(value) => {
                            setPhoneInput(value);
                            setIsPhoneError(false);
                          }}
                          onError={() => {
                            setIsPhoneError(true);
                          }}
                          onClear={() => {
                            setPhoneInput("");
                            setIsPhoneError(false);
                          }}
                          inputStyle={{ ...inputProps.inputStyle }}
                          placeholder={"Telephone"}
                        />
                      </div>
                      <div className="phone-wrap-actions">
                        <div className="phone-wrap-ok-action" onClick={handleOkPhone}>
                          <img src={OkIcon} alt="" />
                        </div>
                        <div className="phone-wrap-cross-action" onClick={handleCrossPhone}>
                          <img src={CrossIcon} alt="" />
                        </div>
                      </div>
                      {isPhoneError && (
                        <div className="invalid-phone">
                          {t("subscription.personal-information.invalid-phone")}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {phoneToShow}
                      <div>
                        {customerLoadingStatus.pending || updatePhoneLoadingStatus.pending ? (
                          <CircularProgress size={20} />
                        ) : (
                          <div className="phone-wrap-actions" onClick={onEditHandler}>
                            <img src={EditIcon} alt="" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <AccountItemInfo title={t("my-account.pro-data.mail")} value={email} />

              {isFirstDriverExists && (
                <>
                  <h5>Informations du conducteur</h5>
                  {/*<h5>{t('my-account.personal-data.my-society')}</h5>*/}

                  <AccountItemInfo
                    title={t("my-account.personal-data.last-name")}
                    value={`${handleShowCivility(cf_pro_1st_driver_civility)} ${
                      cf_pro_1st_driver_last_name || ""
                    }`}
                  />
                  <AccountItemInfo
                    title={t("my-account.personal-data.second-driver-first-name")}
                    value={cf_pro_1st_driver_first_name}
                  />
                  <AccountItemInfo
                    // title={'Adresse de résidence'}
                    title={t("my-account.pro-data.residence_address")}
                    value={
                      <>
                        {billing_address.line1 || ""} <br />
                        {billing_address.zip || ""} {billing_address.city || ""}
                      </>
                    }
                  />
                  <AccountItemInfo
                    title={t("my-account.personal-data.date-of-birth")}
                    value={
                      cf_pro_birth_date_first_driver
                        ? moment(cf_pro_birth_date_first_driver, "DD-MM-YYYY").format("DD/MM/YYYY")
                        : ""
                    }
                  />
                  <AccountItemInfo
                    title={t("my-account.pro-data.mail")}
                    value={cf_pro_1st_driver_email}
                  />
                </>
              )}
              {isSecondDriverExists && (
                <>
                  <h5>{t("my-account.personal-data.second-driver")}</h5>

                  <AccountItemInfo
                    title={t("my-account.personal-data.last-name")}
                    value={`${handleShowCivility(cf_second_driver_civility)} ${
                      cf_second_driver_last_name || ""
                    }`}
                  />
                  <AccountItemInfo
                    title={t("my-account.personal-data.second-driver-first-name")}
                    value={cf_second_driver_fist_name}
                  />
                  <AccountItemInfo
                    title={t("my-account.personal-data.second-driver-date-of-birth")}
                    value={
                      cf_birth_date_second_driver
                        ? moment(cf_birth_date_second_driver, "DD-MM-YYYY").format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </>
              )}
            </>
          )}
          {!isFirstDriverExists && !isSecondDriverExists && (
            <>
              {/*<h5>Mes informations</h5>*/}
              <h5>{t("my-account.pro-data.my_information")}</h5>

              <AccountItemInfo
                title={t("my-account.personal-data.last-name")}
                value={`${handleShowCivility(cf_civility)} ${last_name}`}
              />
              <AccountItemInfo
                title={t("my-account.personal-data.first-name")}
                value={first_name}
              />
              <AccountItemInfo
                title={t("my-account.personal-data.date-of-birth")}
                value={
                  cf_birth_date ? moment(cf_birth_date, "DD-MM-YYYY").format("DD/MM/YYYY") : ""
                }
              />
              <AccountItemInfo
                // title={'Adresse de résidence'}
                title={t("my-account.pro-data.residence_address")}
                value={`${billing_address.line1 || ""}, ${billing_address.city || ""}, ${
                  countriesMultilang.getName(billing_address.country, setLanguage()) || ""
                }, ${billing_address.zip || ""}`}
              />
              <AccountItemInfo title={t("my-account.personal-data.mail")} value={email} />
              <div>
                <h6>{t("my-account.personal-data.phone")}</h6>
                <div className="my-account-phone-wrap">
                  {isEditPhone ? (
                    <>
                      <div>
                        <PhoneInputPlain
                          initialPhone={phoneInput}
                          onValid={(value) => {
                            setPhoneInput(value);
                            setIsPhoneError(false);
                          }}
                          onError={() => {
                            setIsPhoneError(true);
                          }}
                          onClear={() => {
                            setPhoneInput("");
                            setIsPhoneError(false);
                          }}
                          inputStyle={{ ...inputProps.inputStyle }}
                          placeholder={"Telephone"}
                        />
                      </div>
                      <div className="phone-wrap-actions">
                        <div className="phone-wrap-ok-action" onClick={handleOkPhone}>
                          <img src={OkIcon} alt="" />
                        </div>
                        <div className="phone-wrap-cross-action" onClick={handleCrossPhone}>
                          <img src={CrossIcon} alt="" />
                        </div>
                      </div>
                      {isPhoneError && (
                        <div className="invalid-phone">
                          {t("subscription.personal-information.invalid-phone")}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {phoneToShow}
                      <div>
                        {customerLoadingStatus.pending || updatePhoneLoadingStatus.pending ? (
                          <CircularProgress size={20} />
                        ) : (
                          <div className="phone-wrap-actions" onClick={onEditHandler}>
                            <img src={EditIcon} alt="" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <h5>{t("my-account.personal-data.my-society")}</h5>

              <AccountItemInfo
                // title={'Dénomination Sociale'}
                title={t("my-account.pro-data.company")}
                value={company}
              />
              <AccountItemInfo
                // title={'N° SIREN'}
                title={t("my-account.pro-data.siren")}
                value={cf_company_siren}
              />
              <AccountItemInfo
                // title={'Adresse de la société'}
                title={t("my-account.pro-data.residence_address")}
                value={
                  <>
                    {cf_pro_residence_street_name_and_number} <br />
                    {cf_pro_residence_postal_code} {cf_pro_residence_city}
                  </>
                }
              />
              <AccountItemInfo
                // title={'Code APE'}
                title={t("my-account.pro-data.ape")}
                value={cf_company_ape}
              />
              <AccountItemInfo
                // title={'N° TVA'}
                title={t("my-account.pro-data.tva")}
                value={cf_company_tva_code}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
