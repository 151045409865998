const salesPlans = ["SWAPPER_SALES", "SWAPPERX_SALES", "SWAPPERXPLUS_SALES"];

export const getThirdSentence = (plan: string) => {
  if (salesPlans.includes(plan)) {
    return "subscription.validation-of-conditions.private-use-sales";
  }

  if (plan === "SWAPPER_RIDERX") {
    return "subscription.validation-of-conditions.private-use-rider";
  }

  return "subscription.validation-of-conditions.private-use";
};
