
type locales = "fr" | "en" | 'es';

export const setLanguage = (): locales => {
  const countryCode = localStorage.getItem("countryCode") as locales;
  if (countryCode) return countryCode;
  if (navigator.language.includes("fr") || navigator.language.includes("Fr")) return "fr";
  if (navigator.language.includes("es") || navigator.language.includes("Es")) return "es";
  if (window.location.href.includes("devapp.zeway.com")) return "en";
  return "fr";
};

export const baseUrlLocale = (): string => {
  if (setLanguage() === "es") return  "https://www.zeway.es/"
  return "https://www.zeway.com/";
}
