import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";

import { sendDrivingExperience } from "api";
import { useGTM, useDidMount, useCall } from "hooks";
import { RootState, ScooterSetupState } from "store";
import { isEmpty } from "utils";
import { NonNullableKeys, Nullable } from "models";
import { DEFAULT_PAGE, GENERAL_CONDITIONS_PAGE, PAYMENT_PAGE } from "constants/routes.constants";
import Radio from "components/Inputs/Radio";
import { Footer } from "components/Footer";

type CheckBoxType = Nullable<"false" | "true">;

const Authorization: React.FC = memo(() => {
  useGTM(8);

  const history = useHistory();
  const { t } = useTranslation();

  const { submit, submitting, onCallSuccess } = useCall(sendDrivingExperience);
  onCallSuccess(() => history.push(PAYMENT_PAGE.path), []);

  const { setup, personalInfo, subscriptionId } = useSelector(
    (state: RootState) => state.scooterSetup,
  ) as NonNullableKeys<ScooterSetupState>;

  const power = +setup.cf_power;
  const { dob } = personalInfo;

  const [injuryResponsibility, setInjuryResponsibility] = useState<CheckBoxType>(null);
  const [materialClaimsResponsibility, setMaterialClaimsResponsibility] =
    useState<CheckBoxType>(null);
  const [drivingLicenseWithdrawal, setDrivingLicenseWithdrawal] = useState<CheckBoxType>(null);
  const [prosecution, setProsecution] = useState<CheckBoxType>(null);

  const [accidentResponsibility, setAccidentResponsibility] = useState<CheckBoxType>(null);
  const [alcoholResponsibility, setAlcoholResponsibility] = useState<CheckBoxType>(null);
  const [drugsResponsibility, setDrugsResponsibility] = useState<CheckBoxType>(null);

  const [license, setLicense] = useState<Nullable<"BSR" | "PERMISSION_B">>(null);

  useDidMount(() => {
    if (!power || isEmpty(personalInfo) || !subscriptionId) history.push(DEFAULT_PAGE.path);
  });

  const isLicenseValid = () => {
    const minDob = new Date("1988-01-01");
    const dobParsed = new Date(dob);
    if (minDob < dobParsed || power >= 80) return Boolean(license);
    return true;
  };

  const isShowPermissionBlock = () => {
    const minDob = new Date("1988-01-01");
    const dobParsed = new Date(dob);
    return minDob < dobParsed && power < 80;
  };

  const isNextButtonDisabled = () => {
    if (
      (injuryResponsibility === "false" &&
        materialClaimsResponsibility === "false" &&
        drivingLicenseWithdrawal === "false" &&
        prosecution === "false" &&
        isLicenseValid()) ||
      (accidentResponsibility === "false" &&
        alcoholResponsibility === "false" &&
        drugsResponsibility === "false" &&
        isLicenseValid())
    ) {
      return false;
    }
    return true;
  };

  const notificationText = () => {
    if (
      injuryResponsibility === "true" ||
      materialClaimsResponsibility === "true" ||
      drivingLicenseWithdrawal === "true" ||
      prosecution === "true" ||
      accidentResponsibility === "true" ||
      alcoholResponsibility === "true" ||
      drugsResponsibility === "true"
    ) {
      return "subscription.license-and-insurance.error-message";
    }
    return "";
  };

  const nextStepHandler = () => {
    if (license) return submit(subscriptionId, { permissionType: license });
    history.push(PAYMENT_PAGE.path);
  };

  const prevButtonHandler = () => history.push(GENERAL_CONDITIONS_PAGE.path);

  return (
    <div className="common-wrap">
      <div className="content subscribtions-terms-wrap">
        <h3 className="header">{t("subscription.license-and-insurance.driver-license")}</h3>
        <div className="divider" />

        <span>
          <span>*</span>
          {t("subscription.personal-information")}
        </span>

        {isShowPermissionBlock() && (
          <>
            <h6>
              <span>*</span>
              {t("subscription.license-and-insurance.questuion")}
            </h6>
            <div className="license-type">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="license"
                  onChange={(e: any) => setLicense(e.target.value)}
                  value={license}
                >
                  <FormControlLabel
                    value="BSR"
                    control={<Radio />}
                    label={
                      <div className="license_label">
                        {t("subscription.license-and-insurance.am_or_bsr")}
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="PERMISSION_B"
                    control={<Radio />}
                    label={
                      <div className="license_label">
                        {t("subscription.license-and-insurance.b_license")}
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {power >= 80 && (
          <div className="license-type">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="position"
                name="license"
                onChange={(e: any) => setLicense(e.target.value)}
                value={license}
              >
                <FormControlLabel
                  value="PERMISSION_A"
                  control={<Radio />}
                  label={
                    <div className="license_label">
                      {t("subscription.license-and-insurance.driver-license-125cc")}
                    </div>
                  }
                />
                <FormControlLabel
                  value="PERMISSION_B"
                  control={<Radio />}
                  label={
                    <div className="license_label">
                      {t("subscription.license-and-insurance.permit-b")}{" "}
                      <span className="semilight">
                        {t("subscription.license-and-insurance.born-before-1980")}
                      </span>
                    </div>
                  }
                />
                <FormControlLabel
                  value="ATTESTATION_125"
                  control={<Radio />}
                  label={
                    <div className="license_label">
                      {t("subscription.license-and-insurance.certificate-125cc")}{" "}
                      <span className="semilight">
                        {t("subscription.license-and-insurance.born-after-1980")}
                      </span>
                    </div>
                  }
                />
                <FormControlLabel
                  value="INSURANCE_DOCUMENT"
                  control={<Radio />}
                  label={
                    <div className="license_label">
                      {t("subscription.license-and-insurance.statement-of-assurance")}{" "}
                      <span className="semilight">
                        {t("subscription.license-and-insurance.insurer-infotmation")}
                      </span>
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <h6>
          <span>*</span>
          {t("subscription.license-and-insurance.last-three-years")}
        </h6>

        <div className="accidents-wrap">
          {power < 80 ? (
            <ul>
              <li>
                <div className="text">- {t("subscription.license-and-insurance.accident")}</div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setAccidentResponsibility(e.target.value)}
                      value={accidentResponsibility}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
              <li>
                <div className="text">- {t("subscription.license-and-insurance.alcohol")}</div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setAlcoholResponsibility(e.target.value)}
                      value={alcoholResponsibility}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
              <li>
                <div className="text">- {t("subscription.license-and-insurance.drugs")}</div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setDrugsResponsibility(e.target.value)}
                      value={drugsResponsibility}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <div className="text">- {t("subscription.license-and-insurance.body_injury")}</div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setInjuryResponsibility(e.target.value)}
                      value={injuryResponsibility}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
              <li>
                <div className="text">
                  - {t("subscription.license-and-insurance.material_claims")}
                </div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setMaterialClaimsResponsibility(e.target.value)}
                      value={materialClaimsResponsibility}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
              <li>
                <div className="text">
                  - {t("subscription.license-and-insurance.license_withdrawal")}
                </div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setDrivingLicenseWithdrawal(e.target.value)}
                      value={drivingLicenseWithdrawal}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
              <li>
                <div className="text">- {t("subscription.license-and-insurance.conviction")}</div>
                <div className="checkbox-wrap">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="bodyInjury"
                      onChange={(e: any) => setProsecution(e.target.value)}
                      value={prosecution}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.yes")}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("subscription.license-and-insurance.no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
      <Footer
        nextButtonId="driver-license"
        nextButtonHandler={nextStepHandler}
        prevButtonHandler={prevButtonHandler}
        isNextButtonDisabled={isNextButtonDisabled()}
        notificationText={notificationText()}
        isLoading={submitting}
      />
    </div>
  );
});

export default Authorization;
