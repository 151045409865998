import React from "react";
import PropTypes from "prop-types";

export const AccountItemInfo = ({ title, value = "" }) => {
  return (
    <div>
      <h6>{title}</h6>
      <p>{value}</p>
    </div>
  );
};

AccountItemInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};
