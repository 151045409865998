import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { withStyles, Switch } from "@material-ui/core";

import { useWindowSize } from "hooks";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 68,
    height: 36,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "visible",
  },
  switchBase: {
    padding: 6,
    "&$checked": {
      transform: "translateX(32px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#F16344",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#F16344",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 50,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#CFCFCF",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const IOSSwitchSmall = withStyles((theme) => ({
  root: {
    width: 58,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "visible",
  },
  switchBase: {
    padding: 5,
    "&$checked": {
      transform: "translateX(28px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#F16344",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#F16344",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 50,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#CFCFCF",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

type InsuranceProps = {
  checked: boolean;
  onChecked: (value: boolean) => void;
  priceText?: string;
  descriptionText?: string;
  detailsText?: string;
};

export const Insurance: React.FC<InsuranceProps> = memo(
  ({
    descriptionText = "subscription.subscription-choice.ins-option-part-description",
    detailsText = "subscription.subscription-choice.ins-option-part-details",
    priceText = "N/A",
    checked = false,
    onChecked,
  }: InsuranceProps) => {
    const { t } = useTranslation();

    const { width } = useWindowSize();

    const handleSwitchChange = () => onChecked(!checked);

    return (
      <div className="assurance-wrap">
        <h2 className="assurance-title">
          {t("subscription.subscription-choice.ins-option-part-header")}
        </h2>
        <div className="divider" />
        <div className="assurance-description">{t(descriptionText)}</div>
        <div className="assurance-content">
          <div className="assurance-content-details">{t(detailsText)}</div>
          <div className="assurance-content-price">
            <div className="price-wrap">{priceText}</div>
            <div className="button-wrap">
              {width > 550 ? (
                <IOSSwitch checked={checked} onChange={handleSwitchChange} />
              ) : (
                <IOSSwitchSmall checked={checked} onChange={handleSwitchChange} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
