import axios from "axios";

import { TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { ParamsProps, ErrorResponse } from "./api.middleware";

type ProgressProps = {
  loaded: number;
  total: number;
};

type ErrorProps = {
  response: any;
  request: any;
} & ErrorResponse;

const { CancelToken } = axios;
const abortControllers: Record<string, any> = {};

const cancelError = "Request has been canceled";

export function handlePrepareData(payload: any, isFormData: boolean) {
  let data: string | FormData | undefined = undefined;
  const isPayloadFormData = payload instanceof FormData;
  if (isPayloadFormData && isFormData) {
    data = payload;
  } else if (!isPayloadFormData && isFormData) {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    data = formData;
  } else if (payload) {
    data = JSON.stringify(payload) as any;
  }
  return data;
}

export function handleHeaders(params: ParamsProps, isFormData: boolean) {
  const headers: HeadersInit = {};
  const token = localStorage.getItem(TOKEN_STORAGE_FIELD);
  const isUnauthorizedEndpoint = isExcludedEndpoint(params.url);

  if (token && !isUnauthorizedEndpoint) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  Object.assign(headers, params.headers);

  return headers;
}

export function handleCancelDuplicatedRequest(params: ParamsProps) {
  if (params.canceling !== undefined && params.canceling === false) {
    return undefined;
  }

  const abortName = params.url.split("?")[0];

  if (abortControllers[abortName]) {
    abortControllers[abortName].cancel(cancelError);
    delete abortControllers[abortName];
  }

  const cancelToken = CancelToken.source();

  abortControllers[abortName] = cancelToken;

  return cancelToken.token;
}

export function handleErrors(error: ErrorProps): ErrorResponse {
  if (error?.message === cancelError) {
    return {
      statusCode: 0,
      errors: ["Request canceled"],
      formattedMessage: "Request canceled",
    };
  } else {
    return { ...error, formattedMessage: error.errors?.[0] || "Server Error" };
  }
}

export function handleStatus({ response, request }: ErrorProps) {
  if (response) {
    return response?.status;
  } else if (request) {
    return request?.status;
  }
  return 0;
}

export function handleProgress(callback?: (percentage: number) => void) {
  return (progressEvent: ProgressProps) => {
    if (callback && progressEvent) {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      callback(percentage);
    }
  };
}

export function isCanceledRequest(error: any) {
  return error?.message === cancelError;
}

function isExcludedEndpoint(url: string) {
  const excludedEndpoints = ["login", "account-activation", "reset-password"];
  const isExcluded = excludedEndpoints.find((excludedRoute) => url.includes(excludedRoute));

  return Boolean(isExcluded);
}
