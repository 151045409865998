export const GET_DOCUMENTS_LIST = "GET_DOCUMENTS_LIST";
export const GET_DOCUMENTS_LIST_SUCCESS = "GET_DOCUMENTS_LIST_SUCCESS";
export const GET_DOCUMENTS_LIST_FAILURE = "GET_DOCUMENTS_LIST_FAILURE";

export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA";
export const GET_CUSTOMER_DATA_SUCCESS = "GET_CUSTOMER_DATA_SUCCESS";
export const GET_CUSTOMER_DATA_FAILURE = "GET_CUSTOMER_DATA_FAILURE";

export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER";
export const UPDATE_PHONE_NUMBER_SUCCESS = "UPDATE_PHONE_NUMBER_SUCCESS";
export const UPDATE_PHONE_NUMBER_FAILURE = "UPDATE_PHONE_NUMBER_FAILURE";

export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = "GET_SUBSCRIPTION_DETAILS_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_FAILURE = "GET_SUBSCRIPTION_DETAILS_FAILURE";

export const GET_CONTRACT_STATUS = "GET_CONTRACT_STATUS";
export const GET_CONTRACT_STATUS_SUCCESS = "GET_CONTRACT_STATUS_SUCCESS";
export const GET_CONTRACT_STATUS_FAILURE = "GET_CONTRACT_STATUS_FAILURE";
