import React from "react";
import PropTypes from "prop-types";

export const LoggedInHeader = ({ t }) => {
  return (
    <div className="login-wrap">
      <nav>
        <span
          dangerouslySetInnerHTML={{
            __html: t("my-account.header.home-link"),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t("my-account.header.swapping-link"),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t("my-account.header.total-link"),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t("my-account.header.propos-link"),
          }}
        />
      </nav>
    </div>
  );
};

LoggedInHeader.propTypes = {
  t: PropTypes.func.isRequired,
};
