export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-", ".", ",", " "].includes(e.key) && e.preventDefault();

export function setInputCaretPosition(inputRef, start, end) {
  // IE Support
  if (document.selection) {
    // Set focus on the element
    inputRef.current.focus();

    // Create empty selection range
    const oSel = document.selection.createRange();

    // Move selection start and end to 0 position
    oSel.moveStart("character", -inputRef.current.value.length);

    // Move selection start and end to desired position
    oSel.moveStart("character", 0);
    oSel.moveEnd("character", 0);
    oSel.select();
  }

  // Firefox support
  else if (inputRef.current.selectionStart || inputRef.current.selectionStart === "0") {
    inputRef.current.selectionStart = 0;
    inputRef.current.selectionEnd = 0;
    inputRef.current.focus();
  }
  inputRef.current.setSelectionRange(start, end);
}
