import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { MenuHoc } from "./MenuHoc.js";

import OkIcon from "assets/images/icons/ok.svg";
import WarningIcon from "assets/images/icons/warning.svg";
import ErrorIcon from "assets/images/icons/error.svg";
import Clock from "assets/images/icons/clock.svg";

import { getSubscriptionDetails, getContractStatus } from "../../store/actions";

const mapStatusToImage = {
  ok: OkIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  "not verified": Clock,
  validated: OkIcon,
};

const Dashboard = ({
  contractStatus,
  getContractStatus,
  customer,
  subscription,
  getSubscriptionDetails,
}) => {
  const { push } = useHistory();
  const [data, updateData] = useState([
    {
      text: "subscription.header.my-offer",
      statusText: "my-account.dashboard.valid",
      status: "ok",
    },
    {
      text: "subscription.header.my-personal-information",
      statusText: "my-account.dashboard.valid",
      status: "ok",
    },
    {
      text: "subscription.header.my-payment-method",
      statusText: "my-account.dashboard.valid",
      status: "ok",
      link: "/account/payment",
    },
    {
      text: "subscription.header.my-documents",
      statusText: "my-account.dashboard.valid",
      status: "ok",
      link: "/account/documents",
    },
    // {
    //     text: 'my-account.dashboard.my-signature',
    //     statusText: 'my-account.dashboard.signed-contract',
    //     status: 'error',
    //     //TODO: need to get contract downloadUrl to redirect, server doesn't send it yet.
    //     link: '/account/contract',
    // },
  ]);

  const { t } = useTranslation();

  useEffect(() => {
    if (customer.subscriptions && customer.subscriptions.length) {
      if (Object.keys(subscription).length === 0 && customer.email) {
          const mainSubscription = customer.subscriptions.find(subscription => subscription.main)

          if (mainSubscription) {
              getContractStatus(mainSubscription.externalId);
              getSubscriptionDetails(mainSubscription.externalId,);
          }

      }
      updateData((prevState) =>
        prevState.map((item) => {
          if (item.text !== "subscription.header.my-payment-method") return item;
          const status =
            customer.chargebeeCustomer.cf_payment_bic && customer.chargebeeCustomer.cf_payment_iban
              ? "validated"
              : "warning";

          let statusTextToShow = status;

          if (statusTextToShow === "validated") {
            statusTextToShow = "my-account.dashboard.valid";
          }
          if (statusTextToShow === "warning") {
            statusTextToShow = "my-account.dashboard.missing-info";
          }

          return {
            ...item,
            statusText: statusTextToShow,
            status,
          };
        }),
      );
    }
  }, [customer]);
  useEffect(() => {
    updateData((prevState) =>
      prevState.map((item) => {
        if (item.text !== "my-account.dashboard.my-signature") return item;
        const status = contractStatus === "signed" ? "validated" : "warning";

        let statusTextToShow = status;

        if (statusTextToShow === "validated") {
          statusTextToShow = "my-account.dashboard.signed-contract";
        }
        if (statusTextToShow === "warning") {
          statusTextToShow = "my-account.dashboard.not-signed-contract";
        }

        return {
          ...item,
          statusText: statusTextToShow,
          status,
        };
      }),
    );
  }, [contractStatus]);
  useEffect(() => {
    const { cf_driver_licence_bsr_check, cf_cf_buyer_passport_check, cf_residence_proof_check } =
      subscription;
    updateData((prevState) =>
      prevState.map((item) => {
        if (item.text !== "subscription.header.my-documents") return item;

        // const status = cf_driver_licence_bsr_check === cf_residence_proof_check && cf_driver_licence_bsr_check === cf_cf_buyer_passport_check && cf_driver_licence_bsr_check === 'not verified' ? 'not verified' :
        // cf_driver_licence_bsr_check === cf_residence_proof_check && cf_driver_licence_bsr_check === cf_cf_buyer_passport_check && cf_driver_licence_bsr_check === 'validated' ? 'validated' :
        // cf_driver_licence_bsr_check === cf_residence_proof_check && cf_driver_licence_bsr_check === cf_cf_buyer_passport_check && cf_driver_licence_bsr_check === 'not needed' ? 'validated' : 'warning';

        const status =
          cf_driver_licence_bsr_check === "empty" ||
          cf_cf_buyer_passport_check === "empty" ||
          cf_residence_proof_check === "empty"
            ? "warning"
            : (cf_driver_licence_bsr_check === "not verified" ||
                cf_driver_licence_bsr_check === "not needed") &&
              (cf_cf_buyer_passport_check === "not verified" ||
                cf_cf_buyer_passport_check === "not needed") &&
              (cf_residence_proof_check === "not verified" ||
                cf_residence_proof_check === "not needed")
            ? "not verified"
            : (cf_driver_licence_bsr_check === "validated" ||
                cf_driver_licence_bsr_check === "not needed") &&
              (cf_cf_buyer_passport_check === "validated" ||
                cf_cf_buyer_passport_check === "not needed") &&
              (cf_residence_proof_check === "validated" ||
                cf_residence_proof_check === "not needed")
            ? "validated"
            : (cf_driver_licence_bsr_check === "validated" ||
                cf_driver_licence_bsr_check === "not needed" ||
                cf_driver_licence_bsr_check === "not verified") &&
              (cf_cf_buyer_passport_check === "validated" ||
                cf_cf_buyer_passport_check === "not needed" ||
                cf_cf_buyer_passport_check === "not verified") &&
              (cf_residence_proof_check === "validated" ||
                cf_residence_proof_check === "not needed" ||
                cf_residence_proof_check === "not verified")
            ? "not verified"
            : "warning";

        let statusTextToShow = status;

        if (statusTextToShow === "warning") {
          statusTextToShow = "my-account.dashboard.missing-document";
        }
        if (statusTextToShow === "not verified") {
          statusTextToShow = "my-account.dashboard.checking";
        }
        if (statusTextToShow === "validated") {
          statusTextToShow = "my-account.dashboard.valid";
        }

        return {
          ...item,
          statusText: statusTextToShow,
          status,
        };
      }),
    );
  }, [subscription]);

  if (subscription.status === "active") {
    push("/account");
    return null;
  }

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("my-account.dashboard.dashboard")}</h3>
        <div className="divider" />

        <p className="help-text">{t("my-account.dashboard.subscription-status")}</p>

        <div className="dashboard-list">
          {data.map((item, index) => {
            if (item.link) {
              return (
                <Link key={index} to={item.link} className={"dashboard-item"}>
                  <div className="list-text">{t(item.text)}</div>
                  <div className={`list-status ${item.status}`}>{t(item.statusText)}</div>
                  <div className="list-icon">
                    <img src={mapStatusToImage[item.status]} alt="" />
                  </div>
                </Link>
              );
            }
            return (
              <div key={index} className={"dashboard-item"}>
                <div className="list-text">{t(item.text)}</div>
                <div className={`list-status ${item.status}`}>{t(item.statusText)}</div>
                <div className="list-icon">
                  <img src={mapStatusToImage[item.status]} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.account.subscription,
  customer: state.account.customer,
  contractStatus: state.account.contractStatus,
});
const mapDispatchToProps = {
  getSubscriptionDetails,
  getContractStatus,
};

export default MenuHoc(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
