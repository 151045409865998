import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "store";

export const SecurityDeposit: React.FC = memo(() => {
  const { t } = useTranslation();

  const cautionAddon = useSelector(
    (state: RootState) => state.scooterSetup.paymentDetails.cautionAddon,
  );

  if (!cautionAddon) return null;

  return (
    <div className="security-deposit">
      <div className="security-deposit-text">
        <h5>{t("subscription.subscription-choice.deposit")}</h5>
        <p>{t("subscription.subscription-choice.deposit.guarantee")}</p>
      </div>

      <div className="security-deposit-price">
        <div className="main-price">{cautionAddon.price / 100}€</div>
      </div>
    </div>
  );
});
