import { useDidMount } from "hooks";
import { pushDataLayerEvent } from "utils";

export const useGTM = (step: number): void => {
  useDidMount(() => {
    const find = window.dataLayer?.find((item: any) => item.step === `${step}`);

    if (!find) {
      pushDataLayerEvent({
        event: "pageview",
        step: `${step}`,
      });
    }
  });
};

export default useGTM;
