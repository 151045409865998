import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";

import { getCoupon } from "api";
import { useCall } from "hooks";
import { isEmpty } from "utils";
import { RootState, setCoupons } from "store";
import { CouponModel, Nullable } from "models";

import SuccessImage from "assets/images/icons/valide.svg";
import ErrorImage from "assets/images/icons/error.svg";

export const PromoCode: React.FC = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [coupon, setCoupon] = useState("");
  const [fetchedCoupon, setFetchedCoupon] = useState<Nullable<CouponModel>>(null);
  const coupons = useSelector(
    (state: RootState) => state.scooterSetup.paymentDetails.selectedCoupons,
  );
  const savedCoupon = coupons[0] || fetchedCoupon || {};

  const { submit, submitting, onCallSuccess, details } = useCall(getCoupon);
  onCallSuccess((data) => {
    setFetchedCoupon(data);
    dispatch(setCoupons([data]));
  }, []);

  const isValidCoupon = !isEmpty(savedCoupon) && details.status === 200 && !submitting;
  const isInvalidCoupon = !savedCoupon && !!details.status && details.status !== 200 && !submitting;

  const { discount_amount, name, id, meta_data } = savedCoupon || {};
  const isReferral = meta_data?.coupon_type === "refferal";

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFetchedCoupon(null);
    dispatch(setCoupons([]));
    submit(coupon);
  };

  return (
    <div className={"promo-wrap"}>
      <form className={"form-wrap"} onSubmit={handleSubmit}>
        <div className={"input-wrap"}>
          <TextField
            variant="outlined"
            error={isInvalidCoupon}
            value={coupon || savedCoupon.id || ""}
            onChange={(e) => setCoupon(e.target.value)}
            placeholder={t("subscription.subscription-choice.promo-code")}
            disabled={submitting}
          />
          {isInvalidCoupon && <img src={ErrorImage} alt="" />}
          {isValidCoupon && <img src={SuccessImage} alt="" />}
        </div>
        {isInvalidCoupon && (
          <div className={"form-helper-text"}>Code invalide, vérifiez votre saisie</div>
        )}
      </form>

      <div className={"description-wrap"}>
        {name && (
          <>
            <h6>
              {isReferral
                ? t("subscription.subscription-choice.referral-coupon-title")
                : t(`subscription.subscription-choice.${id}`)}
            </h6>
            <p>
              {isReferral
                ? t("subscription.subscription-choice.referral-coupon-description")
                : t(`subscription.subscription-choice.${id}.description`)}
            </p>
          </>
        )}
      </div>

      {discount_amount && (
        <div className={"discount-wrap"}>
          <div>-{discount_amount / 100}€</div>
          <span>{t("subscription.cart.ttc")}</span>
        </div>
      )}
      <button onClick={handleSubmit}>{t("subscription.subscription-choice.add")}</button>
    </div>
  );
});
