import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "Auth";
import { MenuHoc } from "./MenuHoc.js";

import MyInformation from "assets/images/account/Information personnelle.svg";
import MyContact from "assets/images/account/icon-contract.svg";
import MyBills from "assets/images/account/Factures.png";
import MyPaymentMethods from "assets/images/account/icon-paymebt.svg";
import MyDocuments from "assets/images/account/Documents.png";
import Password from "assets/images/account/Mote de passe.svg";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const NavigationPage = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const { status } = useSelector((state) => state.account.subscription);

  const links = [
    {
      title: "my-account.home.my-information",
      link: "/account/personal",
      image: MyInformation,
    },
    {
      title: "my-account.home.my-contract",
      link: "/account/contract",
      image: MyContact,
    },
    {
      title: "my-account.home.my-bills",
      link: "/account/bills",
      image: MyBills,
    },
    {
      title: "my-account.home.my-payment-method",
      link: "/account/payment",
      image: MyPaymentMethods,
    },
    {
      title: "my-account.home.my-documents",
      link: "/account/documents",
      image: MyDocuments,
    },
    {
      title: "my-account.home,password-recovery",
      link: "/account/password",
      image: Password,
    },
  ];

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <div className="navigation-wrap">
          <Link to="/account/dashboard" className="link-mobile-block">
            <div className="title">{t("my-account.dashboard.dashboard")}</div>
            <div className="image">
              <ArrowForwardIosIcon />
            </div>
          </Link>
          {links.map((item, index) => {
            if (status === "active" && item.title === "my-account.home.my-documents") return null;

            if (!item.link) {
              return (
                <Fragment key={item + index}>
                  <div className="link-block">
                    <div className={"title"}>
                      {t(item.title)} <ArrowForwardIosIcon />
                    </div>
                    <div className={"image-wrap"}>
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <div className="link-mobile-block">
                    <div className={"title"}>{t(item.title)}</div>
                    <div className={"image"}>
                      <ArrowForwardIosIcon />
                    </div>
                  </div>
                </Fragment>
              );
            }

            return (
              <Fragment key={item + index}>
                <Link to={item.link} className="link-block">
                  <div className={"title"}>
                    {t(item.title)} <ArrowForwardIosIcon />
                  </div>
                  <div className={"image-wrap"}>
                    <img src={item.image} alt="" />
                  </div>
                </Link>
                <Link to={item.link} className="link-mobile-block">
                  <div className={"title"}>{t(item.title)}</div>
                  <div className={"image"}>
                    <ArrowForwardIosIcon />
                  </div>
                </Link>
              </Fragment>
            );
          })}
          <button className={"logout-button"} onClick={logout}>
            {t("my-account.header.sign-out")}
          </button>
          <div
            className="contact-us"
            dangerouslySetInnerHTML={{
              __html: t("my-account.menu.contact-us-link"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuHoc(NavigationPage);
