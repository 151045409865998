import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { isEmpty, isEqual } from "utils";
import { getDocumentsList, uploadFiles, getSubscriptionDetails } from "../../store/actions";
import { MenuHoc } from "./MenuHoc.js";
import { Uploader } from "../Fourth_step/Uploader";

const maxSize = 20; //20 MB

const formatbytes = (bytes) => {
  return parseFloat(bytes / Math.pow(1024, 2));
};
const isExceedSize = (weight) => formatbytes(weight) > maxSize;
const isFormatValid = (format) => {
  const availableFormats = ["image/png", "image/jpeg", "application/pdf"];
  return availableFormats.includes(format);
};
const isShowDriverLicense = (dob) => {
  let dobToParse = dob ? moment(dob, "DD-MM-YYYY") : "1988-01-01";
  const dobParsed = new Date(dobToParse);
  const minDob = new Date("1988-01-01");
  return minDob < dobParsed;
};

function Documents() {
  const { t } = useTranslation();

  const { push } = useHistory();
  const dispatch = useDispatch();

  const documents = useSelector((state) => state.account.document.documentsList);
  const documentLoadingStatus = useSelector((state) => state.user.documentLoadingStatus);
  const generalDocumentLoadingStatus = useSelector((state) => state.account.document);
  const customer = useSelector((state) => state.account.customer);
  const plans = useSelector((state) => state.scooterData.plans);
  const subscription = useSelector((state) => state.account.subscription);

  const kbisList = documents.filter((doc) => doc.includes("KBIS")).map((doc) => doc.split("_")[1]);
  const idList = documents
    .filter((doc) => doc.includes("PASSPORT"))
    .map((doc) => doc.split("_")[1]);
  const addressList = documents
    .filter((doc) => doc.includes("ADDRESS"))
    .map((doc) => doc.split("_")[1]);
  const driveLicenseList = documents
    .filter((doc) => doc.includes("DRIVE_LICENCE"))
    .map((doc) => doc.split("_")[1]);
  const secondDriveLicenseList = documents
    .filter((doc) => doc.includes("SECOND_DRIVER_LICENSE"))
    .map((doc) => doc.split("_")[1]);

  const [currentPlan, setCurrentPlan] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({
    drivingLicense: [],
    secondDrivingLicense: [],
    id: [],
    address: [],
    kbis: [],
  });
  const [uploadingError, setUploadingError] = useState({
    drivingLicense: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    secondDrivingLicense: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    id: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    address: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
    kbis: {
      isSizeExceeded: false,
      isWrongFormat: false,
    },
  });

  useEffect(() => {
    dispatch(getDocumentsList());
  }, []);

  useEffect(() => {
    if (plans.length && subscription.plan_id) {
      const temp = plans.find((plan) => plan.id === subscription.plan_id);
      setCurrentPlan(temp);
    }
  }, [plans.length, subscription.plan_id]);

  useEffect(() => {
    if (Object.keys(subscription).length === 0 && customer.email && customer.subscriptions.length) {
      const mainSubscription = customer.subscriptions.find(subscription => subscription.main)

      if (mainSubscription) {
        dispatch(getSubscriptionDetails(mainSubscription.externalId));
      }

    }
  }, [customer]);

  const handleFileUpload = useCallback((acceptedFiles, documentName) => {
    // Setting no errors for particular documentName.
    setUploadingError((prevState) => ({
      ...prevState,
      [documentName]: { isSizeExceeded: false, isWrongFormat: false },
    }));

    const temp = acceptedFiles
      .map((file) => {
        // If file is Ok -> return file.
        if (isFormatValid(file.type) && !isExceedSize(file.size)) return file;
        // If file format isn't Ok -> set format error for this file.
        else if (!isFormatValid(file.type)) {
          setUploadingError((prevState) => ({
            ...prevState,
            [documentName]: {
              ...prevState[documentName],
              isWrongFormat: true,
            },
          }));
        }
        // If file size isn't Ok -> set size error for this file.
        else if (isExceedSize(file.size)) {
          setUploadingError((prevState) => ({
            ...prevState,
            [documentName]: {
              ...prevState[documentName],
              isSizeExceeded: true,
            },
          }));
        }

        return undefined;
      })
      .filter((item) => item);

    setUploadedFiles((prevState) => ({
      ...prevState,
      [documentName]: [...prevState[documentName], ...temp],
    }));
  }, []);

  const deleteHandler = (documentToDelete, document) => {
    setUploadingError((prevState) => ({
      ...prevState,
      [document]: { isSizeExceeded: false, isWrongFormat: false },
    }));
    const temp = uploadedFiles[document].filter((file) => !isEqual(file, documentToDelete));
    setUploadedFiles((prevState) => ({ ...prevState, [document]: temp }));
  };
  const manualUploadHandler = () => {
    const mainSubscription = customer.subscriptions.find(subscription => subscription.main)

    dispatch(
      uploadFiles({
        data: uploadedFiles,
        isManualUpload: true,
        subscriptionId: mainSubscription?.externalId,
        documentsToUpload: Object.entries(uploadedFiles)
          .map(([key, value]) => !isEmpty(value) && key)
          .filter((item) => item),
      }),
    );
    setUploadedFiles({
      drivingLicense: [],
      id: [],
      address: [],
      kbis: [],
      secondDrivingLicense: [],
    });
  };

  useEffect(() => {
    const shouldUpload = Object.values(uploadedFiles).some((value) => {
      return value.length !== 0;
    });

    if (shouldUpload) {
      manualUploadHandler();
    }
  }, [uploadedFiles]);

  if (subscription.status === "active") {
    push("/account");
    return null;
  }

  return (
    <div className="common-wrap common-account">
      <div className="content">
        <h3 className="header">{t("subscription.confirmation-page.my-documents")}</h3>
        <div className="divider" />
        {!["particular", "professional"].includes(subscription?.cf_buyer_profile) && (
          <>
            <div className="bills-subscription-title">
              {`${t("my-account.documents.missing-document_1")} `}
              <a href={t("my-account.documents.missing-document_3")} target="_blank">{`${t("my-account.documents.missing-document_2")} `}</a>
              {`${t("my-account.documents.missing-document_4")}`}
            </div>
            <div className="bills-subscription-title">
              {`${t("my-account.documents.missing-document_5")} `}
            </div>
          </>

        )}
        <div className="uploaders-wrap">
          {subscription.cf_buyer_profile === "particular" && (
            <>
              {(isShowDriverLicense(
                customer.chargebeeCustomer && customer.chargebeeCustomer.cf_birth_date,
              ) ||
                currentPlan.cf_power === "80") && (
                <Uploader
                  header={t("subscription.document-upload.driving-license")}
                  onDrop={handleFileUpload}
                  document={"drivingLicense"}
                  uploadedFiles={uploadedFiles}
                  isLoading={
                    documentLoadingStatus.drivingLicense.pending ||
                    generalDocumentLoadingStatus.isLoading
                  }
                  documentsList={driveLicenseList}
                  deleteHandler={deleteHandler}
                  subscription={subscription}
                  errors={uploadingError.drivingLicense}
                />
              )}
              <Uploader
                header={t("subscription.document-upload.card-or-passport")}
                onDrop={handleFileUpload}
                document={"id"}
                uploadedFiles={uploadedFiles}
                isLoading={
                  documentLoadingStatus.id.pending || generalDocumentLoadingStatus.isLoading
                }
                documentsList={idList}
                deleteHandler={deleteHandler}
                subscription={subscription}
                errors={uploadingError.id}
              />
              <Uploader
                header={t("subscription.document-upload.address-proof")}
                onDrop={handleFileUpload}
                document={"address"}
                uploadedFiles={uploadedFiles}
                isLoading={
                  documentLoadingStatus.address.pending || generalDocumentLoadingStatus.isLoading
                }
                documentsList={addressList}
                deleteHandler={deleteHandler}
                subscription={subscription}
                errors={uploadingError.address}
              />
            </>
          )}
          {subscription.cf_buyer_profile === "professional" && (
            <>
              <Uploader
                header={t("subscription.document-upload.company-proof")}
                onDrop={handleFileUpload}
                document={"kbis"}
                uploadedFiles={uploadedFiles}
                isLoading={
                  documentLoadingStatus.kbis.pending || generalDocumentLoadingStatus.isLoading
                }
                documentsList={kbisList}
                deleteHandler={deleteHandler}
                subscription={subscription}
                errors={uploadingError.kbis}
              />
              <Uploader
                header={t("subscription.document-upload.card-or-passport")}
                onDrop={handleFileUpload}
                document={"id"}
                uploadedFiles={uploadedFiles}
                isLoading={
                  documentLoadingStatus.id.pending || generalDocumentLoadingStatus.isLoading
                }
                documentsList={idList}
                deleteHandler={deleteHandler}
                subscription={subscription}
                errors={uploadingError.id}
              />
              {(isShowDriverLicense(
                customer.chargebeeCustomer && customer.chargebeeCustomer.cf_birth_date,
              ) ||
                currentPlan.cf_power === "80") && (
                <Uploader
                  header={t("subscription.document-upload.driving-license")}
                  onDrop={handleFileUpload}
                  document={"drivingLicense"}
                  uploadedFiles={uploadedFiles}
                  isLoading={
                    documentLoadingStatus.drivingLicense.pending ||
                    generalDocumentLoadingStatus.isLoading
                  }
                  documentsList={driveLicenseList}
                  deleteHandler={deleteHandler}
                  subscription={subscription}
                  errors={uploadingError.drivingLicense}
                />
              )}
              {(isShowDriverLicense(
                customer.chargebeeCustomer &&
                  customer.chargebeeCustomer.cf_birth_date_second_driver,
              ) ||
                currentPlan.cf_power === "80") && (
                <Uploader
                  header={t("subscription.document-upload.second-driving-license")}
                  onDrop={handleFileUpload}
                  document={"secondDrivingLicense"}
                  uploadedFiles={uploadedFiles}
                  isLoading={
                    documentLoadingStatus.secondDrivingLicense.pending ||
                    generalDocumentLoadingStatus.isLoading
                  }
                  documentsList={secondDriveLicenseList}
                  deleteHandler={deleteHandler}
                  subscription={subscription}
                  errors={uploadingError.secondDrivingLicense}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MenuHoc(Documents);
