import { useState } from "react";

import { useWindowEvent } from "hooks";

type ReturnType = {
  width: number;
  height: number;
};

type UseWindowSize = (onResize?: () => void) => ReturnType;

const useWindowSize: UseWindowSize = (onResize) => {
  const isClient = typeof window === "object";

  const getSize = () => ({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = () => {
    setWindowSize(getSize());
    onResize && onResize();
  };

  useWindowEvent("resize", handleResize);

  return windowSize;
};

export default useWindowSize;
