import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { GeneratedContractModel, Nullable } from "models";

import { ReactComponent as Loader } from "assets/images/contract-loader.svg";
import Label from "assets/images/uploader/label.png";
import FileImage from "assets/images/uploader/file.svg";

type QuoteOnlineProps = {
  contract: Nullable<GeneratedContractModel>;
  isGenerating: boolean;
};

export const QuoteOnline: React.FC<QuoteOnlineProps> = memo(
  ({ contract, isGenerating }: QuoteOnlineProps) => {
    const { downloadUrl } = contract || {};

    const { t } = useTranslation();

    const patienceText = t("subscription.confirmation-page.patience");
    const charsToShow = typeof patienceText === "string" ? patienceText.split("") : [];

    return (
      <div className="common-wrap">
        <div className="content">
          <h3 className="header">
            {t("subscription.confirmation-page.congratulations")}
            <br />
            {t("subscription.confirmation-page.subscription-created")}
          </h3>
          <div className="divider" />
          <div className="contract-wrap">
            <p>{t("subscription.confirmation-page-online.request-taken-subsidy-new")}</p>
            <div className="contract">
              <div className="contract-content">
                <h4>
                  {t("subscription.confirmation-page.to-sign")}
                  {isGenerating && <Loader width={35} height={35} />}
                </h4>
                {isGenerating && (
                  <div className="contract-patience">
                    {charsToShow.map((char, index) => {
                      const inlineStyles = {
                        animationDelay: index * 100 + "ms",
                      };

                      return (
                        <span key={index} style={inlineStyles}>
                          {char}
                        </span>
                      );
                    })}
                  </div>
                )}
                {downloadUrl && (
                  <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                    {t("subscription.confirmation-page.view-contract")}
                  </a>
                )}
              </div>
              <div className="image-wrap">
                <img src={Label} className="label" alt="" />
                <img src={FileImage} alt="" />
              </div>
            </div>
            <h4>{t("subscription.confirmation-page.next-step")}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t("subscription.confirmation-page-online.final-acception-subsidy-new"),
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);
