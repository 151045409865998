import { readCookie } from "hooks/use-cookies.hook";
import { utmDataToAdd } from "./personal-info.constants";

export const getCookiesData = (): Record<string, string> => {
  const cookies = readCookie();

  const utmCookies = cookies["utm"];
  const storeCodeCookies = cookies["store_code"];
  const agreedCategoriesCookies = cookies["cookie-agreed-categories"];

  const hasMarketingCookies =
    agreedCategoriesCookies && agreedCategoriesCookies.includes("marketing");

  const objToReturn: Record<string, string> = {};

  if (storeCodeCookies) {
    objToReturn["store_code"] = storeCodeCookies;
  }

  if (!hasMarketingCookies) {
    utmDataToAdd.forEach((dataKey) => (objToReturn[dataKey] = "refus"));
    return objToReturn;
  }
  if (!utmCookies) {
    utmDataToAdd.forEach((dataKey) => (objToReturn[dataKey] = "organic"));
    return objToReturn;
  }

  const convertedArr = utmCookies.replace(/["{}]/g, "").split(",");
  const utmObj = Object.fromEntries(convertedArr.map((str) => str.split(":")));
  utmDataToAdd.forEach((dataKey) => {
    const value = utmObj[dataKey.slice(11)];
    if (value) {
      objToReturn[dataKey] = value;
    }
  });
  return objToReturn;
};
