import React from "react";
import { useTranslation } from "react-i18next";

import { putCustomerPayment } from "api";
import { useCall } from "hooks";
import { MenuHoc } from "./MenuHoc.js";

import VisaPNG from "assets/images/account/visa.png";
import MastercardPNG from "assets/images/account/mastercard.png";

const Payment = () => {
  const { t } = useTranslation();

  const { submit, submitting, onCallSuccess } = useCall(putCustomerPayment);
  onCallSuccess((res) => {
    window.open(res.url, "_blank");
  });

  return (
    <div className="common-wrap common-account">
      <div className="content payment-wrap">
        <h3 className="header title">{t("subscription.header.my-payment-method-title-new")}</h3>
        <div className="subtitle">
          <span>{t("subscription.header.my-payment-method-subtitle-new")}</span>
          &nbsp;
          <strong>{t("subscription.header.my-payment-method-subtitle-bold-new")}</strong>
        </div>
        <div className="cards-wrapper">
          <img className="visa-img" src={VisaPNG} alt="visa-card" />
          <img className="mastercard-img" src={MastercardPNG} alt="mastercard-card" />
        </div>
        <button className="payment-button" onClick={submit} disabled={submitting}>
          {t("subscription.header.my-payment-method-button-new")}
        </button>
      </div>
    </div>
  );
};

export default MenuHoc(Payment);
