import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { SelectButton } from "components";
import { Footer } from "components/Footer";
import { useDidMount, useGTM, useQuery } from "hooks";
import { RootState, setScooterModel, setScooterSetup } from "store";
import { BuyerTypesKeys } from "models";
import { uniqBy } from "utils";
import { mapModelToScooterModel, mapModelToScooterDescription } from "../scooter-setup.constants";
import { buildImageUrl } from "../scooter-setup.utils";

type QueryType = {
  cf_buyer_profile: BuyerTypesKeys | "";
  cf_power: string;
  cf_model?: string;
};

const ScooterModel: React.FC = memo(() => {
  useGTM(3);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { query, setQueryParam, updateQueryParams } = useQuery<QueryType>();

  const plans = useSelector((state: RootState) => state.scooterData.plans);

  const availableModelArr: string[] = useMemo(() => {
    const filteredByBuyer = plans.filter((el) => el.cf_buyer_profile === query.cf_buyer_profile);

    const uniq = uniqBy(filteredByBuyer, "cf_scooter_model");

    return uniq.map(({ cf_scooter_model }) => cf_scooter_model);
  }, [query.cf_model]);

  const modelType = availableModelArr.find((type) => query.cf_model === type);

  const blockClickHandler = (data: string) => () => setQueryParam("cf_model", data);

  const nextButtonOnClick = (model = "") => {
    const powerToSet = model || modelType;
    powerToSet && dispatch(setScooterModel(powerToSet));
  };

  const handlePrevButton = () => {
    updateQueryParams({
      cf_buyer_profile: "",
      cf_power: "",
      cf_model: "",
    });

    dispatch(
      setScooterSetup({
        cf_buyer_profile: "",
        cf_model: "",
        cf_power: "",
      }),
    );
  };

  useDidMount(() => {
    const length = availableModelArr.length;
    if (!length) return;

    if (modelType) {
      setQueryParam("cf_model", modelType);
      return nextButtonOnClick(modelType);
    }
    const model = availableModelArr[0];
    setQueryParam("cf_model", model);

    if (length === 1) nextButtonOnClick(model);
  });

  if (!availableModelArr.length || availableModelArr.length === 1) return null;

  return (
    <>
      <div className="common-wrap">
        <div className="content-with-image-wrap">
          <div className="scooter-image">
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={buildImageUrl(modelType, "blue", "large")}
              />
              <source
                media="(max-width: 768px)"
                srcSet={buildImageUrl(modelType, "blue", "small")}
              />
              <img src={buildImageUrl(modelType, "blue", "large")} alt="" />
            </picture>
          </div>
          <div className="image-content">
            <h3 className="header">
              {availableModelArr.length > 1
                ? t("subscription.scooter-choice.question")
                : t("subscription.scooter-choice.statement")}
            </h3>
            <div className="divider" />
            <div className="user-types-wrap">
              {availableModelArr.map((model) => (
                <SelectButton
                  key={model}
                  className={cn("user-type", { active: modelType === model })}
                  selected={model === modelType}
                  disabled={model === modelType}
                  onClick={blockClickHandler(model)}
                >
                  <div className="model">{t(mapModelToScooterModel[model])}</div>
                  <div className="description">{t(mapModelToScooterDescription[model])}</div>
                </SelectButton>
              ))}
            </div>
            <div className="delivery-wrap">
              <h3>{t("subscription.color-choice.delivery")}</h3>
              <p>{t("subscription.scooter-choice.1-3-weeks")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer
        nextButtonId="scooter-model-select"
        isNextButtonDisabled={!modelType}
        nextButtonHandler={nextButtonOnClick}
        prevButtonHandler={handlePrevButton}
      />
    </>
  );
});

export default ScooterModel;
