import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useAuth0 } from "Auth";
import { isEmpty } from "utils";
import { useGiveEventId } from "hooks";
import { ACCOUNT_PAGE } from "constants/routes.constants";
import { getCustomerData, getSubscriptionDetails } from "store/actions";
import Dashboard from "../../components/Account/Dashboard";
import Personal from "../../components/Account/Personal";
import Contract from "../../components/Account/Contract";
import Bills from "../../components/Account/Bills";
import Payment from "../../components/Account/Payment";
import Documents from "../../components/Account/Documents";
import Password from "../../components/Account/Password";
import NavigationPage from "../../components/Account/NavigationPage";
import SubscriptionError from "../../components/Account/SubscriptionError";
import EmailWarning from "../../components/Account/EmailWarning";

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "aliceblue",
    right: 0,
    bottom: 0,
    zIndex: 3,
    opacity: 0.7,
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-20px",
    marginLeft: "-25px",
    textAlign: "center",
    fontSize: "10px",
  },
}));

const Account = () => {
  useGiveEventId();

  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();

  const { pathname } = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [pushedToDashBoard, setPushedToDashBoard] = useState(false);
  const [pushedToErrorPage, setPushedToErrorPage] = useState(false);

  const customer = useSelector((state) => state.account.customer);
  const subscription = useSelector((state) => state.account.subscription);
  const customerLoadingStatus = useSelector((state) => state.account.customerLoadingStatus);
  const subscriptionLoadingStatus = useSelector((state) => state.account.subscriptionLoadingStatus);

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        redirect_uri: window.location.origin + ACCOUNT_PAGE.path,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getCustomerData());
  }, []);

  useEffect(() => {
    const { subscriptions, chargebeeCustomer, email, type } = customer;

    if (
      chargebeeCustomer === null ||
      subscriptions === null ||
      email === null ||
      (type && type === "GUEST")
    ) {
      !pathname.includes("/account/subscription-error") &&
        history.push("/account/subscription-error");
      setPushedToErrorPage(true);
    }
  }, [customer, customer.chargebeeCustomer, customer.subscriptions, customer.email]);

  useEffect(() => {
    const { subscriptions } = customer;
    if (subscriptions && subscriptions.length && isEmpty(subscription)) {
      const mainSubscription = customer.subscriptions.find(subscription => subscription.main)
      if (mainSubscription) {
        dispatch(getSubscriptionDetails(mainSubscription.externalId));
      }

    }
  }, [customer.subscriptions]);

  useEffect(() => {
    const { cf_last_update_step, status } = subscription;
    const allowedSteps = ["bank-account-details", "permission-type", "documents", "contract"];
    if (
      status &&
      cf_last_update_step &&
      status !== "active" &&
      !allowedSteps.includes(cf_last_update_step)
      // ||
      // (status && status === "cancelled")
    ) {
      !pathname.includes("/account/subscription-error") &&
        history.push("/account/subscription-error");
      return setPushedToErrorPage(true);
    }

    if (
      status === "future" &&
      !pushedToDashBoard &&
      !pathname.includes("/account/subscription-error")
    ) {
      setPushedToDashBoard(true);
      history.push("/account/dashboard");
    }
  }, [subscription]);

  if (
    (customerLoadingStatus.pending || subscriptionLoadingStatus.pending) &&
    !pathname.includes("/account/subscription-error")
  )
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress className={classes.spinner} size={59.5} />
      </div>
    );

  if (pushedToErrorPage)
    return (
      <>
        <EmailWarning />
        <Route path="*" component={SubscriptionError} exact />
      </>
    );

  return (
    <>
      <EmailWarning />

      <Switch>
        <Route path="/account/subscription-error" component={SubscriptionError} exact />
        <Route path="/account/" component={NavigationPage} exact />
        <Route path="/account/dashboard" component={Dashboard} exact />
        <Route path="/account/personal" component={Personal} exact />
        <Route path="/account/contract" component={Contract} exact />
        <Route path="/account/bills" component={Bills} exact />
        <Route path="/account/payment" component={Payment} exact />
        <Route path="/account/documents" component={Documents} exact />
        <Route path="/account/password" component={Password} exact />
        <Redirect to="/account" />
      </Switch>
    </>
  );
};

export default Account;
