import React from "react";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import TagManager from "react-gtm-module";
import * as serviceWorker from "./serviceWorker";

import Providers from "components/client/providers";
// import { appEnv } from "./config/environment.config";
import App from "./app";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "assets/styles/index.css";
import "./i18n";

// Sentry.init({
//   environment: appEnv,
//   dsn: "https://30aef278a0c547b1baafebc0785d5956@o1299863.ingest.sentry.io/6548194",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

TagManager.initialize({
  gtmId: "GTM-WLP3R7V",
  dataLayerName: "PageDataLayer",
});

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
