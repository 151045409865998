import React, { useEffect, useRef, useState } from "react";
import { AsYouType, isValidNumber } from "libphonenumber-js";
import PropTypes from "prop-types";

import { setInputCaretPosition } from "../../utils/inputs";

export function PhoneInputPlain({
  initialPhone,
  inputStyle,
  placeholder,
  onValid,
  onError,
  onClear,
}) {
  const [phoneValue, setPhoneValue] = useState(
    new AsYouType().input(!initialPhone.startsWith("+") ? `+${initialPhone}` : initialPhone),
  );

  const [_isPhoneError, setPhoneError] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState("");
  const [phoneTemplate, setPhoneTemplate] = useState("");
  const [countryCallingCode, setCountryCallingCode] = useState("");

  // caret positioning.
  const [inputRange, setInputRange] = useState({
    start: 0,
    end: 0,
  });
  const inputRef = useRef();

  useEffect(() => {
    const { start, end } = inputRange;

    setInputCaretPosition(inputRef, start, end);
  }, [phoneValue]);

  function handleError() {
    onError && onError();
    setPhoneError(true);
  }

  function handleValid() {
    onValid && onValid(phoneValue.replace(/\s/g, ""));
    setPhoneError(false);
  }

  function handleClear() {
    onClear && onClear();
    setPhoneValue("+");
    setPhoneCountry("");
    setPhoneTemplate("");
    setPhoneError(false);
  }

  function handlePhoneInputChange(event) {
    const prevValue = phoneValue;
    let value = event.target.value;

    // remove spacing, special symbols, etc.
    const clearNewNumbers = value.replace(/\D/g, "");
    if (!clearNewNumbers) {
      handleClear();
      setInputRange({
        start: 1,
        end: 1,
      });
      return;
    }

    const clearPrevNumbers = prevValue.replace(/\D/g, "");

    const valueToSet = new AsYouType();
    const formattedValue = valueToSet.input(value.startsWith("+") ? value : `+${value}`);

    // set necessary staff.
    if (valueToSet.template) {
      setPhoneTemplate(valueToSet.template);
    }
    if (valueToSet.country) {
      setPhoneCountry(valueToSet.country);
    }
    if (valueToSet.countryCallingCode) {
      setCountryCallingCode(valueToSet.countryCallingCode);
    }

    // check if user exceed numbers length.
    if (phoneTemplate && value.length > phoneTemplate.length) {
      return;
    }

    setPhoneValue(formattedValue);

    // Handle caret pos.
    const { selectionStart, selectionEnd } = event.target;
    let offset = 0;

    // Check if user select all text and paste new.
    if (value.length < formattedValue.length && !value.startsWith("+")) {
      offset++;
    }

    // Check if prev value < new value by length.
    if (
      clearPrevNumbers.length <= clearNewNumbers.length &&
      (formattedValue.charAt(selectionStart - 1) === " " ||
        formattedValue.charAt(selectionStart + 1) === "")
    ) {
      offset++;
    }

    const start = selectionStart + offset;
    const end = selectionEnd + offset;

    setInputRange((prevState) => ({
      ...prevState,
      start,
      end,
    }));
  }

  function validatePhone(event) {
    let phone = event.target.value;

    // remove spacing, special symbols, etc.
    phone = phone.replace(/\D/g, "");
    // remove dialCode.
    phone = phone.substring(countryCallingCode.length);

    if (!phone) {
      handleClear();
      return;
    }

    // If country is not France and phone length < 7 => show error.
    if (phoneCountry !== "FR" && phone.length < 7) {
      handleError();
      return;
    }

    // If country is not France => do not validate phone.
    if (phoneCountry !== "FR") {
      handleValid();
      return;
    }

    // If country is France => validate phone.
    const isValid = isValidNumber(phone, phoneCountry);

    if (!isValid) {
      handleError();
      return;
    }

    handleValid();
  }

  return (
    <input
      ref={inputRef}
      type="text"
      style={inputStyle}
      value={phoneValue}
      onKeyDown={(event) => {
        const { selectionStart } = event.target;

        if (event.key === "Backspace" && phoneValue.charAt(selectionStart - 1) === " ") {
          setInputCaretPosition(inputRef, selectionStart - 1, selectionStart - 1);
          return setInputRange((prevState) => ({
            ...prevState,
            start: selectionStart - 1,
            end: selectionStart - 1,
          }));
        }
      }}
      onChange={handlePhoneInputChange}
      onBlur={validatePhone}
      onFocus={() => setPhoneError(false)}
      onClick={(event) => {
        const { selectionStart, selectionEnd } = event.target;

        setInputRange({
          start: selectionStart,
          end: selectionEnd,
        });
      }}
      placeholder={placeholder}
    />
  );
}

PhoneInputPlain.propTypes = {
  initialPhone: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  onValid: PropTypes.func,
  onError: PropTypes.func,
  onClear: PropTypes.func,
};
