export const mapPower: Record<string, string> = {
  "45": "subscription.power-choice.50cc",
  "80": "subscription.power-choice.125cc",
};

export const inclusiveList = [
  "subscription.subscription-choice.swappable-battery",
  "subscription.subscription-choice.unlimited-disctance",
  "subscription.subscription-choice.unlimited-bateries",
  "subscription.subscription-choice.electicity",
  "subscription.subscription-choice.insurance-support",
  "subscription.subscription-choice.maintenance",
  "subscription.subscription-choice.application",
];

export const offersList = [
  "subscription.subscription-choice.top-case",
  "subscription.subscription-choice.skirt",
  "subscription.subscription-choice.holder",
  "subscription.subscription-choice.charging-cable",
];

export const offersPriceList: Record<string, string> = {
  "subscription.subscription-choice.top-case": "subscription.subscription-choice.amount1-offered",
  "subscription.subscription-choice.skirt": "subscription.subscription-choice.amount2-offered",
  "subscription.subscription-choice.holder": "subscription.subscription-choice.amount3-offered",
  "subscription.subscription-choice.charging-cable":
    "subscription.subscription-choice.amount4-offered",
};
