import React from "react";
import countriesMultilang from "i18n-iso-countries";
import { getData } from "country-list";
import Select from "react-select";

import { setLanguage } from "utils";
import { mapHelperText } from "utils/mappers";

countriesMultilang.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesMultilang.registerLocale(require("i18n-iso-countries/langs/es.json"));

const options = getData().map((country) => {
  return { label: countriesMultilang.getName(country.code, setLanguage()), value: country.code };
});

export const CountrySelect = ({ t, formik, name, error, onChange }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      height: "58px",
      border: error ? "1px solid #f44336" : "1px solid #1E285A",
      borderRadius: "4px",
      marginTop: "14px",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
      color: "#1E285A",
      boxShadow: 0,
      "&:focus": {
        border: "1px solid #1E285A",
        boxShadow: 0,
      },
      "&:hover": {
        border: "1px solid #1E285A",
        boxShadow: 0,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: "#1E285A",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#1E285A",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#1E285A",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
      opacity: "0.5",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#1E285A",
      fontFamily: "Betm Rounded",
      fontSize: "18px",
    }),
  };
  const customComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <div className={"country-select-arrow"} />,
  };

  return (
    <div className={"country-select-container"}>
      <Select
        menuPlacement="auto"
        className="country-select"
        placeholder={t("subscription.professtional-information.country")}
        value={options.find((item) => item.value === formik.values[name])}
        onChange={(e) => {
          onChange && onChange(name, e.value);
          formik.setFieldValue(name, e.value);
          formik.setFieldTouched(name, true);
        }}
        options={options}
        styles={customStyles}
        components={customComponents}
      />
      {error && <div className="country-select-error">{t(mapHelperText[name])}</div>}
    </div>
  );
};
