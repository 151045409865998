import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { useDispatch } from "react-redux";

import { isEmpty } from "utils";
import { setScooterSetupReduxState } from "store";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isAttemptedToLogin, setIsAttemptedToLogin] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      const token = await auth0FromHook.getIdTokenClaims();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        token && localStorage.setItem("token", token.__raw);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  useEffect(() => {
    const setup = JSON.parse(localStorage.getItem("setup"));
    const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan"));
    const paymentDetails = JSON.parse(localStorage.getItem("paymentDetails"));

    if (isEmpty(setup) || isEmpty(selectedPlan) || isEmpty(paymentDetails)) return;

    dispatch(
      setScooterSetupReduxState({
        setup,
        selectedPlan,
        paymentDetails,
      }),
    );

    localStorage.removeItem("setup");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("paymentDetails");
  }, []);

  useEffect(() => {
    if (auth0Client) {
      async function authorize() {
        try {
          await auth0Client.getTokenSilently();

          const tokenClaims = await auth0Client.getIdTokenClaims();

          const isAuthenticated = await auth0Client.isAuthenticated();
          const user = await auth0Client.getUser();

          setIsAuthenticated(isAuthenticated);
          setUser(user);
          localStorage.setItem("token", tokenClaims.__raw);
        } catch (e) {
        } finally {
          setIsAttemptedToLogin(true);
        }
      }

      authorize();
    }
  }, [auth0Client]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    const token = await auth0Client.getIdTokenClaims();
    localStorage.setItem("token", token.__raw);
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isAttemptedToLogin,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
